import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import Tvsub from "./tvsubs";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import axios from '../../../axios-allen';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './tvmainmodule.css'

const Tvmainentry = (props) => {
  let tvmaininit = {
    name : '',
    tvsubs:[],
    centres:[]
  };
  const history = useHistory();
  const toast = useRef(null);
  const [tvmain,settvmain] = useState(tvmaininit);
  const [saved,setSaved] = useState(false);

  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      settvmain(history.location.state.tvmaindata);
    }
  },[history.location.state.mode,history.location.state.tvmaindata]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newtvmain = {...tvmain}
    newtvmain[`${name}`] = val;
    settvmain(newtvmain);
  }

  const saveTvsub = (i) => {
    let newtvmain = {...tvmain}
      newtvmain.tvsubs = i;
    settvmain(newtvmain);
  }

  const tvmainSave = (event) => {
    event.preventDefault();
    if (tvmain.name==='') {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Name', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      let authOptions = {
        method: 'POST',
        url: '/tvmain/', 
        data: tvmain,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TV Main Created', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      let authOptions = {
        method: 'PUT',
        url: '/tvmain/', 
        data: tvmain,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TV Main Detail Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  if (saved)  history.goBack();

  let Tvsubcomponent=null;
  Tvsubcomponent = (<Tvsub  mode={history.location.state.mode} Tvsub={tvmain.tvsubs} SaveTvsubs={saveTvsub}/>)


  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if  (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={tvmainSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (history.location.state.mode === 'Edit' ) {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={tvmainSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  return (
    <div >
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">TV Channel - Main Channel Form</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>
      <div style={{width:'67%',margin:'auto'}} className="h-auto pb-2 surface-200 px-4">
        <div style={{}} className="formgrid grid">
          <label htmlFor="tvmainname" className="mt-1 p-0">Main Channel Name*</label>
          <InputText className="w-full" id="tvmainname"  autoComplete="off" value={tvmain.name} onChange={(e) => onInputChange(e, 'name')} />
        </div>
      </div>
      <div className='w-8 m-auto'>
        {Tvsubcomponent}
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
            {BtnOptions}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitame:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Tvmainentry);