import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Prodetail from "./prodetailverify";

import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Proverify = (props) => {
  let proinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    centres:'',
    costalloc:[],
    isSpaceIncentive:false,
    siamt:null,
    fixedRate:null,
    media:[],
    caption:'',
    color:'COLOR',
    unit:'',
    height:'',
    width:'',
    page:'',
    rate:0,
    tsize:0,
    amt:0,
    advt:[],
    cost: {
      grossamt:0,
      pdiscper:0,
      pdisc:0,
      addldiscper:0,
      addldisc:0,
      agencydiscper:0,
      agencydisc:0,
      gstper:5,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bstate:'',
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };
  const [sessions,setsessions] = useState([]);
  const pagelist = [
    {nm:'Full Page', h:52, w:33},
    {nm:'Half-Ver', h:52, w:16},
    {nm:'Half-Hor', h:25, w:33},
    {nm:'Qtr', h:25, w:16},
    {nm:'SemiQtr',h:20, w:12},
    {nm:'JktFull', h:45, w:33},
    {nm:'other',h:'',w:''}
];
const sizeunits = ['Sq.Cm', 'Col.Cm'];

  const advcolor = ['B&W', 'COLOR']

  const history = useHistory();
  const toast = useRef(null);
  const [vendorlist,setv] = useState(null);
  const [pro,setpro] = useState(proinit);
  const [saved,setSaved] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [medias,setmedias] = useState([]);
  const [selmedia,setselmedia] = useState([]);
  const [editions,seteditions] = useState([]);
  const [newspapers, setNewspapers] = useState([]);
  const [selnewspaper, setselNewspaper] = useState(null);

  let disable=true;
  //if (pro.finalstatus === 'Draft' || pro.finalstatus === 'Review' || history.location.state.mode === 'New')
  //{
  // disable=false;
  //}



  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {

      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/newspaper/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const newspaper = response.data;
      setNewspapers(newspaper);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
  },[props.storeid]);

  useEffect(() => {
      let authOptions = {
        method: 'PUT',
        url: '/media/np',
        data: {'npid':history.location.state.prodata.media[0].newspaper._id},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setmedias(response.data);
        setselNewspaper(history.location.state.prodata.media[0].newspaper);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setmedias([]);
      });
  },[history.location.state.mode,history.location.state.prodata,units]);

  useEffect(() => {
      const newpro = {...history.location.state.prodata};
      const ed =medias.filter(n => history.location.state.prodata.media.some(n2 => n.name === n2.name));//medias.find(e => e._id === history.location.state.prodata.media._id);
      let final=[];
      const final1 = history.location.state.prodata.media.map(e => final.concat(e.editions));
      final = [].concat(...final1);
      const newadvt = newpro.advt.map(ea => ({ ...ea, edition : final.find(e => e._id === ea.edition)}))
      newpro.advt = newadvt;
      const selcentres = units.filter((el) => {
        return newpro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselmedia(ed);
      setselUnits(selcentres!==null?selcentres:'');
      seteditions(final);
      setpro(newpro);
  },[history.location.state.mode,history.location.state.prodata,medias,units]);

  const proSave = (event) => {
    event.preventDefault();
    if (!pro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    if (pro.session === "") {
      let newpros = {...pro};
      const xx =sessions.splice(-1);
      newpros.session = xx[0];
      setpro(newpros);
      //toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Session', life: 3000 });
      //return;
    };
    if (pro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!pro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!pro.media) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Print Media', life: 3000 });
      return;
    };
    if (pro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!pro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!pro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    let newpro = {...pro};
    // if (pro.isSpaceIncentive || pro.fixedRate>0) {
    //   const items = newpro.advt.map((x) => {
    //     return {...x, rate:0, amt:0};
    //   });
    //   newpro.advt = items;
    // }
    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newpro.status.push(status);
      let authOptions = {
        method: 'POST',
        url: '/printmedia/', 
        data: newpro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newpro.status.push(status);
      let authOptions = {
        method: 'PUT',
        url: '/printmedia/', 
        data: newpro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Verified', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

if (saved)  history.goBack();


const saveItems = (i) => {
  let newpro = {...pro};
  newpro.advt = i;
  setpro(newpro);
}

const BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={proSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>);


  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Print - ROs - Verification</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="prono" >RO_No</label>
            <InputText style={{width:'300px'}}className="" disabled={disable}  htmlFor="prono"  value={pro.rono}/>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="proDate" className="w-2 mb-0 mr-2">RO_Date</label>
            <Calendar  style={{width:'300px'}}  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="proDate" name="roDate" value={new Date(pro.rodate)} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={pro.session} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Division"style={{width:'300px'}} id="division"  options={divisions} value={pro.division} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="project" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={pro.agency}  />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="np" className="w-2 mr-2 mb-0">NewsPaper</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Newspaper"style={{width:'300px'}} id="np" optionLabel="name"  options={newspapers} value={selnewspaper}  />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="media" className="w-2 mr-2 mb-0">Main Edition</label>
            <MultiSelect 
              style={{width:'300px'}}
              filter filterBy='name' 
              disabled={disable} 
              value={selmedia} 
              optionLabel="name"
              options={medias} 
              placeholder="Select Main Edition" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="project" className="w-2 mr-2 mb-0">Color</label>
            <Dropdown disabled={disable} placeholder="Select Color" style={{width:'300px'}}  id="color"  options={advcolor} value={pro.color} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="proDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={pro.caption} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={pro.bstate} 
              options={states} 
              placeholder="Select States" 
              
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="prono" >Centres</label>
            <MultiSelect 
            style={{width:'300px'}}
              disabled={disable} 
              value={pro.centres} 
              options={selUnits} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'5px 0px 0 0px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="field justify-content-center grid mb-1 ml-29 m-auto">
          <label htmlFor="page" className="mr-2 mb-0">Page</label>
          <Dropdown disabled={disable} placeholder="Select Page" className="w-auto" id="page"  optionLabel="nm" options={pagelist} value={{nm:pro.page,h:pro.height,w:pro.width}}  />
        </div>
        <div  className="field justify-content-center grid mb-1 ml-2 m-auto">
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="unit">Select Unit</label>
            <Dropdown  disabled={disable} placeholder="Unit" className="w-auto" id="unit"  options={sizeunits} value={pro.unit}  />
          </div>
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="size" >Height</label>
            <InputNumber disabled={disable} inputStyle={{width:'100%'}} prefix="H:" mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={pro.height} />
          </div>
          <label className="s-1 m-0 mt-3 p-0" htmlFor="size" >X</label>
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="size" >Width</label>
            <InputNumber disabled={disable} inputStyle={{width:'100%'}} prefix="W:"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={pro.width} />
          </div>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'5px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Prodetail disabled={disable} captions={captions} selcaption={pro.caption} pdate={pro.rodate} Items={pro.advt} mode={history.location.state.mode}  SaveItems={saveItems} editions={editions} />
        </div>
      </div>

      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={5} autoComplete="off" className={'w-full'} id="remarks" value={pro.remarks}  />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={5} autoComplete="off" className={'w-full'} id="remarks" value={pro.note}  />
          </div>
        </div>
        <div className="flex flex-row justify-content-around w-6 mt-0 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Proverify);