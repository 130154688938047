import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './bill.css'

import { useHistory } from "react-router-dom";

const Billtable = (props) => {

  const history = useHistory()
  const [Items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [frDate, setfrDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  
  const toast = useRef(null);
  const dt = useRef(null);
  const [selectedStatuses, setSelectedStatuses] = useState(['Draft', 'Submitted']);

  const statuses = [
    "Draft",
    "Submitted",
    "Approved",
    "Rejected",
    "Review",
    "Dispatched",
    "Inwarded",
    "Posted"
  ];

  useEffect(() => {
    const i = props.permissions.find(x => (x.name === 'FM-BILLCREATE'))
    if (props.isAdmin || i) {
      axios.get('/bill/robill/FM', {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        console.log(response.data)
        setItems(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setItems([]);
      });
      } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised for RO Billing', life: 3000 }); 
  }, [props.storeid,props.isAdmin,props.permissions]);

  const billsearch = (event) => {
      event.preventDefault();  
      if ((frDate === null || toDate === null) && selectedStatuses.length===0) {
        return;
      }
    // const s = frDate.getFullYear()+"-"+((frDate.getMonth()+1) <10?"0"+(frDate.getMonth()+1):(frDate.getMonth()+1))+"-"+frDate.getDate()
    // const s1 = toDate.getFullYear()+"-"+((toDate.getMonth()+1) <10?"0"+(toDate.getMonth()+1):(toDate.getMonth()+1))+"-"+toDate.getDate() 
     let authOptions = {
      method: 'POST',
      url: '/bill/robillsearch/FM', 
      data: {'sdt':frDate, 'edt':toDate, 'sts': selectedStatuses},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
    .then(response => {
      setItems(response.data);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    });
  }

  const formatDate = (dt) => {
    let cdt = new Date(dt);
    let month = cdt.getMonth() + 1;
    let day = cdt.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return day+"-"+month+"-"+cdt.getFullYear() ;
  };

  const editItem = (Item) => {
    if (!props.isAdmin) {
      const i = props.permissions.find(x => x.name === 'FM-BILLCREATE')
      if (!i) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Edit New Bill', life: 3000 }); 
        }
        else {
          history.push({
            pathname: '/FmBillentry',
            state: { mode:'Edit', billdata:Item }
          });
              }
    } else {
      history.push({
        pathname: '/FmBillentry',
        state: { mode:'Edit', billdata:Item }
      });
    }
  };

  const openNew = () => {
    if (!props.isAdmin) {
      const i = props.permissions.find(x => x.name === 'FM-BILLCREATE')
      if (!i) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Add New Bill', life: 3000 }); 
        }
        else {
          history.push({
            pathname: '/FmBillentry',
            state: { mode:'New', billdata:null }
          });
              }
    } else {
      history.push({
        pathname: '/FmBillentry',
        state: { mode:'New', billdata:null }
      });
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <Button label="New" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
    );
};

  const rightToolbarTemplate = () => {
      return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      )
  }

  const recddateBodyTemplate = (rowData) => formatDate(rowData.recddate);
  const billdateBodyTemplate = (rowData) => formatDate(rowData.billdate);

  const actionBodyTemplate = (rowData) => 
    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-primary mr-2 " onClick={() => editItem(rowData)} />;
  
  return (
    <div className="BILL">
      <div className="Headerbill">
        <img src={logo} onClick={() => history.push('/MEDIA')} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
        <h2 style={{marginTop:'5px', textAlign:'center'}}>Media Module<br/>FM-Radio Bills</h2>
        <div className="Userbill">
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
        </div>
      </div>
      <div className="Sformbill h-auto mt-2">
        <div className="">
          <label htmlFor="frDate" className="mr-4 h-2rem">Date-From</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="frDate" className="h-2rem" value={frDate} onChange={(e) => setfrDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <label htmlFor="toDate" className="mx-4 h-2rem">Date-To</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="toDate" className="h-2rem" value={toDate} minDate={frDate} onChange={(e) => settoDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <MultiSelect className="ml-4 my-1 h-auto" value={selectedStatuses} options={statuses} onChange={(e) => setSelectedStatuses(e.value)}  placeholder="Select Status" />
        </div>
        <Button icon="pi pi-search" className="ml-2 p-button-text p-button-success" onClick={billsearch}/>
      </div>
      <div className="TCbill">
        <Toast ref={toast} />
        <div className="card h-auto">
            <Toolbar className="p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
            <DataTable  sortField="ubn" sortOrder={-1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Items} selection={selectedItem} onSelectionChange={(e) => setSelectedItem(e.value)}
                dataKey="ubn" paginator rows={20} rowsPerPageOptions={[20, 25, 30]} style={{padding:'0px',fontSize:'14px'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                globalFilter={globalFilter} scrollable scrollHeight="320px" >
                <Column field="ubn" header="Bill UBN" sortable style={{textAlign:'center', width:'10%',padding:'0 2px 0 2px'}}></Column>
                <Column field="recddate" header="Recd Date" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} body={recddateBodyTemplate}></Column>
                <Column field="bno" header="Bill No" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="billdate" header="Bill Date" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} body={billdateBodyTemplate} ></Column>
                <Column field="rono" header="RO No" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}}  ></Column>
                <Column field="vendor.name" header="Agency" sortable style={{textAlign:'center',width:'22%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="netamt" header="Net Amt" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="finalstatus" header="Status" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}}></Column>
                <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
            </DataTable>
            <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
              <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
            </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin,
      storeid:state.auth.storeid
  }
}

export default connect(mapStateToProps)(Billtable);