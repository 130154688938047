import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { InputNumber } from 'primereact/inputnumber';

const Flexrodetailverify = (props) => {
    let emptyItem = {
        displaycity:'',
        ooarea:'',
        oolocation:'',
        mediatype:'',
        littype:'',
        unit:'',
        width:'',
        height:'',
        tsize:'',
        frequency:0,
        qty:0,
        flexmaterial:null,
        flexprintrate:0,
        flexamt:0,
        flexmountingcharges:0,
        totalamt:0,
        bno:0
    };
    const sizeunits = ['Sq.Ft', 'Sq.M', 'Sq.In'];
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();

        if (!Item.oolocation) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Location Defined ?'});
            return;
        }
        if (Item.oolocation.trim()) {
            let _Items = [...Items];
            let _Item = {...Item};
            if (!_Item.vperson.trim()) {
                setItemDialog(false);            
                setItem(emptyItem);
                return;
            }
            _Item.verified = true;
            const index = findIndexByName(Item._id);
            if (index >= 0) 
            {    _Items[index] = _Item;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OO Detail Updated', life: 3000 });
            }
            else {
                _Items.push(_Item);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OO Detail Created', life: 3000 });
            }
            setItemDialog(false);            
            setItems(_Items); 
            props.SaveItems(_Items);
            setItem(emptyItem);
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        setItemDialog(true);
    };


    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item[`${name}`] = val;
        setItem(_Item);
    };
    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button  icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            </>
        );
    };
    const famtBodyTemplate = (rowData) => {
        return rowData.flexamt.toFixed(2);
    };
    const vstatusBodyTemplate = (rowData) => {
        return rowData.verified?<span style={{color:'green',fontWeight:'bold'}}>{'Verified'}</span>:<span style={{color:'orange',fontWeight:'bold'}}>{'Not Verified'}</span>;
    }
    const onInputDateChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newfmro = {...Item};
        newfmro[`${name}`] = val;
        setItem(newfmro);
      }


    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1"  right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column field="displaycity.name" header="City" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="ooarea" header="Area" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="oolocation" header="Location" style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="mediatype.name" header="Media" sortable estyle={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="littype.name" header="Lit Type"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="tsize" header="Size" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="flexmaterial.name" header="Flex Mat" style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="flexprintrate" header="Flex Rate"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="flexmountingcharges" header="M Chrg"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="flexamt" header="Flex Amt"  body={famtBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="verified"  header="Verified"  body={vstatusBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{width:'8%',padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '600px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveItem} onReset={hideDialog}> 
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto ">
                            <label  className=" mr-2 mb-0">Display City</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Display City" style={{width:'100%'}} optionLabel="name" options={props.dc} value={Item.displaycity}  />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label htmlFor="area" className=" mr-2 mb-0">Area</label>
                            <InputText  className="" value={Item.ooarea}  />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label htmlFor="location" className="mr-2 mb-0">Location</label>
                            <InputText  className="" value={Item.oolocation}  />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Media Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Media Type"style={{width:'50%'}} optionLabel="name" options={props.mediatypes} value={Item.mediatype} />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Lit Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Lit Type"style={{width:'50%'}} optionLabel="name" options={props.littypes} value={Item.littype}  />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0" >Select Unit</label>
                            <Dropdown placeholder="Unit" style={{width:'50%'}} options={sizeunits} value={Item.unit}  />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0" >Height</label>
                            <InputNumber style={{width:'26.5%'}} prefix="H:" mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.height} />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0"  >Width</label>
                            <InputNumber  style={{width:'26.5%'}} prefix="W:"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.width} />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0"  >Total Size</label>
                            <InputNumber  style={{width:'26.5%'}}  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.tsize} readOnly/>
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Qty/Side</label>
                            <InputNumber style={{width:'26.5%'}}locale="en-IN"  value={Item.qty} />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1  m-auto">
                            <label className="w-3 mr-2 mb-0">Flex Material Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Flex Mat Type"style={{width:'50%'}} optionLabel="name" options={props.flexmaterials} value={Item.flexmaterial}  />
                        </div>
                    </div>
                    <div  className="field grid mb-1  m-auto">
                        <label className="w-3 mr-2 mb-0">Verified On</label>
                        <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.vdate)} onChange={(e) => onInputDateChange(e, 'vdate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                    </div>
                    <div  className="field grid mb-1  m-auto">
                        <label className="w-3 mr-2 mb-0">Verified By</label>
                        <InputText style={{width:'300px'}}className=""  htmlFor="fmrono"  value={Item.vperson} onChange={(e) => onInputChange(e, 'vperson')}/>
                    </div>
                    <div  className="field grid mb-1 m-auto">
                        <label className="w-3 mr-2 mb-0">Remarks</label>
                        <InputTextarea style={{width:'350px'}} rows={4} autoComplete="off" className='' id="remarks" value={Item.vremark} onChange={(e) => onInputChange(e, 'vremark')} />
                    </div>
                    <div className="formgrid grid mt-5">
                        <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    <div className="field col">
                        <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>
        </div>
    );
}

export default Flexrodetailverify;


