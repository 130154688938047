import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-allen';
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../Components/state';

import Statuslog from "../statuslog"
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Brverify = (props) => {
  const [sessions,setsessions] = useState([]);
  let brroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    costalloc:[],
    advt:'',
    cost: {
      grossamt:0,
      discper:0,
      disc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [brro,setbrro] = useState(brroinit);
  const [saved,setSaved] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const medias = [
    'Souvenior',
    'Brochure',
    'Booklet',
    'Magazine',
    'Newspaper'
    ]
  const colours = [
    'Colour',
    'B&W'
  ]
  const position = [
    'Front Page',
    'Front Inside Page',
    'Back Inside Page',
    'Back Page'
  ]

  let disable=true;

  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.brrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newbrro = {...history.location.state.brrodata};
      const selcentres = units.filter((el) => {
        return newbrro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselUnits(selcentres!==null?selcentres:'');
      setbrro(newbrro);
      //setselfmmain(ed);
    }
  },[history.location.state.mode,history.location.state.brrodata,units]);


  const brroSave = (event) => {
    event.preventDefault();
    if (!brro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newbrro = {...brro};
    if (brro.session === "") {
      const xx =sessions.splice(-1);
      newbrro.session = xx[0];
    };
    if (brro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!brro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!brro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (!brro.advt) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!brro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!brro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newbrro.status.push(status);
      newbrro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/brand/', 
        data: newbrro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newbrro.status.push(status);
      newbrro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/brand/', 
        data: newbrro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }


  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && brro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (brro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  const onInputChangeadvt = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let it = {...brro}
    let cst = {...it.advt}
    cst[`${name}`] = (val);
    it.advt = cst
    setbrro(it);
  }

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Branding - ROs Verify</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="brrono" readOnly value={brro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="brroDate" name="roDate" value={new Date(brro.rodate)} mask="99/99/9999" dateFormat="dd/mm/yy"  />
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={brro.session}  />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={brro.division}  />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={brro.agency}  />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="brroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={brro.caption}  />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={brro.bstate} 
              options={states} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="brrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={brro.centres} 
              options={selUnits} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-between mt-1 surface-100">
            <div  className="w-2 mb-1 ">
                <label  className=" mr-2 mb-0">Medias</label>
                <Dropdown  disabled={disable}  placeholder="Select print Media" style={{width:'100%'}}  options={medias} value={brro.advt.media}  />
                {brro.advt.media === 'Other'?<InputText  className="w-auto"  value={brro.advt.mediaother} />:null}
          </div>
            <div  className="w-2 mb-1">
                <label  className=" mr-2 mb-0">Publication</label>
                <InputText disabled={disable}  style={{width:'100%'}} value={brro.advt.publication}  />
            </div>
            <div  className="w-2 mb-1">
                <label  className=" mr-2 mb-0">Edition</label>
                <InputText disabled={disable}  style={{width:'100%'}} value={brro.advt.edition}  />
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Size</label>
                <InputText disabled={disable}  style={{width:'100%'}} value={brro.advt.size}  />
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Position</label>
                <Dropdown  disabled={disable}  placeholder="Select Position" style={{width:'100%'}}  options={position} value={brro.advt.position} />
                {brro.advt.position === 'Other'?<InputText  className="w-auto"  value={brro.advt.positionother} />:null}
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Colour</label>
                <Dropdown  disabled={disable}  placeholder="Select Colour" style={{width:'100%'}}  options={colours} value={brro.advt.colorb}  />
            </div>
            <div  className="w-2 mb-1">
                <label className="mr-2 mb-0">Date Publish</label>
                <Calendar disabled={disable}  style={{width:'100%'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" id="brroDate" name="dtpublish" value={new Date(brro.advt.dtpublish)}  mask="99/99/9999" dateFormat="dd/mm/yy" required/>
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="location" className="mr-2 mb-0">Amount</label>
                <InputNumber disabled={disable} style={{width:'100%'}} locale="en-IN" minFractionDigits={2}  value={brro.advt.amt}  />
            </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="brrono" readOnly value={brro.cost.grossamt}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="brrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="brrono"  value={brro.cost.gstper}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="brrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="brroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={brro.bunit}  />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={brro.baddress}  />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={brro.remarks}  />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={brro.note}  />
          </div>
        </div>
        <div style={{margin:'auto', padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex  justify-content-around mt-1 surface-100">
          <div  className="field  mb-1 ">
            <label className="w-3 mr-2 mb-0">Verified On</label>
            <Calendar  style={{width:'350px'}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(brro.advt.vdate)} onChange={(e) => onInputChangeadvt(e, 'vdate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
          </div>
          <div  className="field  mb-1 ">
            <label className="w-3 mr-2 mb-0">Verified By</label>
            <InputText style={{width:'350px'}}className=""  htmlFor="fmrono"  value={brro.advt.vperson} onChange={(e) => onInputChangeadvt(e, 'vperson')}/>
          </div>
          <div  className="field  mb-1  m-auto">
            <label className="w-3 mr-2 mb-0">Remarks</label>
            <InputTextarea style={{width:'550px'}} rows={4} autoComplete="off" className='' id="remarks" value={brro.advt.vremark} onChange={(e) => onInputChangeadvt(e, 'vremark')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={brro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Brverify);