import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Flexrodetail from "./flexrodetail";

import Statuslog from "../../statuslog"
//import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Flexro = (props) => {
  const [sessions,setsessions] = useState([]);
  let flexroinit = {
    rono: '',
    rodate: new Date().toString(),
    flexrono:'',
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    focqty:0,
    costalloc:[],
    advt:[],
    cost: {
      flexamt:0,
      grossamt:0,
      discper:0,
      disc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    dlydate: new Date().toString(),
    dlyaddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [flexro,setflexro] = useState(flexroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [cities,setCities] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [mediatypes,setMediatypes] = useState([]);
  const [littypes,setLittypes] = useState([]);
  const [flexmaterials,setFlexmaterials] = useState([]);

  let disable=true;
  if (flexro.finalstatus === 'Submitted' || flexro.finalstatus === 'Draft' || flexro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newflexroa) => {
    let tnet=0;
    const newflexro = {...newflexroa.cost};
    newflexro.flexamt = newflexroa.advt.map(item => item.flexamt).reduce((acc,curr)=>acc+curr, 0);
    
    tnet = newflexro.flexamt;
    newflexro.disc = Math.round((tnet * newflexro.discper/100)*100)/100;
    tnet = tnet - newflexro.disc;
    newflexro.gst = Math.round((tnet * newflexro.gstper/100)*100)/100;
    tnet = tnet + newflexro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newflexro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newflexro.roundoff = Math.round(-roff*100)/100;
    }
    newflexro.netamt = tnet;
    if (newflexroa.agency.dtype === 'Unregistered' ) {
      tnet = newflexro.netamt-newflexro.gst;
      newflexro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newflexro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newflexro.roundoff = Math.round(-roff*100)/100;
      }
      newflexro.netamt=tnet;
    }
    newflexroa.cost = newflexro;
    setflexro(newflexroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/mediatype/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setMediatypes(response.data.mediatypes);
      setLittypes(response.data.littypes);
      setFlexmaterials(response.data.flexmaterials);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.flexrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newflexro = {...history.location.state.flexrodata};
      const selcentres = units.filter((el) => {
        return newflexro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      let authOptions = {
        method: 'PUT',
        url: '/cities/bystate',
        data: {'state':newflexro.bstate},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setCities(response.data);
        setflexro(newflexro);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setCities([]);
        setflexro(newflexro);
      });
    }
  },[history.location.state.mode,history.location.state.flexrodata,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newflexro = {...flexro};
    newflexro[`${name}`] = val;
    setflexro(newflexro);
  }
  // const onInputDateChange = (e, name) => {
  //   let val = (e.target && e.target.value) || '';
  //   let newflexro = {...flexro};
  //   newflexro[`${name}`] = val;
  //   newflexro.days = Math.ceil((new Date(newflexro.enddate).getTime() - new Date(newflexro.startdate).getTime()) / (1000*60*60*24))+1;
  //   setflexro(newflexro);
  // }
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _Item = {...flexro};
    _Item[`${name}`] = val;
    //_Item.enddate = new Date(new Date(_Item.startdate).getTime()+(val*1000*60*60*24));
    setflexro(_Item);
  };
//   <div  className="  mb-1 ml-2 m-auto">
//   <label  className=" mr-2 mb-0" >Start Date</label>
//   <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(flexro.startdate)} onChange={(e) => onInputDateChange(e, 'startdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
// </div>
// <div  className="  mb-1 ml-2 m-auto">
//   <label className="mr-2 mb-0">End Date</label>
//   <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(flexro.enddate)} onChange={(e) => onInputDateChange(e, 'enddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
// </div>
// <div  className="  mb-1 ml-2 m-auto">
//   <label className=" mr-2 mb-0" >Period</label>
//   <InputNumber inputStyle={{width:'100%'}} disabled={disable}  onChange={(e) => onInputDaysChange(e, 'period')} value={flexro.period}/>
// </div>

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    if (isNaN(val) === false) {
      let newflexro = {...flexro}
      let cst = {...newflexro.cost}
      cst[`${name}`] = (val);
      newflexro.cost = cst
      recalculate(newflexro);
    }
      //setflexro(newflexro);
  }


  const flexroSave = (event) => {
    event.preventDefault();
    if (!flexro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newflexro = {...flexro};
    if (flexro.session === "") {
      const xx =sessions.splice(-1);
      newflexro.session = xx[0];
    };
    if (flexro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!flexro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!flexro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (flexro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!flexro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!flexro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newflexro.status.push(status);
      const advts = newflexro.advt.map(e => {
          if (e._id < 9000) {
            const {_id, ...ni} = e;
            return ni;
          } else {
            return e;
          }
      });
      newflexro.advt = advts;
      newflexro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/flexro/', 
        data: newflexro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newflexro.status.push(status);
      const advts = newflexro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
    });
    newflexro.advt = advts;
    newflexro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/flexro/', 
        data: newflexro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const flexroCancel = (event) => {
    event.preventDefault();
    flexroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={flexroCancel} />
    </>
  );

  //Approve
  const flexroApproveReject = (type) => {  
    if (flexro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/flexro/approve/', 
        data: {'rono':flexro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const flexroApprove = (event) => {
    event.preventDefault();
    flexroApproveReject('Approved');
  }
  const flexroReject = (event) => {
    event.preventDefault();
    flexroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'FLX-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apflexroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={flexroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={flexroReject} />
    </>
  );

  const onVendorChange = (e) => {
    const flexronew = {...flexro};
    flexronew.agency = e.value;
    flexronew.bunit = units.find(x => x.name === 'KOTA-HO');
    flexronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+flexronew.bunit.address+"\n"+flexronew.bunit.city+"-"+flexronew.bunit.pincode+" "+flexronew.bunit.state+"\nGSTIN:"+flexronew.bunit.gstin;
    setflexro(flexronew);
  };
 const onCaptionChange = (e) => {
  const flexronew = {...flexro};
  flexronew.caption = e.value;
  //recalculate(flexronew); 
  setflexro(flexronew);
};
const onSessionChange = (e) => {
  const flexronew = {...flexro};
  flexronew.session = e.value;
  //recalculate(flexronew);    
  setflexro(flexronew);
};
const onDivisionChange = (e) => {
  const flexronew = {...flexro};
  flexronew.division = e.value;
  //recalculate(flexronew);    
  setflexro(flexronew);
};
const onCentreChange = (e) => {
  e.preventDefault();
  const flexronew = {...flexro};
  flexronew.centres = e.value;
  flexronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    flexronew.costalloc = final2;
  }
  setflexro(flexronew);

};


const onStateChange  = (e) => {
  e.preventDefault();
  const flexronew = {...flexro};
  flexronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });

  setselUnits(selcentres!==null?selcentres:'');
  let authOptions = {
    method: 'PUT',
    url: '/cities/bystate',
    data: {'state':flexronew.bstate},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
    setCities(response.data);
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setCities([]);
  });

  //setselUnits(units.filter(es => es.state === e.value));
  setflexro(flexronew);
};
const onBunitChange = (e) => {
  const flexronew = {...flexro};
  flexronew.bunit = e.target.value;
  flexronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setflexro(flexronew);
}
const onOldRoChange = (e) => {
    e.preventDefault();
    const flexronew = {...flexro};
    flexronew.flexrono = e.target.value;
    setflexro(flexronew);
}
const onOldRoBlur = (e) => {
  let authOptions = {
    method: 'PUT',
    url: '/ooro/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newflexro = response.data;
      newflexro.rono = ''
      newflexro.rodate = new Date();
      newflexro.cost.flexamt=0
      newflexro.cost.discper=0
      newflexro.cost.disc=0
      newflexro.cost.gstper=18
      newflexro.cost.gst=0
      newflexro.cost.roundoff=0
      newflexro.cost.netamt=0
      newflexro.tandc=''
      newflexro.status = []
      const selcentres = units.filter((el) => {
        return newflexro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      let authOptions = {
        method: 'PUT',
        url: '/cities/bystate',
        data: {'state':newflexro.bstate},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setCities(response.data);
        setflexro(newflexro);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setCities([]);
        setflexro(newflexro);
      });
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setflexro(flexroinit);
  });
}

// const dateEditor = (flexroductKey, props) => {
//   return  <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} value={props.rowData['dop']}
//   onChange={(e) => onEditorValueChange(flexroductKey, props, e.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
// }
const saveItems = (i) => {
  let newflexro = {...flexro};
  newflexro.advt = i;
  recalculate(newflexro);
  //setflexro(newflexro);
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flexroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && flexro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flexroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (flexro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flexroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Flex - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="flexrono" readOnly value={flexro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="flexroDate" name="roDate" value={new Date(flexro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={flexro.session} onChange={onSessionChange} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={flexro.division} onChange={onDivisionChange} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Outdoor RO</label>
            <InputText disabled={disable} style={{width:'250px'}} id="rro"  value={flexro.flexrono} onChange={onOldRoChange} onBlur={onOldRoBlur} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={flexro.agency} onChange={onVendorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="flexroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={flexro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={flexro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="flexrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={flexro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto', padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="  mb-1 ml-2 ">
          <label className=" mr-2 mb-0" >FOC - Qty</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable}  min='0'  onChange={(e) => onInputNumberChange(e, 'focqty')} value={flexro.focqty}/>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Flexrodetail disabled={disable} dc={cities}  mediatypes={mediatypes} littypes={littypes} flexmaterials={flexmaterials} pdate={flexro.rodate} Items={flexro.advt} mode={history.location.state.mode}  SaveItems={saveItems} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Flex Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.flexamt}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="flexroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Discount</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="flexrono"  value={flexro.cost.discper} onValueChange={(e) => onInputChangeamt(e, 'discper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.disc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.flexamt-flexro.cost.disc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="flexrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="flexrono"  value={flexro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="flexrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="flexroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="flexrono" readOnly value={flexro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={flexro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={flexro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Delivery Date & Address</label>
            <Calendar  style={{}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="dlyDate" name="dlydate" value={new Date(flexro.dlydate)} onChange={(e) => onInputChange(e, 'dlydate')} mask="99/99/9999" dateFormat="dd/mm/yy" />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={flexro.dlyaddress} onChange={(e) => onInputChange(e, 'dlyaddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={flexro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={flexro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={flexro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {flexro && <span>Are you sure you want to Cancel this RO <b>{flexro.rono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apflexroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Flexro);