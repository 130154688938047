import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Tvrodetail from "./tvrodetail";

import Statuslog from "../../statuslog"
import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Tvro = (props) => {
  const [sessions,setsessions] = useState([]);
  let tvroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    centres:[],
    costalloc:[],
    tvmain:[],
    caption:'',
    startdate:new Date().toString(),
    enddate:new Date().toString(),
    days:0,
    fctper:0,
    tsec:0,
    advt:[],
    cost: {
      grossamt:0,
      pdiscper:0,
      pdisc:0,
      addldiscper:0,
      addldisc:0,
      agencydiscper:0,
      agencydisc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bstate:'',
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [tvro,settvro] = useState(tvroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [tvmains,settvmains] = useState([]);
  const [seltvmain,setseltvmain] = useState([]);
  const [tvsubs,settvsubs] = useState([]);
  const [spots, setSpots] = useState(null);
  const [timebands, setTimebands] = useState(null);
  const [tvpropertys, setPropertys] = useState(null);
  const [oldRo,setOldRo] = useState('');

  let disable=true;
  if (tvro.finalstatus === 'Submitted' || tvro.finalstatus === 'Draft' || tvro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newtvroa) => {
    let tnet=0;
    const newtvro = {...newtvroa.cost};
    newtvro.grossamt = newtvroa.advt.map(item => item.amt).reduce((acc,curr)=>acc+curr, 0);
    tnet = newtvro.grossamt;
    newtvro.pdisc = Math.round((tnet * newtvro.pdiscper/100)*100)/100;
    tnet = tnet + newtvro.pdisc;
    newtvro.addldisc = Math.round((tnet * newtvro.addldiscper/100)*100)/100;
    tnet = tnet - newtvro.addldisc;
    newtvro.agencydisc = Math.round((tnet * newtvro.agencydiscper/100)*100)/100;
    tnet = tnet - newtvro.agencydisc;
    newtvro.gst = Math.round((tnet * newtvro.gstper/100)*100)/100;
    tnet = tnet + newtvro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newtvro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newtvro.roundoff = Math.round(-roff*100)/100;
    }
    newtvro.netamt = tnet;
    if (newtvroa.agency.dtype === 'Unregistered' ) {
      tnet = newtvro.netamt-newtvro.gst;
      newtvro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newtvro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newtvro.roundoff = Math.round(-roff*100)/100;
      }
      newtvro.netamt=tnet;
    }
    newtvroa.cost = newtvro;
    settvro(newtvroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {

      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/tvmain/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const tvstation = response.data;
      settvmains(tvstation);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
    axios.get('/spot/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const spots = response.data;
      setSpots(spots);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
    axios.get('/tvtimeband/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const tb = response.data;
      setTimebands(tb);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
    axios.get('/tvproperty/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const tb = response.data;
      setPropertys(tb);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
  },[props.storeid]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      // let authOptions = {
      //   method: 'PUT',
      //   url: '/tvmain/np',
      //   data: {'npid':history.location.state.tvrodata.tvmain[0].tvstation._id},
      //   headers : {
      //       'x-auth-token' : localStorage.getItem('token')
      //   }
      // };
      // axios(authOptions)
      //   .then(response => {
      //   settvmains(response.data);
      // })
      // .catch(error => {
      //   toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      //   settvmains([]);
      // });
    } else {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
      }
  },[history.location.state.mode,history.location.state.tvrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newtvro = {...history.location.state.tvrodata};
      const ed =tvmains.find(e => e._id === history.location.state.tvrodata.tvmain[0]._id);
      let final=[];
      const final1 = history.location.state.tvrodata.tvmain.map(e => final.concat(e.tvsubs));
      final = [].concat(...final1);
      const newadvt = newtvro.advt.map(ea => ({ ...ea, tvsub : final.find(e => e._id.toString() === ea.tvsub.toString())}))
      newadvt.sort((a, b) => {
        let fa = a.tvsub.tvsub.toLowerCase(),
            fb = b.tvsub.tvsub.toLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
      });
      
      newtvro.advt = newadvt;
      const selcentres = units.filter((el) => {
        return newtvro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setseltvmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      settvsubs(final);
      settvro(newtvro);
    }
  },[history.location.state.mode,history.location.state.tvrodata,tvmains,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newtvro = {...tvro};
    newtvro[`${name}`] = val;
    settvro(newtvro);
  }
  const onInputDateChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newtvro = {...tvro};
    newtvro[`${name}`] = val;
    newtvro.days = Math.ceil((new Date(newtvro.enddate).getTime() - new Date(newtvro.startdate).getTime()) / (1000*60*60*24))+1;
    settvro(newtvro);
  }
  const onInputDaysChange = (e, name) => {
    const val = e.value || 0;
    let _Item = {...tvro};
    _Item[`${name}`] = val;
    //_Item.enddate = new Date(new Date(_Item.startdate).getTime()+(val*1000*60*60*24));
    settvro(_Item);
  };
  
  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newtvro = {...tvro}
    let cst = {...newtvro.cost}
    cst[`${name}`] = (val);
    newtvro.cost = cst
    recalculate(newtvro);
    //settvro(newtvro);
  }


  const tvroSave = (event) => {
    event.preventDefault();
    if (!tvro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newtvro = {...tvro};
    if (tvro.session === "") {
      const xx =sessions.splice(-1);
      newtvro.session = xx[0];
    };
    if (tvro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!tvro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!tvro.tvmain) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Print Media', life: 3000 });
      return;
    };
    if (tvro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!tvro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!tvro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newtvro.status.push(status);
      const advts = newtvro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newtvro.advt = advts;
      newtvro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/tvro/', 
        data: newtvro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newtvro.status.push(status);
      const advts = newtvro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newtvro.advt = advts;
      newtvro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/tvro/', 
        data: newtvro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const tvroCancel = (event) => {
    event.preventDefault();
    tvroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={tvroCancel} />
    </>
  );

  //Approve
  const tvroApproveReject = (type) => {  
    if (tvro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/tvro/approve/', 
        data: {'rono':tvro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const tvroApprove = (event) => {
    event.preventDefault();
    tvroApproveReject('Approved');
  }
  const tvroReject = (event) => {
    event.preventDefault();
    tvroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'TV-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const aptvroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={tvroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={tvroReject} />
    </>
  );

  if (saved)  history.goBack();

  const onVendorChange = (e) => {
    const tvronew = {...tvro};
    tvronew.agency = e.value;
    tvronew.bunit = units.find(x => x.name === 'KOTA-HO');
    tvronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+tvronew.bunit.address+"\n"+tvronew.bunit.city+"-"+tvronew.bunit.pincode+" "+tvronew.bunit.state+"\nGSTIN:"+tvronew.bunit.gstin;
    settvro(tvronew);
  };
 const onCaptionChange = (e) => {
  const tvronew = {...tvro};
  tvronew.caption = e.value;
  //recalculate(tvronew); 
  settvro(tvronew);
};
const onSessionChange = (e) => {
  const tvronew = {...tvro};
  tvronew.session = e.value;
  //recalculate(tvronew);    
  settvro(tvronew);
};
const onDivisionChange = (e) => {
  const tvronew = {...tvro};
  tvronew.division = e.value;
  //recalculate(tvronew);    
  settvro(tvronew);
};
 const onMediaChange = (e) => {
  const tvronew = {...tvro};
  tvronew.tvmain = e.value;
  let final=[];
  const final1 = final.concat(e.value.tvsubs);
  settvsubs([].concat(...final1));
  setseltvmain(e.value);
  settvro(tvronew);
};
const onCentreChange = (e) => {
  const tvronew = {...tvro};
  tvronew.centres = e.value;
  tvronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    tvronew.costalloc = final2;
  }
  settvro(tvronew);
};

const onStateChange  = (e) => {
  e.preventDefault();
  const tvronew = {...tvro};
  tvronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });
  setselUnits(selcentres!==null?selcentres:'');
  //setselUnits(units.filter(es => es.state === e.value));
  settvro(tvronew);
};
const onBunitChange = (e) => {
  const tvronew = {...tvro};
  tvronew.bunit = e.target.value;
  tvronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  settvro(tvronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}

const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/tvro/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newtvro = response.data;
      newtvro.rono='';
      newtvro.rodate = new Date();
      // let authOptions = {
      //   method: 'PUT',
      //   url: '/tvmain/np',
      //   data: {'npid':newtvro.tvmain[0].tvstation._id},
      //   headers : {
      //       'x-auth-token' : localStorage.getItem('token')
      //   }
      // };
      // axios(authOptions)
      //   .then(response => {
        const ed =tvmains.find(e => e._id === history.location.state.tvrodata.tvmain._id);
        let final=[];
        const final1 = newtvro.tvmain.map(e => final.concat(e.tvsubs));
        final = [].concat(...final1);
        const newadvt = newtvro.advt.map(ea => ({ ...ea, tvsub : final.find(e => e._id === ea.tvsub)}))
        newadvt.sort((a, b) => {
          let fa = a.tvsub.tvsub.toLowerCase(),
              fb = b.tvsub.tvsub.toLowerCase();
      
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
        
        newtvro.advt = newadvt;
        const selcentres = units.filter((el) => {
          return newtvro.bstate.some((f) => {
            return f === el.state;
          });
        });
        settvmains(response.data);
        setseltvmain(ed);
        setselUnits(selcentres!==null?selcentres:'');
        settvsubs(final);
        settvro(newtvro);
        })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        settvmains([]);
      });
  }


// const dateEditor = (tvroductKey, props) => {
//   return  <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} value={props.rowData['dop']}
//   onChange={(e) => onEditorValueChange(tvroductKey, props, e.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
// }
const saveItems = (i) => {
  let newtvro = {...tvro};
  newtvro.advt = i;
  recalculate(newtvro);
  //settvro(newtvro);
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={tvroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && tvro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={tvroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (tvro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={tvroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">TV - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-3 ml-1">RO_No</label>
              <InputText style={{width:'140px'}}className="" disabled={disable}  htmlFor="tvrono" readOnly value={tvro.rono}/>
            </div>
            <div  className="field grid mb-1 ml-2">
              <label htmlFor="rro" className=" mr-1 ml-2 mb-0">Copy RO</label>
              <InputText disabled={disable} style={{width:'140px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="ml-1 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="tvroDate" name="roDate" value={new Date(tvro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
            <div  className="field grid mb-1">
              <label htmlFor="session" className="ml-4 mr-2 mb-0">Session</label>
              <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'145px'}} id="session"  options={sessions} value={tvro.session} onChange={onSessionChange} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1">
              <label htmlFor="division" className="ml-1 mr-2 mb-0">Division</label>
              <Dropdown disabled={disable} filter filterBy='name' style={{width:'350px'}} placeholder="Select Division" id="division"  options={divisions} value={tvro.division} onChange={onDivisionChange} />
            </div>
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="tvroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={tvro.agency} onChange={onVendorChange} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="np" className="w-2 mr-2 mb-0">TV Channel</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select TV Channel"style={{width:'300px'}} id="np" optionLabel="name"  options={tvmains} value={seltvmain} onChange={onMediaChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="tvroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={tvro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={tvro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
              
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="tvrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={tvro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto', padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="  mb-1 ml-2 m-auto">
          <label  className=" mr-2 mb-0" >Start Date</label>
          <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(tvro.startdate)} onChange={(e) => onInputDateChange(e, 'startdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className="mr-2 mb-0">End Date</label>
          <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(tvro.enddate)} onChange={(e) => onInputDateChange(e, 'enddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className=" mr-2 mb-0" >No of Days</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable}  onChange={(e) => onInputDaysChange(e, 'days')} value={tvro.days}/>
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className=" mr-2 mb-0" >FCT(%)</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable} minFractionDigits={2} min='0' max='100' onChange={(e) => onInputDaysChange(e, 'fctper')} value={tvro.fctper}/>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Tvrodetail disabled={disable} sdt={tvro.startdate} edt={tvro.enddate} days={tvro.days} fctper={tvro.fctper} spots={spots} tvpropertys={tvpropertys} timebands={timebands} pdate={tvro.rodate} Items={tvro.advt} mode={history.location.state.mode}  SaveItems={saveItems} tvsubs={tvsubs} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.grossamt}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="division" style={{width:'20%'}} className="mr-2 mb-0">TV(Prm/Disc)(+/-)</label>
            <InputNumber  minFractionDigits={2} min="-100" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}} suffix="%" disabled={disable}  htmlFor="tvrono"  value={tvro.cost.pdiscper} onValueChange={(e) => onInputChangeamt(e, 'pdiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}} className="w-auto"  disabled={true} htmlFor="tvrono" readOnly value={tvro.cost.pdisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.grossamt+tvro.cost.pdisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="tvroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Additional Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="tvrono"  value={tvro.cost.addldiscper} onValueChange={(e) => onInputChangeamt(e, 'addldiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.addldisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.grossamt+tvro.cost.pdisc-tvro.cost.addldisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="tvroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Agency Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="tvrono"  value={tvro.cost.agencydiscper} onValueChange={(e) => onInputChangeamt(e, 'agencydiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.agencydisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.grossamt+tvro.cost.pdisc-tvro.cost.addldisc-tvro.cost.agencydisc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="tvrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="tvrono"  value={tvro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="tvrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="tvroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="tvrono" readOnly value={tvro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={tvro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={tvro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={tvro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={tvro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={tvro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {tvro && <span>Are you sure you want to Cancel this RO <b>{tvro.tvrono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={aptvroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Tvro);