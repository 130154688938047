
import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import './flexro.css'
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { useHistory } from "react-router-dom";

 const Flexrotable = (props) => {
  const history = useHistory()
  const [Items, setItems] = useState([]);
  const [initItems, setInitItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [TcDialog, setTcDialog] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(null);
  const [frDate, setfrDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [tandc, settandc] = useState('');
  const [RO, setRO] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState(['Draft','Submitted']);
  const toast = useRef(null);
  const dt = useRef(null);

  const statuses = [
    "Draft",
    "Submitted",
    "Approved",
    "Rejected",
    "Closed",
    "Cancel",
    "Verified"
  ]
  useEffect(() => {
    let authOptions = {
      method: 'POST',
      url: '/flexro/all/', 
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
    .then(response => {
      setInitItems(response.data);
      setItems(response.data);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    });
    axios.get('/tandcflex/', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
        settandc(response.data.tandc);
    })
    .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        settandc('');
    });
  }, [props.storeid]);


  
  const onTandcChange = (e, name) => {
    // let val = (e.target && e.target.value) || '';
    // let newtc = {...tandc};
    // newtc[`${name}`] = val;
    //let nro={...RO};
    //nro.tandc = e.target.value;
    //setRO(nro);
    settandc(e.target.value);
  }

  const PROsearch = (event) => {
    event.preventDefault();
    if ((frDate === null || toDate === null) && selectedStatuses.length===0) {
      setItems(initItems);
      return;
    }
     let authOptions = {
      method: 'POST',
      url: '/flexro/search/', 
      data: {'sdt':frDate, 'edt':toDate, 'sts': selectedStatuses},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
    .then(response => {
      setItems(response.data);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setItems([]);
    });
  }

  const formatDate = (dt) => {
    let cdt = new Date(dt);
    let month = cdt.getMonth() + 1;
    let day = cdt.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    return day+"-"+month+"-"+cdt.getFullYear() ;
  };

  const openNew = () => {
    if (!props.isAdmin) {
      const i = props.permissions.find(x => x.name === 'FLX-CREATE')
      if (!i) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Add New RO', life: 3000 }); 
        }
        else {
          history.push({
            pathname: '/FLEXRO',
            state: { mode:'New', flexrodata:null}
          });
        }
    } else {
      history.push({
        pathname: '/FLEXRO',
        state: { mode:'New', flexrodata:null}
      });
    }
  };
  
  
  const fmroprint = () => {
    const i = props.permissions.find(x => x.name === 'FLX-CREATE')
    if (props.isAdmin || i) {
      const ro = {...RO};
      ro.tandc = tandc.replace(/<AGENCY NAME>/g,ro.agency.name);
      let authOptions = {
        method: 'PUT',
        url: '/flexro/', 
        data: ro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          history.push({
            pathname: '/PRINTFLEXRO',
            state: { pro:ro }
          });
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    } else 
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Print RO', life: 3000 }); 
  };
  
  const flexroverify = (pro) => {
    const i = props.permissions.find(x => (x.name === 'FLX-VERIFY' ))
    if (props.isAdmin || i) {
      history.push({
        pathname: '/FLEXROVERIFY',
        state: { mode:'Edit',flexrodata:pro }
      });
      } else 
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Verify RO', life: 3000 }); 
  };

  const editItem = (Item) => {
    if (!props.isAdmin) {
      const i = props.permissions.find(x => x.name === 'FLX-CREATE')
      if (!i) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Modify RO', life: 3000 }); 
        }
        else {
          history.push({
            pathname: '/FLEXRO',
            state: { mode:'Edit', flexrodata:Item}
          });
        }
    } else {
      history.push({
        pathname: '/FLEXRO',
        state: { mode:'Edit', flexrodata:Item}
      });
    }
  }
  const flexrocostalloc = (pro) => {
    const i = props.permissions.find(x => (x.name === 'FLX-COST'))
    if (props.isAdmin || i) {
      history.push({
        pathname: '/FlexcostAlloc',
        state: { mode:'Edit', flexrodata:pro}
      });
    } else 
    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Allocate Cost', life: 3000 }); 
  }


  const leftToolbarTemplate = () => {
      return (
        <Button label="New" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
      );
  };

  const rightToolbarTemplate = () => {
      return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      )
  }
  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.rodate);
};
// const sdateBodyTemplate = (rowData) => {
//     return formatDate(rowData.startdate);
// };


  // const roTemplate = (rowData) => {
  //   return new Date(rowData.rodate).getFullYear().toString()+'01'+String(rowData.rono).padStart(4, '0')
  // }
  const setFLEXPrint = (Item) => {
    (!Item.tandc || Item.tandc === '')?settandc(tandc.replace(/<AGENCY NAME>/g,Item.agency.name)):settandc(Item.tandc);
    setRO(Item);
    setTcDialog(true);
};

  const actionBodyTemplate = (rowData) => {
      return (<>
        <Button icon="pi pi-pencil" className="p-button-text p-button-warning mr-2 " onClick={() => editItem(rowData)} />
        <Button icon="pi pi-print" className="p-button-text p-button-warning mr-2 " onClick={() => setFLEXPrint(rowData)} />
        <Button icon="pi pi-check" tooltip='Verify' className="p-button-text p-button-warning" onClick={() => flexroverify(rowData)} />
        <Button icon="pi pi-dollar" tooltip='Cost Alloc' className="p-button-text p-button-warning" onClick={() => flexrocostalloc(rowData)} />
        </>
      );
  };

  const hideTcDialog = () => {
    setTcDialog(false);
  };

    const TcDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideTcDialog} />
            <Button label="Print" icon="pi pi-check" className="p-button-text" onClick={fmroprint} />
        </>
    );

    return (
    <div className="PROM">
      <div className="Headerm">
        <img src={logo} onClick={() => history.push('/MEDIA')} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
        <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>Flex-ROs</h2>
        <div className="Userm">
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
        </div>
      </div>
      <div  className="Sformm h-auto mt-2">
        <div className="">
          <label htmlFor="frDate" className="mr-2 h-2rem">Date-From</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="frDate" className="h-2rem" value={frDate} onChange={(e) => setfrDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <label htmlFor="toDate" className="mx-2 h-2rem">Date-To</label>
          <Calendar maxDate={new Date()}  monthNavigator yearNavigator yearRange="2010:2030"id="toDate" className="h-2rem" value={toDate} minDate={frDate} onChange={(e) => settoDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className="">
          <MultiSelect className="ml-4 my-1 h-auto" value={selectedStatuses} options={statuses} onChange={(e) => setSelectedStatuses(e.value)}  placeholder="Select Status" />
        </div>
        <Button icon="pi pi-search" className="ml-2 p-button-text p-button-success my-1" onClick={PROsearch}/>
      </div>

      <div className="TCm mt-1">
        <Toast ref={toast} />
        <div className="card h-auto">
            <Toolbar className="mt-2 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
            <DataTable  sortField="rono" sortOrder={-1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                dataKey="rono" paginator rows={10} rowsPerPageOptions={[10, 20, 30]} style={{padding:'0px',fontSize:'14px'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                globalFilter={globalFilter} scrollable scrollHeight="400px" >

                
                <Column field="rono"  header="RO No" sortable style={{textAlign:'center',  width:'8%',padding:'0 0 0 0'}}></Column>
                <Column field="rodate" header="RO Date" sortable style={{textAlign:'center', width:'8%',padding:'0 0 0 0'}} body={dateBodyTemplate}></Column>
                <Column field="centres.0.name" header="Centre" sortable style={{textAlign:'center', width:'10%',padding:'0 0 0 0'}} ></Column>
                <Column field="division" header="Division" sortable style={{textAlign:'center', width:'10%',padding:'0 0 0 0'}} ></Column>
                <Column field="agency.name" header="Agency" sortable style={{textAlign:'center', width:'15%',padding:'0 0 0 0'}} ></Column>
                <Column field="caption"  header="Caption" sortable style={{textAlign:'center', width:'15%',padding:'0 0 0 0'}} ></Column>
                <Column field="cost.netamt" header="Amount" sortable style={{textAlign:'center', width:'8%',padding:'0 0 0 0'}}></Column>
                <Column field="finalstatus" sortable header="Status" style={{textAlign:'center', width:'10%',padding:'0 0 0 0'}}></Column>
                <Column body={actionBodyTemplate} style={{ padding:'0 2px 0 8px'}}></Column>
            </DataTable>
            <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
              <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
            </div>
            <Dialog visible={TcDialog} style={{ width: '700px' }} header="Terms & Conditions" modal footer={TcDialogFooter} onHide={hideTcDialog}>
                <div className="confirmation-content">
                <div  className="field grid mb-1 ml-4 m-auto">
                    <InputTextarea rows={15} cols={100}  style={{ height: '320px', width:'600px' }} value={tandc} onChange={(e) => onTandcChange(e,'tandc')} />
                </div>
                </div>
            </Dialog>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      storeid:state.auth.storeid,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin
  }
}

export default connect(mapStateToProps)(Flexrotable);