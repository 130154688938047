import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Fmrodetail from "./fmrodetail";

import Statuslog from "../../statuslog"
import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Fmro = (props) => {
  const [sessions,setsessions] = useState([]);
  let fmroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    centres:[],
    costalloc:[],
    fmmain:[],
    caption:'',
    startdate:new Date().toString(),
    enddate:new Date().toString(),
    days:0,
    fctper:0,
    tsec:0,
    advt:[],
    cost: {
      grossamt:0,
      pdiscper:0,
      pdisc:0,
      addldiscper:0,
      addldisc:0,
      agencydiscper:0,
      agencydisc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bstate:'',
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [fmro,setfmro] = useState(fmroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [fmmains,setfmmains] = useState([]);
  const [selfmmain,setselfmmain] = useState([]);
  const [fmsubs,setfmsubs] = useState([]);
  const [fmstations, setFmstations] = useState([]);
  const [selfmstation, setselFmstation] = useState(null);
  const [spots, setSpots] = useState(null);
  const [timebands, setTimebands] = useState(null);
  const [oldRo,setOldRo] = useState('');

  let disable=true;
  if (fmro.finalstatus === 'Submitted' || fmro.finalstatus === 'Draft' || fmro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newfmroa) => {
    let tnet=0;
    const newfmro = {...newfmroa.cost};
    newfmro.grossamt = newfmroa.advt.map(item => item.amt).reduce((acc,curr)=>acc+curr, 0);
    tnet = newfmro.grossamt;
    newfmro.pdisc = Math.round((tnet * newfmro.pdiscper/100)*100)/100;
    tnet = tnet + newfmro.pdisc;
    newfmro.addldisc = Math.round((tnet * newfmro.addldiscper/100)*100)/100;
    tnet = tnet - newfmro.addldisc;
    newfmro.agencydisc = Math.round((tnet * newfmro.agencydiscper/100)*100)/100;
    tnet = tnet - newfmro.agencydisc;
    newfmro.gst = Math.round((tnet * newfmro.gstper/100)*100)/100;
    tnet = tnet + newfmro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newfmro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newfmro.roundoff = Math.round(-roff*100)/100;
    }
    newfmro.netamt = tnet;
    if (newfmroa.agency.dtype === 'Unregistered' ) {
      tnet = newfmro.netamt-newfmro.gst;
      newfmro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newfmro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newfmro.roundoff = Math.round(-roff*100)/100;
      }
      newfmro.netamt=tnet;
    }
    newfmroa.cost = newfmro;
    setfmro(newfmroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {

      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/fmstation/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const fmstation = response.data;
      setFmstations(fmstation);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
    axios.get('/spot/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const spots = response.data;
      setSpots(spots);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
    axios.get('/timeband/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const tb = response.data;
      setTimebands(tb);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
  },[props.storeid]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      let authOptions = {
        method: 'PUT',
        url: '/fmmain/np',
        data: {'npid':history.location.state.fmrodata.fmmain[0].fmstation._id},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setfmmains(response.data);
        setselFmstation(history.location.state.fmrodata.fmmain[0].fmstation);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setfmmains([]);
      });
    } else {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
      }
  },[history.location.state.mode,history.location.state.fmrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newfmro = {...history.location.state.fmrodata};
      const ed =fmmains.filter(n => history.location.state.fmrodata.fmmain.some(n2 => n.name === n2.name));//fmmains.find(e => e._id === history.location.state.fmrodata.fmmain._id);
      let final=[];
      const final1 = history.location.state.fmrodata.fmmain.map(e => final.concat(e.fmsubs));
      final = [].concat(...final1);
      const newadvt = newfmro.advt.map(ea => ({ ...ea, fmsub : final.find(e => e._id.toString() === ea.fmsub.toString())}))
      newadvt.sort((a, b) => {
        let fa = a.fmsub.fmsub.toLowerCase(),
            fb = b.fmsub.fmsub.toLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
      });
      
      newfmro.advt = newadvt;
      const selcentres = units.filter((el) => {
        return newfmro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      setfmsubs(final);
      setfmro(newfmro);
    }
  },[history.location.state.mode,history.location.state.fmrodata,fmmains,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newfmro = {...fmro};
    newfmro[`${name}`] = val;
    setfmro(newfmro);
  }
  const onInputDateChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newfmro = {...fmro};
    newfmro[`${name}`] = val;
    newfmro.days = Math.ceil((new Date(newfmro.enddate).getTime() - new Date(newfmro.startdate).getTime()) / (1000*60*60*24))+1;
    setfmro(newfmro);
  }
  const onInputDaysChange = (e, name) => {
    const val = e.value || 0;
    let _Item = {...fmro};
    _Item[`${name}`] = val;
    //_Item.enddate = new Date(new Date(_Item.startdate).getTime()+(val*1000*60*60*24));
    setfmro(_Item);
  };
  
  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newfmro = {...fmro}
    let cst = {...newfmro.cost}
    cst[`${name}`] = (val);
    newfmro.cost = cst
    recalculate(newfmro);
    //setfmro(newfmro);
  }


  const fmroSave = (event) => {
    event.preventDefault();
    if (!fmro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newfmro = {...fmro};
    if (fmro.session === "") {
      const xx =sessions.splice(-1);
      newfmro.session = xx[0];
    };
    if (fmro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!fmro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!fmro.fmmain) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Print Media', life: 3000 });
      return;
    };
    if (fmro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!fmro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!fmro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newfmro.status.push(status);
      const advts = newfmro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newfmro.advt = advts;
      newfmro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/fmro/', 
        data: newfmro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newfmro.status.push(status);
      const advts = newfmro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newfmro.advt = advts;
      newfmro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/fmro/', 
        data: newfmro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const fmroCancel = (event) => {
    event.preventDefault();
    fmroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={fmroCancel} />
    </>
  );

  //Approve
  const fmroApproveReject = (type) => {  
    if (fmro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/fmro/approve/', 
        data: {'rono':fmro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const fmroApprove = (event) => {
    event.preventDefault();
    fmroApproveReject('Approved');
  }
  const fmroReject = (event) => {
    event.preventDefault();
    fmroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'FM-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apfmroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={fmroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={fmroReject} />
    </>
  );

  if (saved)  history.goBack();

  const onVendorChange = (e) => {
    const fmronew = {...fmro};
    fmronew.agency = e.value;
    fmronew.bunit = units.find(x => x.name === 'KOTA-HO');
    fmronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+fmronew.bunit.address+"\n"+fmronew.bunit.city+"-"+fmronew.bunit.pincode+" "+fmronew.bunit.state+"\nGSTIN:"+fmronew.bunit.gstin;
    setfmro(fmronew);
  };
 const onCaptionChange = (e) => {
  const fmronew = {...fmro};
  fmronew.caption = e.value;
  //recalculate(fmronew); 
  setfmro(fmronew);
};
const onSessionChange = (e) => {
  const fmronew = {...fmro};
  fmronew.session = e.value;
  //recalculate(fmronew);    
  setfmro(fmronew);
};
const onDivisionChange = (e) => {
  const fmronew = {...fmro};
  fmronew.division = e.value;
  //recalculate(fmronew);    
  setfmro(fmronew);
};
 const onMediaChange = (e) => {
  const fmronew = {...fmro};
  fmronew.fmmain = e.value;
  let final=[];
  const final1 = e.value.map(e => final.concat(e.fmsubs));
  setfmsubs([].concat(...final1));
  setselfmmain(e.value);
  setfmro(fmronew);
};
const onCentreChange = (e) => {
  const fmronew = {...fmro};
  fmronew.centres = e.value;
  fmronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    fmronew.costalloc = final2;
  }
  setfmro(fmronew);
};

const onFmstationChange  = (e) => {
  e.preventDefault();
  setselFmstation(e.value);
  let authOptions = {
    method: 'PUT',
    url: '/fmmain/np',
    data: {'npid':e.value._id},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
    setfmmains(response.data);
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setfmmains([]);
  });
};

const onStateChange  = (e) => {
  e.preventDefault();
  const fmronew = {...fmro};
  fmronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });
  setselUnits(selcentres!==null?selcentres:'');
  //setselUnits(units.filter(es => es.state === e.value));
  setfmro(fmronew);
};
const onBunitChange = (e) => {
  const fmronew = {...fmro};
  fmronew.bunit = e.target.value;
  fmronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setfmro(fmronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}

const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/fmro/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newfmro = response.data;
      newfmro.rono='';
      newfmro.status = []
      newfmro.rodate = new Date();
      let authOptions = {
        method: 'PUT',
        url: '/fmmain/np',
        data: {'npid':newfmro.fmmain[0].fmstation._id},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        const ed =response.data.filter(n => newfmro.fmmain.some(n2 => n.name === n2.name));//fmmains.find(e => e._id === history.location.state.fmrodata.fmmain._id);
        let final=[];
        const final1 = newfmro.fmmain.map(e => final.concat(e.fmsubs));
        final = [].concat(...final1);
        const newadvt = newfmro.advt.map(ea => ({ ...ea, fmsub : final.find(e => e._id === ea.fmsub)}))
        newadvt.sort((a, b) => {
          let fa = a.fmsub.fmsub.toLowerCase(),
              fb = b.fmsub.fmsub.toLowerCase();
      
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
        
        newfmro.advt = newadvt;
        const selcentres = units.filter((el) => {
          return newfmro.bstate.some((f) => {
            return f === el.state;
          });
        });
        setfmmains(response.data);
        setselFmstation(newfmro.fmmain[0].fmstation);
        setselfmmain(ed);
        setselUnits(selcentres!==null?selcentres:'');
        setfmsubs(final);
        setfmro(newfmro);
        })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setfmmains([]);
      });
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setfmro(fmroinit);
  });
}

// const dateEditor = (fmroductKey, props) => {
//   return  <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} value={props.rowData['dop']}
//   onChange={(e) => onEditorValueChange(fmroductKey, props, e.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
// }
const saveItems = (i) => {
  let newfmro = {...fmro};
  newfmro.advt = i;
  recalculate(newfmro);
  //setfmro(newfmro);
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={fmroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && fmro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={fmroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (fmro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={fmroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">FM - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-3 ml-1">RO_No</label>
              <InputText style={{width:'140px'}}className="" disabled={disable}  htmlFor="fmrono" readOnly value={fmro.rono}/>
            </div>
            <div  className="field grid mb-1 ml-2">
              <label htmlFor="rro" className=" mr-1 ml-2 mb-0">Copy RO</label>
              <InputText disabled={disable} style={{width:'140px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="ml-1 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="fmroDate" name="roDate" value={new Date(fmro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
            <div  className="field grid mb-1">
              <label htmlFor="session" className="ml-4 mr-2 mb-0">Session</label>
              <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'145px'}} id="session"  options={sessions} value={fmro.session} onChange={onSessionChange} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1">
              <label htmlFor="division" className="ml-1 mr-2 mb-0">Division</label>
              <Dropdown disabled={disable} filter filterBy='name' style={{width:'350px'}} placeholder="Select Division" id="division"  options={divisions} value={fmro.division} onChange={onDivisionChange} />
            </div>
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={fmro.agency} onChange={onVendorChange} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="np" className="w-2 mr-2 mb-0">FM Station</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Fmstation"style={{width:'300px'}} id="np" optionLabel="name"  options={fmstations} value={selfmstation} onChange={onFmstationChange} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmmain" className="w-2 mr-2 mb-0">Main Station</label>
            <MultiSelect 
              style={{width:'300px'}}
              filter filterBy='name' 
              disabled={disable} 
              value={selfmmain} 
              optionLabel="name"
              options={fmmains} 
              onChange={onMediaChange} 
              placeholder="Select Main Edition" 
            />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={fmro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={fmro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
              
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="fmrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={fmro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto', padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="  mb-1 ml-2 m-auto">
          <label  className=" mr-2 mb-0" >Start Date</label>
          <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(fmro.startdate)} onChange={(e) => onInputDateChange(e, 'startdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className="mr-2 mb-0">End Date</label>
          <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(fmro.enddate)} onChange={(e) => onInputDateChange(e, 'enddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className=" mr-2 mb-0" >No of Days</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable}  onChange={(e) => onInputDaysChange(e, 'days')} value={fmro.days}/>
        </div>
        <div  className="  mb-1 ml-2 m-auto">
          <label className=" mr-2 mb-0" >FCT(%)</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable} minFractionDigits={2} min='0' max='100' onChange={(e) => onInputDaysChange(e, 'fctper')} value={fmro.fctper}/>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Fmrodetail disabled={disable} sdt={fmro.startdate} edt={fmro.enddate} days={fmro.days} fctper={fmro.fctper} spots={spots} timebands={timebands} pdate={fmro.rodate} Items={fmro.advt} mode={history.location.state.mode}  SaveItems={saveItems} fmsubs={fmsubs} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.grossamt}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="division" style={{width:'20%'}} className="mr-2 mb-0">FM(Prm/Disc)(+/-)</label>
            <InputNumber  minFractionDigits={2} min="-100" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}} suffix="%" disabled={disable}  htmlFor="fmrono"  value={fmro.cost.pdiscper} onValueChange={(e) => onInputChangeamt(e, 'pdiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}} className="w-auto"  disabled={true} htmlFor="fmrono" readOnly value={fmro.cost.pdisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.grossamt+fmro.cost.pdisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="fmroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Additional Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="fmrono"  value={fmro.cost.addldiscper} onValueChange={(e) => onInputChangeamt(e, 'addldiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.addldisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.grossamt+fmro.cost.pdisc-fmro.cost.addldisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="fmroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Agency Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="fmrono"  value={fmro.cost.agencydiscper} onValueChange={(e) => onInputChangeamt(e, 'agencydiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.agencydisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.grossamt+fmro.cost.pdisc-fmro.cost.addldisc-fmro.cost.agencydisc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="fmrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="fmrono"  value={fmro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="fmrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="fmroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="fmrono" readOnly value={fmro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={fmro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={fmro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={fmro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={fmro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={fmro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {fmro && <span>Are you sure you want to Cancel this RO <b>{fmro.fmrono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apfmroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Fmro);