import React, {  useEffect } from 'react';

import MediaMainpage from "../src/media/mainpage/mainpage"
import Mediapage from "../src/media/mediamain/mediapage"
import Printrotable from "../src/media/printmedia/printro/printrotable"
import Proentry from "./media/printmedia/printro/printro"
import Caption from "./media/caption/caption"
import Session from "./media/session/session"
import Stream from "./media/stream/stream"
import Cities from "./media/outdoor/cities/cities"
import Fabrication from "./media/flyer/fabrication/fabrication"
import Lamination from "./media/flyer/lamination/lamination"
import Printtype from "./media/flyer/printtype/printtype"
import Papertype from "./media/flyer/papertype/papertype"
import Tandcfl from "./media/flyer/tandcfl"
import Division from "./media/division/division"
import Mediatable from "./media/printmedia/media/mediatable"
import Mediaentry from "./media/printmedia/media/mediaentry"
import Newspaper from "./media/printmedia/newspaper/newspaper"
import Proprint from "./media/printmedia/printro/proprint"
import Tandc from "./media/printmedia/tandc"
import Proverify from "./media/printmedia/printro/proverify"
import Mediabill from "./media/printmedia/bill/billtable"
import Billentry from "./media/printmedia/bill/billentry"
import Tvbill from "./media/tv/bill/billtable"
import TvBillentry from "./media/tv/bill/billentry"
import Fmbill from "./media/fm/bill/billtable"
import FmBillentry from "./media/fm/bill/billentry"
import Oobill from "./media/outdoor/bill/billtable"
import OoBillentry from "./media/outdoor/bill/billentry"
import Flexbill from "./media/flex/bill/billtable"
import OoBillapprove from "./media/outdoor/bill/billapprove"
import OoBilldispatch from "./media/outdoor/bill/billdispatch"
import PmBillapprove from "./media/printmedia/bill/billapprove"
import PmBilldispatch from "./media/printmedia/bill/billdispatch"
import FlyBillapprove from "./media/flyer/bill/billapprove"
import FlyBilldispatch from "./media/flyer/bill/billdispatch"
import FlxBillapprove from "./media/flex/bill/billapprove"
import FlxBilldispatch from "./media/flex/bill/billdispatch"
import FmBillapprove from "./media/fm/bill/billapprove"
import FmBilldispatch from "./media/fm/bill/billdispatch"
import TvBillapprove from "./media/tv/bill/billapprove"
import TvBilldispatch from "./media/tv/bill/billdispatch"
import BrBillapprove from "./media/branding/bill/billapprove"
import BrBilldispatch from "./media/branding/bill/billdispatch"
import SpBillapprove from "./media/sponsor/bill/billapprove"
import SpBilldispatch from "./media/sponsor/bill/billdispatch"
import FlyBillentry from "./media/flyer/bill/billentry"
import Flybill from "./media/flyer/bill/billtable"
import FlexBillentry from "./media/flex/bill/billentry"
import Fmstation from "./media/fm/fmstation/fmstation"
import Fmmain from "./media/fm/fmmain/fmmaintable"
import Fmmainentry from "./media/fm/fmmain/fmmainentry"
import Timeband from "./media/fm/timeband/timeband"
import Tandcfm from "./media/fm/tandcfm"
import Spot from "./media/fm/spot/spot"
import Fmrotable from "../src/media/fm/fmro/fmrotable"
import Fmro from "./media/fm/fmro/fmro"
import Fmroprint from "./media/fm/fmro/fmroprint"
import Fmroverify from "./media/fm/fmro/fmroverify"
import Tvmain from "./media/tv/tvmain/tvmaintable"
import Tvmainentry from "./media/tv/tvmain/tvmainentry"
import Tvtimeband from "./media/tv/tvtimeband/tvtimeband"
import Tandctv from "./media/tv/tandctv"
import Tvproperty from "./media/tv/tvproperty/tvproperty"
import Fctproperty from "./media/tv/fctproperty/fctproperty"
import Tvrotable from "../src/media/tv/tvro/tvrotable"
import Tvro from "./media/tv/tvro/tvro"
import Tvroprint from "./media/tv/tvro/tvroprint"
import Tvroverify from "./media/tv/tvro/tvroverify"
import Flexmaterial from "./media/outdoor/flexmaterial/flexmaterial"
import Mediatype from "./media/outdoor/mediatype/mediatype"
import Littype from "./media/outdoor/littype/littype"
import Tandcoo from "./media/outdoor/tandcoo"
import Tandcflex from "./media/flex/tandcflex"
import Oorotable from "../src/media/outdoor/ooro/oorotable"
import Ooro from "./media/outdoor/ooro/ooro"
import Ooroprint from "./media/outdoor/ooro/ooroprint"
import Ooroverify from "./media/outdoor/ooro/ooroverify"
import Flexrotable from "../src/media/flex/flexro/flexrotable"
import Flexro from "./media/flex/flexro/flexro"
import Flexroprint from "./media/flex/flexro/flexroprint"
import Flexroverify from "./media/flex/flexro/flexroverify"
import Flyrotable from "../src/media/flyer/flyro/flyrotable"
import Flyro from "./media/flyer/flyro/flyro"
import Flyroprint from "./media/flyer/flyro/flyroprint"
import Flyroverify from "./media/flyer/flyro/flyroverify"
import CostAlloc from "./media/printmedia/costalloc/costalloc"
import FmcostAlloc from "./media/fm/costalloc/fmcostalloc"
import TvcostAlloc from "./media/tv/costalloc/tvcostalloc"
import OocostAlloc from "./media/outdoor/costalloc/oocostalloc"
import FlexcostAlloc from "./media/flex/costalloc/flexcostalloc"
import FlycostAlloc from "./media/flyer/costalloc/flycostalloc"
import Vtable from "./Containers/vendor/vtable"
import Ventry from "./Containers/vendor/ventry"
import Brrotable from "../src/media/branding/brtable"
import Brro from "./media/branding/brentry"
import Brroprint from "./media/branding/brprint"
import Brroverify from "./media/branding/brverify"
import Tandcbr from "./media/branding/tandcbr"
import BrcostAlloc from "./media/branding/costalloc/brcostalloc"
import Brbill from "./media/branding/bill/billtable"
import BrBillentry from "./media/branding/bill/billentry"
import Sprotable from "../src/media/sponsor/sptable"
import Spro from "./media/sponsor/spentry"
import Sproprint from "./media/sponsor/spprint"
import Sproverify from "./media/sponsor/spverify"
import Tandcsp from "./media/sponsor/tandcsp"
import SpcostAlloc from "./media/sponsor/costalloc/spcostalloc"
import Spbill from "./media/sponsor/bill/billtable"
import SpBillentry from "./media/sponsor/bill/billentry"
import Fmbillpending from "./media/fm/fmro/billspending"
import Pmbillpending from "./media/printmedia/printro/billspending"
import Flybillpending from "./media/flyer/flyro/billspending"
import Flxbillpending from "./media/flex/flexro/billspending"
import Oobillpending from "./media/outdoor/ooro/billspending"
import Spbillpending from "./media/sponsor/billspending"
import Brbillpending from "./media/branding/billspending"
import Tvbillpending from "./media/tv/tvro/billspending"
import Brroreport from "./media/branding/roreport"

import './App.css';
//import asyncComponent from '../src/hoc/asyncComponent/asyncComponent';
import {connect} from 'react-redux';
import * as actions from './store/actions/index';
import {Route, Switch, Redirect} from 'react-router-dom';
//import Logout from '../src/Containers/Auth/logout/logout';
//const asyncAuth = asyncComponent(() => {
//  return import('./Containers/Auth/Auth')
//});
function App(props) {
  useEffect(() => {
    props.onTryAutoSignup();
  });
  let routes = (
      <Switch>
        <Route path="/" exact component={MediaMainpage} />
        <Redirect to="/" />
      </Switch>
      );
  if (props.isAuth)
    routes = (
    <Switch>

      <Route path="/MEDIA" exact component={Mediapage} />
      <Route path="/PROTABLE" exact component={Printrotable} />
      <Route path="/PRINTRO" exact component={Proentry} />
      <Route path="/caption" exact component={Caption} />
      <Route path="/session" exact component={Session} />
      <Route path="/division" exact component={Division} />
      <Route path="/newspaper" exact component={Newspaper} />
      <Route path="/stream" exact component={Stream} />
      <Route path="/cities" exact component={Cities} />
      <Route path="/fabrication" exact component={Fabrication} />
      <Route path="/lamination" exact component={Lamination} />
      <Route path="/printtype" exact component={Printtype} />
      <Route path="/papertype" exact component={Papertype} />
      <Route path="/TANDCFL" exact component={Tandcfl} />
      <Route path="/Mediatable" exact component={Mediatable} />
      <Route path="/Mediaentry" exact component={Mediaentry} />
      <Route path="/Vtable" exact component={Vtable} />
      <Route path="/Ventry" exact component={Ventry} />
      <Route path="/PROPRINT" exact component={Proprint} />
      <Route path="/TANDC" exact component={Tandc} />
      <Route path="/PROVERIFY" exact component={Proverify} />
      <Route path="/FLYBILL" exact component={Flybill} />
      <Route path="/FlyBillentry" exact component={FlyBillentry} />
      <Route path="/TVBILL" exact component={Tvbill} />
      <Route path="/TvBillentry" exact component={TvBillentry} />
      <Route path="/FMBILL" exact component={Fmbill} />
      <Route path="/FmBillentry" exact component={FmBillentry} />
      <Route path="/OOBILL" exact component={Oobill} />
      <Route path="/OoBillentry" exact component={OoBillentry} />
      <Route path="/OOBILLApprove" exact component={OoBillapprove} />
      <Route path="/OOBILLDispatch" exact component={OoBilldispatch} />
      <Route path="/PMBILLApprove" exact component={PmBillapprove} />
      <Route path="/PMBILLDispatch" exact component={PmBilldispatch} />
      <Route path="/FLYBILLApprove" exact component={FlyBillapprove} />
      <Route path="/FLYBILLDispatch" exact component={FlyBilldispatch} />
      <Route path="/FLXBILLApprove" exact component={FlxBillapprove} />
      <Route path="/FLXBILLDispatch" exact component={FlxBilldispatch} />
      <Route path="/FMBILLApprove" exact component={FmBillapprove} />
      <Route path="/FMBILLDispatch" exact component={FmBilldispatch} />
      <Route path="/TVBILLApprove" exact component={TvBillapprove} />
      <Route path="/TVBILLDispatch" exact component={TvBilldispatch} />
      <Route path="/BRBILLApprove" exact component={BrBillapprove} />
      <Route path="/BRBILLDispatch" exact component={BrBilldispatch} />
      <Route path="/SPBILLApprove" exact component={SpBillapprove} />
      <Route path="/SPBILLDispatch" exact component={SpBilldispatch} />
      <Route path="/FLEXBILL" exact component={Flexbill} />
      <Route path="/FlexBillentry" exact component={FlexBillentry} />
      <Route path="/MEDIABILL" exact component={Mediabill} />
      <Route path="/Billentry" exact component={Billentry} />
      <Route path="/Fmmain" exact component={Fmmain} />
      <Route path="/timeband" exact component={Timeband} />
      <Route path="/TANDCFM" exact component={Tandcfm} />
      <Route path="/spot" exact component={Spot} />
      <Route path="/FMTABLE" exact component={Fmrotable} />
      <Route path="/FMRO" exact component={Fmro} />
      <Route path="/FMROVERIFY" exact component={Fmroverify} />
      <Route path="/PRINTFMRO" exact component={Fmroprint} />
      <Route path="/Fmmainentry" exact component={Fmmainentry} />
      <Route path="/fmstation" exact component={Fmstation} />
      <Route path="/Tvmain" exact component={Tvmain} />
      <Route path="/tvtimeband" exact component={Tvtimeband} />
      <Route path="/TANDCTV" exact component={Tandctv} />
      <Route path="/tvproperty" exact component={Tvproperty} />
      <Route path="/TVTABLE" exact component={Tvrotable} />
      <Route path="/TVRO" exact component={Tvro} />
      <Route path="/TVROVERIFY" exact component={Tvroverify} />
      <Route path="/PRINTTVRO" exact component={Tvroprint} />
      <Route path="/Tvmainentry" exact component={Tvmainentry} />
      <Route path="/fctproperty" exact component={Fctproperty} />
      <Route path="/Flexmaterial" exact component={Flexmaterial} />
      <Route path="/Mediatype" exact component={Mediatype} />
      <Route path="/TANDCOO" exact component={Tandcoo} />
      <Route path="/littype" exact component={Littype} />
      <Route path="/OOTABLE" exact component={Oorotable} />
      <Route path="/OORO" exact component={Ooro} />
      <Route path="/PRINTOORO" exact component={Ooroprint} />
      <Route path="/OOROVERIFY" exact component={Ooroverify} />
      <Route path="/TANDCFLEX" exact component={Tandcflex} />
      <Route path="/FLEXTABLE" exact component={Flexrotable} />
      <Route path="/FLEXRO" exact component={Flexro} />
      <Route path="/PRINTFLEXRO" exact component={Flexroprint} />
      <Route path="/FLEXROVERIFY" exact component={Flexroverify} />
      <Route path="/FLYTABLE" exact component={Flyrotable} />
      <Route path="/FLYRO" exact component={Flyro} />
      <Route path="/PRINTFLYRO" exact component={Flyroprint} />
      <Route path="/FLYROVERIFY" exact component={Flyroverify} />
      <Route path="/BRTABLE" exact component={Brrotable} />
      <Route path="/BRRO" exact component={Brro} />
      <Route path="/PRINTBRRO" exact component={Brroprint} />
      <Route path="/BRROVERIFY" exact component={Brroverify} />
      <Route path="/TANDCBR" exact component={Tandcbr} />
      <Route path="/BRBILL" exact component={Brbill} />
      <Route path="/BrBillentry" exact component={BrBillentry} />
      <Route path="/SPTABLE" exact component={Sprotable} />
      <Route path="/SPRO" exact component={Spro} />
      <Route path="/PRINTSPRO" exact component={Sproprint} />
      <Route path="/SPROVERIFY" exact component={Sproverify} />
      <Route path="/TANDCSP" exact component={Tandcsp} />
      <Route path="/SPBILL" exact component={Spbill} />
      <Route path="/SpBillentry" exact component={SpBillentry} />
      <Route path="/CostAlloc" exact component={CostAlloc} />
      <Route path="/TvcostAlloc" exact component={TvcostAlloc} />
      <Route path="/FmcostAlloc" exact component={FmcostAlloc} />
      <Route path="/OocostAlloc" exact component={OocostAlloc} />
      <Route path="/FlexcostAlloc" exact component={FlexcostAlloc} />
      <Route path="/FlycostAlloc" exact component={FlycostAlloc} />
      <Route path="/BrcostAlloc" exact component={BrcostAlloc} />
      <Route path="/SpcostAlloc" exact component={SpcostAlloc} />
      <Route path="/fmbillpending" exact component={Fmbillpending} />
      <Route path="/flybillpending" exact component={Flybillpending} />
      <Route path="/flxbillpending" exact component={Flxbillpending} />
      <Route path="/pmbillpending" exact component={Pmbillpending} />
      <Route path="/oobillpending" exact component={Oobillpending} />
      <Route path="/brbillpending" exact component={Brbillpending} />
      <Route path="/spbillpending" exact component={Spbillpending} />
      <Route path="/tvbillpending" exact component={Tvbillpending} />
      <Route path="/brroreport" exact component={Brroreport} />
      <Route path="/" exact component={MediaMainpage} />
      <Redirect to="/" />
    </Switch>
    )

    return (
    <div >
      {routes}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth:state.auth.token !== null,
    isAdmin:state.auth.isAdmin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup:() => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
