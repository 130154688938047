import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const EditionsEntry = (props) => {
    let emptyEdition = {
        edition:''
    };

    const [Editions, setEditions] = useState([]);
    const [EditionDialog, setEditionDialog] = useState(false);
    const [deleteEditionDialog, setDeleteEditionDialog] = useState(false);
    const [Edition, setEdition] = useState(emptyEdition);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
          setEditions(props.Edition);
    },[props.Edition]);

    const openNew = () => {
        setEdition(emptyEdition);
        setEditionDialog(true);
    };

    const hideDialog = () => {
        setEdition(emptyEdition);
        setEditionDialog(false);
    };

    const hideDeleteEditionDialog = () => {
        setDeleteEditionDialog(false);
    };

    const saveEdition = (event) => {
        event.preventDefault();
               
        if (Edition.edition.trim()) {
            let _Editions = [...Editions];
            let _Edition = {...Edition};
            const index = findIndexByName(Edition.edition);
            if (index >= 0) 
            {    _Editions[index] = _Edition;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Updated', life: 3000 });
            }
            else {
                _Editions.push(_Edition);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Created', life: 3000 });
            }
            setEditions(_Editions); 
            props.SaveEditions(_Editions);
            setEditionDialog(false);
            setEdition(emptyEdition);
        }
    };

    const editEdition = (Edition) => {
        setEdition(Edition);
        setEditionDialog(true);
    };

    const confirmDeleteEdition = (Edition) => {
        setEdition(Edition);
        setDeleteEditionDialog(true);
    };

    const deleteEdition = () => {
        let _Editions = Editions.filter(val => val.edition !== Edition.edition);
        setEditions(_Editions);
        setDeleteEditionDialog(false);
        setEdition(emptyEdition);
        props.SaveEditions(_Editions);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Editions.length; i++) {
            if (Editions[i].edition === name) {
                index = i;
                break;
            }
        }
        return index;
    };

     const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Edition = {...Edition};
        _Edition[`${name}`] = val;
        setEdition(_Edition);
    };
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disable} label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disable && !props.receiving} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={() => editEdition(rowData)} />
            <Button disabled={props.disable} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteEdition(rowData)} />
            </>
        );
    };

    const deleteEditionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEditionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteEdition} />
        </>
    );

    return (
        <div className="itemtablepr">
            <Toast ref={toast} />

            <div className="card">
                <h3 style={{margin:'0',textAlign:'center',padding:'0'}}>List Of Sub Edition</h3>
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable  resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Editions} 
                    dataKey="edition" sortField="edition" sortOrder={1} paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Edition"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >
                    <Column field="edition" header="Sub Edition Name" sortable style={{width:'80%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 0 0 8px'}}></Column>
                </DataTable>
            </div>
            
            <Dialog visible={EditionDialog} style={{ width: '700px' }} header="Sub Edition Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveEdition} onReset={hideDialog}> 
                    <div className="field col mt-1">
                        <label htmlFor="edition">Sub Edition Name*</label>
                        <InputText  autoComplete="off" id="edition" value={Edition.edition} required onChange={(e) => onInputChange(e, 'edition')} />
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                        </div>
                        <div className="field col">
                            <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteEditionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEditionDialogFooter} onHide={hideDeleteEditionDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Edition && <span>Are you sure you want to delete <b>{Edition.edition}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default EditionsEntry;