import React from 'react';
import '../mainpage/mainpage.css';
import Auth from "../auth/Auth"
import logo from "../../assets/allen.png";
import { connect } from 'react-redux';

const mainpage = (props) => {
  if (props.token === null){
    localStorage.removeItem('token');}
  return (
    <div className="wAppacc">
      <div className="wApp-mainacc">
        <div className="wApp-loginacc">
          <span style={{margin:'20px 0 120px 0'}}>For help and Support Contact : 9828137504</span>
          <Auth />
        </div> 
        <div className="wApp-messageacc">
          <div >
            <img src={logo} alt="Allen" height={80} width={280}></img>
          </div>
          <div className="wApp-titleacc mt-6">
            <h2 style={{marginBottom:'0px'}}>Commercial Resource Management System </h2>
            <h2 style={{marginTop:'0px',color:'dodgerblue'}}>Media & Marketing</h2>
          </div>
          <h2>Important Instructions</h2>
          <p>In this module Release Orders for various Media Advertisements will be maintained. <br></br>
          </p>
        </div>   
      </div>
    </div>
  );
}
const mapStateToProps = state => ({token:state.auth.token});

export default connect(mapStateToProps)(mainpage);