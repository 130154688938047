import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const TvsubsEntry = (props) => {
    let emptyTvsub = {
        tvsub:''
    };

    const [Tvsubs, setTvsubs] = useState([]);
    const [TvsubDialog, setTvsubDialog] = useState(false);
    const [deleteTvsubDialog, setDeleteTvsubDialog] = useState(false);
    const [Tvsub, setTvsub] = useState(emptyTvsub);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
          setTvsubs(props.Tvsub);
    },[props.Tvsub]);

    const openNew = () => {
        setTvsub(emptyTvsub);
        setTvsubDialog(true);
    };

    const hideDialog = () => {
        setTvsub(emptyTvsub);
        setTvsubDialog(false);
    };

    const hideDeleteTvsubDialog = () => {
        setDeleteTvsubDialog(false);
    };

    const saveTvsub = (event) => {
        event.preventDefault();
               
        if (Tvsub.tvsub.trim()) {
            let _Tvsubs = [...Tvsubs];
            let _Tvsub = {...Tvsub};
            const index = findIndexByName(Tvsub.tvsub);
            if (index >= 0) 
            {    _Tvsubs[index] = _Tvsub;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvsub Updated', life: 3000 });
            }
            else {
                _Tvsubs.push(_Tvsub);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvsub Created', life: 3000 });
            }
            setTvsubs(_Tvsubs); 
            props.SaveTvsubs(_Tvsubs);
            setTvsubDialog(false);
            setTvsub(emptyTvsub);
        }
    };

    const editTvsub = (Tvsub) => {
        setTvsub(Tvsub);
        setTvsubDialog(true);
    };

    const confirmDeleteTvsub = (Tvsub) => {
        setTvsub(Tvsub);
        setDeleteTvsubDialog(true);
    };

    const deleteTvsub = () => {
        let _Tvsubs = Tvsubs.filter(val => val.tvsub !== Tvsub.tvsub);
        setTvsubs(_Tvsubs);
        setDeleteTvsubDialog(false);
        setTvsub(emptyTvsub);
        props.SaveTvsubs(_Tvsubs);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvsub Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Tvsubs.length; i++) {
            if (Tvsubs[i].tvsub === name) {
                index = i;
                break;
            }
        }
        return index;
    };

     const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Tvsub = {...Tvsub};
        _Tvsub[`${name}`] = val;
        setTvsub(_Tvsub);
    };
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disable} label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disable && !props.receiving} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={() => editTvsub(rowData)} />
            <Button disabled={props.disable} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteTvsub(rowData)} />
            </>
        );
    };

    const deleteTvsubDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTvsubDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTvsub} />
        </>
    );

    return (
        <div className="itemtablepr">
            <Toast ref={toast} />

            <div className="card">
                <h3 style={{margin:'0',textAlign:'center',padding:'0'}}>List Of Sub Channel</h3>
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable  resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Tvsubs} 
                    dataKey="tvsub" sortField="tvsub" sortOrder={1} paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Tvsub"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >
                    <Column field="tvsub" header="Sub Channel Name" sortable style={{width:'80%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 0 0 8px'}}></Column>
                </DataTable>
            </div>
            
            <Dialog visible={TvsubDialog} style={{ width: '700px' }} header="Sub Channel Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveTvsub} onReset={hideDialog}> 
                    <div className="field col mt-1">
                        <label htmlFor="tvsub">Sub Channel Name*</label>
                        <InputText  autoComplete="off" id="tvsub" value={Tvsub.tvsub} required onChange={(e) => onInputChange(e, 'tvsub')} />
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                        </div>
                        <div className="field col">
                            <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteTvsubDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTvsubDialogFooter} onHide={hideDeleteTvsubDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Tvsub && <span>Are you sure you want to delete <b>{Tvsub.tvsub}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default TvsubsEntry;