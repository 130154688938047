import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { InputNumber } from 'primereact/inputnumber';

const Flyrodetail = (props) => {
    let emptyItem = {
        printtype:'',
        content:'',
        qty:0,
        tsize:'',
        pages:'',
        pcover:'',
        pinner:'',
        quality:'',
        qcover:'',
        qinner:'',
        papertype:'',
        color:'',
        fabrication:'',
        lamination:'',
        rate:0,
        ratedesc:'',
        transportation:0,
        amt:0,
        dlydt: new Date().toString(),
        dlyatcentre:'',
        daddress:'',
        bno:0
    };
    const color = ['Multicolor', 'Single color'];
    const [actionType, setActionType] = useState('save');
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [id, setId] = useState(1);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const openNew = () => {
        // if (props.fmsubs.length === 0) {
        //     toast.current.show({severity: 'error', summary: 'Error Message', detail:'No FM Station Selected ?'});
        //     return;
        // }
        setItem(emptyItem);
        setItemDialog(true);
    };

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();

        if (!Item.tsize) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Detail Defined ?'});
            return;
        }
        if (Item.tsize.trim()) {
            if (actionType === 'copy') {
                let _Items = [...Items];
                 props.fmsubs.map( e => {
                    let _Item = {...Item};
                    _Item.fmsub = e;
                    _Items.push(_Item);
                    return 1;
                })
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
                setItemDialog(false);            
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Printing Detail Created', life: 3000 });
            }
            else {
                let _Items = [...Items];
                let _Item = {...Item};
                if (_Item._id) {
                    const index = findIndexByName(Item._id);
                    if (index >= 0) 
                    {    _Items[index] = _Item;
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Printing Detail Updated', life: 3000 });
                    }
                    else {
                        _Items.push(_Item);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Printing Detail Created', life: 3000 });
                    }
                } else {
                    _Item._id = id;
                    setId(_Item._id+1)
                    _Items.push(_Item);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Printing Detail Created', life: 3000 });
                }
                setItemDialog(false);            
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
            }
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        setItemDialog(true);
    };

    const confirmDeleteItem = (Item) => {
        setItem(Item);
        setDeleteItemDialog(true);
    };

    const deleteItem = () => {
        let _Items = Items.filter(val => val._id !== Item._id);
        setItems(_Items);
        setDeleteItemDialog(false);
        setItem(emptyItem);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Detail Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };


    const deleteSelectedItems = () => {
        let _Items = Items.filter(val => !selectedItems.includes(val));
        setItems(_Items);
        setDeleteItemsDialog(false);
        setSelectedItems(null);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted Selected', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item[`${name}`] = val;
        setItem(_Item);
    };
    const onPrinttypeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.printtype = val;
        setItem(_Item);
    };
    const onPapertypeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.papertype = val;
        setItem(_Item);
    };
    const onFabricationChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.fabrication = val;
        setItem(_Item);
    };
    const onLaminationChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.lamination = val;
        setItem(_Item);
    };
    const onDunitChange = (e) => {
        let _Item = {...Item};
        _Item.dlyatcentre = e.target.value;
        _Item.daddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
        //setselunit(e.value);
        setItem(_Item);
      }
      
    const onInputDateChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newoflyro = {...Item};
        newoflyro[`${name}`] = val;
        setItem(newoflyro);
      }
      const onInputNumberChange = (e, name) => {
        let val = (e.value || 0);
        let it = {...Item}
        it[`${name}`] = (val);
        if (name === 'qty' || name === 'rate' || name === 'transportation') {
            it.amt = (it.transportation || 0)+(it.qty * it.rate );
        }
        setItem(it);
      }
    
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disabled} label="New" icon="pi pi-plus" className="p-button-text p-button-success mr-2" onClick={openNew} />
            <Button  label="Delete" icon="pi pi-trash" className="p-button-danger p-button-text" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length || props.disabled} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disabled} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            <Button disabled={props.disabled} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteItem(rowData)} />
            </>
        );
    };
    const amtBodyTemplate = (rowData) => {
        return rowData.amt.toFixed(2);
    };
    const rateBodyTemplate = (rowData) => {
        return rowData.rate.toFixed(2);
    };
    const qtyBodyTemplate = (rowData) => {
        return rowData.qty.toFixed(2);
    };

    const deleteItemDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteItem} />
        </>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItems} />
        </>
    );

    // let cpy = null;
    // if (Items.length === 0)
    // cpy= <div className="field col">
    //         <Button type="submit" onClick={e => {
    //                         setActionType('copy');
                        
    //                     }} label="Save And Copy for All Fmsub" icon="pi pi-check" className="p-button-text"  />
    //     </div>;

    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id"  paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="printtype.name" header="Print Type" sortable style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="content" header="Content" style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="papertype.name" header="Paper Type"  style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="quality" header="Quality"  style={{width:'7%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="fabrication.name" header="Fabrication" style={{width:'12%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="lamination.name" header="Lamination"  style={{width:'12%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="pages" header="Pages" style={{width:'5%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="tsize" header="Size" style={{width:'5%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="qty" header="Qty" body={qtyBodyTemplate} style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="rate" header="Rate" body={rateBodyTemplate} style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="amt" header="Amount" body={amtBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '600px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveItem} onReset={hideDialog}> 
                    <div className=" flex mt-1">
                        <div  className="w-6 mb-1 m-auto ">
                            <label  className=" mr-2 mb-0">Print Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select print Type" style={{width:'100%'}} optionLabel="name" options={props.printtypes} value={Item.printtype} onChange={onPrinttypeChange} />
                        </div>
                        <div  className="w-3 mb-1 m-auto">
                            <label htmlFor="area" className=" mr-2 mb-0">Quantity</label>
                            <InputNumber style={{}} locale="en-IN" minFractionDigits={2}  value={Item.qty} onChange={(e) => onInputNumberChange(e,'qty')} />
                        </div>
                        <div  className="w-3 mb-1 m-auto">
                            <label htmlFor="location" className="mr-2 mb-0">Size</label>
                            <InputText  className="" value={Item.tsize} onChange={(e) => onInputChange(e,'tsize')} />
                        </div>
                    </div>
                    <div className=" flex mt-1">
                        <div  className="w-6 mb-1 m-auto ">
                            <label  className=" mr-2 mb-0">Content</label>
                            <InputText  className="" value={Item.content} onChange={(e) => onInputChange(e,'content')} />
                        </div>
                    </div>
                    <div className="flex align-items-start mt-1">
                        <div  className="w-4 mb-1 ">
                            <label  className=" mr-2 mb-0">Total Pages</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.pages} onChange={(e) => onInputNumberChange(e,'pages')} />
                        </div>
                        {Item.printtype.name === 'Booklet'?<>
                        <div  className="w-4 mb-1 ">
                            <label htmlFor="area" className=" mr-2 mb-0">Pages-Cover</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.pcover} onChange={(e) => onInputNumberChange(e,'pcover')} />
                        </div>
                        <div  className="w-4 mb-1 ">
                            <label htmlFor="location" className="mr-2 mb-0">Pages-Inner</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.pinner} onChange={(e) => onInputNumberChange(e,'pinner')} />
                        </div></>:null}
                    </div>
                    <div className="flex align-items-start mt-1">
                        <div  className="w-4 mb-1 ">
                            <label  className=" mr-2 mb-0">Quality(GSM)</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.quality} onChange={(e) => onInputNumberChange(e,'quality')} />
                        </div>
                        {Item.printtype.name === 'Booklet'?<>
                        <div  className="w-4 mb-1">
                            <label htmlFor="area" className=" mr-2 mb-0">Quality-Cover</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.qcover} onChange={(e) => onInputNumberChange(e,'qcover')} />
                        </div>
                        <div  className="w-4 mb-1 ">
                            <label htmlFor="location" className="mr-2 mb-0">Quality-Inner</label>
                            <InputNumber style={{}} locale="en-IN"  value={Item.qinner} onChange={(e) => onInputNumberChange(e,'qinner')} />
                        </div></>:null}
                    </div>
                    <div className="flex align-items-center mt-1">
                        <div  className="w-6 mb-1 m-auto">
                            <label className="mr-2 mb-0">Paper Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Paper Type"style={{width:'100%'}} optionLabel="name" options={props.papertypes} value={Item.papertype} onChange={onPapertypeChange} />
                        </div>
                        <div className='w-6 mb-1  m-auto'>
                            <label className="mr-2 mb-0" >Color</label>
                            <Dropdown placeholder="color" style={{width:'100%'}} options={color} value={Item.color} onChange={(e) => onInputChange(e,'color')} />
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div  className="w-6 mb-1 m-auto">
                            <label className="mr-2 mb-0">Fabrication</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Media Type"style={{}} optionLabel="name" options={props.fabrications} value={Item.fabrication} onChange={onFabricationChange} />
                        </div>
                        <div  className="w-6 mb-1 m-auto">
                            <label className="mr-2 mb-0">Lamination</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Lit Type"style={{}} optionLabel="name" options={props.laminations} value={Item.lamination} onChange={onLaminationChange} />
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div  className="w-12 mb-1 m-auto">
                            <label className="mr-2 mb-0">Rate Description</label>
                            <InputText  className="" value={Item.ratedesc} onChange={(e) => onInputChange(e,'ratedesc')} />
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div  className="w-4 mb-1 m-auto">
                            <label className="mr-2 mb-0">Rate Per Copy</label>
                            <InputNumber style={{}}locale="en-IN" minFractionDigits={2}  value={Item.rate} onChange={(e) => onInputNumberChange(e, 'rate')} />
                        </div>
                        <div  className="w-4 mb-1 m-auto">
                            <label className="mr-2 mb-0">Transportation</label>
                            <InputNumber style={{}}locale="en-IN"  value={Item.transportation} onChange={(e) => onInputNumberChange(e, 'transportation')} />
                        </div>
                        <div  className="w-4 mb-1  m-auto">
                            <label className="mr-2 mb-0">Amount</label>
                            <InputNumber style={{}}locale="en-IN" minFractionDigits={2}   value={Item.amt} readOnly/>
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div  className="w-6 mb-1 ">
                            <label  className="mr-2 mb-0" >Delivery Date</label>
                            <Calendar monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.dlydt)} onChange={(e) => onInputDateChange(e, 'dlydt')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
                        </div>
                        <div  className="w-6 mb-1 ">
                            <label className="mb-0" htmlFor="baddress">Delivery Address</label>
                            <Dropdown placeholder="Select Centre" className="w-full" id="units"  optionLabel="name"  options={props.units} value={Item.dlyatcentre} onChange={onDunitChange} />
                            <InputTextarea  rows='5' autoComplete="off" className={'w-full'} id="daddress" value={Item.daddress} onChange={(e) => onInputChange(e, 'daddress')} />
                        </div>
                    </div>
                    <div className="formgrid grid mt-5">
                        <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    <div className="field col">
                        <Button onClick={e => {
                            setActionType('save');
                        }} type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>

            <Dialog visible={deleteItemDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete <b>{Item.itemname}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteItemsDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete the selected Items?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default Flyrodetail;


