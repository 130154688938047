import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Flyrodetail from "./flyrodetail";

import Statuslog from "../../statuslog"
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Flyro = (props) => {
  const [sessions,setsessions] = useState([]);
  let flyroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    focqty:0,
    costalloc:[],
    advt:[],
    cost: {
      grossamt:0,
      discper:0,
      disc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [flyro,setflyro] = useState(flyroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [printtypes,setPrinttypes] = useState([]);
  const [papertypes,setPapertypes] = useState([]);
  const [fabrications,setFabrications] = useState([]);
  const [laminations,setLaminations] = useState([]);
  const [oldRo,setOldRo] = useState('');

  let disable=true;
  if (flyro.finalstatus === 'Submitted' || flyro.finalstatus === 'Draft' || flyro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newflyroa) => {
    let tnet=0;
    const newflyro = {...newflyroa.cost};
    newflyro.grossamt = newflyroa.advt.map(item => item.amt).reduce((acc,curr)=>acc+curr, 0);
    
    tnet = newflyro.grossamt;
    newflyro.disc = Math.round((tnet * newflyro.discper/100)*100)/100;
    tnet = tnet - newflyro.disc;
    newflyro.gst = Math.round((tnet * newflyro.gstper/100)*100)/100;
    tnet = tnet + newflyro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newflyro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newflyro.roundoff = Math.round(-roff*100)/100;
    }
  newflyro.netamt = tnet;
    if (newflyroa.agency.dtype === 'Unregistered' ) {
      tnet = newflyro.netamt-newflyro.gst;
      newflyro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newflyro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newflyro.roundoff = Math.round(-roff*100)/100;
      }
      newflyro.netamt=tnet;
    }
    newflyroa.cost = newflyro;
    setflyro(newflyroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/printtype/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setPrinttypes(response.data.printtypes);
      setPapertypes(response.data.papertypes);
      setFabrications(response.data.fabrications);
      setLaminations(response.data.laminations);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.flyrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newflyro = {...history.location.state.flyrodata};
      const selcentres = units.filter((el) => {
        return newflyro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselUnits(selcentres!==null?selcentres:'');
      setflyro(newflyro);
      //setselfmmain(ed);
    }
  },[history.location.state.mode,history.location.state.flyrodata,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newflyro = {...flyro};
    newflyro[`${name}`] = val;
    setflyro(newflyro);
  }

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    if (isNaN(val) === false) {
      let newflyro = {...flyro}
      let cst = {...newflyro.cost}
      cst[`${name}`] = (val);
      newflyro.cost = cst
      recalculate(newflyro);
    }
    //setflyro(newflyro);
  }


  const flyroSave = (event) => {
    event.preventDefault();
    if (!flyro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newflyro = {...flyro};
    if (flyro.session === "") {
      const xx =sessions.splice(-1);
      newflyro.session = xx[0];
    };
    if (flyro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!flyro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!flyro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (flyro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!flyro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!flyro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newflyro.status.push(status);
      const advts = newflyro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newflyro.advt = advts;
      newflyro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/flyro/', 
        data: newflyro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newflyro.status.push(status);
      const advts = newflyro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newflyro.advt = advts;
      newflyro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/flyro/', 
        data: newflyro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const flyroCancel = (event) => {
    event.preventDefault();
    flyroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={flyroCancel} />
    </>
  );

  //Approve
  const flyroApproveReject = (type) => {  
    if (flyro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/flyro/approve/', 
        data: {'rono':flyro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const flyroApprove = (event) => {
    event.preventDefault();
    flyroApproveReject('Approved');
  }
  const flyroReject = (event) => {
    event.preventDefault();
    flyroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'FLY-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apflyroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={flyroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={flyroReject} />
    </>
  );

  const onVendorChange = (e) => {
    const flyronew = {...flyro};
    flyronew.agency = e.value;
    flyronew.bunit = units.find(x => x.name === 'KOTA-HO');
    flyronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+flyronew.bunit.address+"\n"+flyronew.bunit.city+"-"+flyronew.bunit.pincode+" "+flyronew.bunit.state+"\nGSTIN:"+flyronew.bunit.gstin;
    setflyro(flyronew);
  };
 const onCaptionChange = (e) => {
  const flyronew = {...flyro};
  flyronew.caption = e.value;
  //recalculate(flyronew); 
  setflyro(flyronew);
};
const onSessionChange = (e) => {
  const flyronew = {...flyro};
  flyronew.session = e.value;
  //recalculate(flyronew);    
  setflyro(flyronew);
};
const onDivisionChange = (e) => {
  const flyronew = {...flyro};
  flyronew.division = e.value;
  //recalculate(flyronew);    
  setflyro(flyronew);
};
const onCentreChange = (e) => {
  e.preventDefault();
  const flyronew = {...flyro};
  flyronew.centres = e.value;
  flyronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
  //let final=[];
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    flyronew.costalloc = final2;
  }
  setflyro(flyronew);
// e.value.map(e1 => { 
  //   const f = flyronew.datealloc.find(e2 => e2.cname === e1.name);
  //   if (!f) flyronew.datealloc.push({'unit':e1._id, 'cname':e1.name, 'dop':flyronew.datealloc.length===0?new Date().toString():flyronew.datealloc[0].dop, 'alloc':0})
  //   return true;
  // })
  // let data = flyronew.datealloc.filter(item => e.value.findIndex(f => f.name === item.cname)+1);
  // //recalculate(flyronew); 
  // flyronew.datealloc = data;   
};


const onStateChange  = (e) => {
  e.preventDefault();
  const flyronew = {...flyro};
  flyronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });

  setselUnits(selcentres!==null?selcentres:'');

  //setselUnits(units.filter(es => es.state === e.value));
  setflyro(flyronew);
};
const onBunitChange = (e) => {
  const flyronew = {...flyro};
  flyronew.bunit = e.target.value;
  flyronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setflyro(flyronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}
const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/flyro/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newflyro = response.data;
      newflyro.rono = ''
      newflyro.status = []
      newflyro.rodate = new Date();
      const selcentres = units.filter((el) => {
        return newflyro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      setflyro(newflyro);
    })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setflyro(flyroinit);
  });
}

// const dateEditor = (flyroductKey, props) => {
//   return  <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} value={props.rowData['dop']}
//   onChange={(e) => onEditorValueChange(flyroductKey, props, e.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
// }
const saveItems = (i) => {
  let newflyro = {...flyro};
  newflyro.advt = i;
  recalculate(newflyro);
  //setflyro(newflyro);
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flyroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && flyro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flyroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (flyro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={flyroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Printing - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="flyrono" readOnly value={flyro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="flyroDate" name="roDate" value={new Date(flyro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={flyro.session} onChange={onSessionChange} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={flyro.division} onChange={onDivisionChange} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Copy Old RO</label>
            <InputText disabled={disable} style={{width:'250px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={flyro.agency} onChange={onVendorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="flyroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={flyro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={flyro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="flyrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={flyro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Flyrodetail disabled={disable} units={units} laminations={laminations} printtypes={printtypes} papertypes={papertypes} fabrications={fabrications} pdate={flyro.rodate} Items={flyro.advt} mode={history.location.state.mode}  SaveItems={saveItems} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.grossamt}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="flyroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Discount</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="flyrono"  value={flyro.cost.discper} onValueChange={(e) => onInputChangeamt(e, 'discper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.disc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.grossamt-flyro.cost.disc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="flyrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="flyrono"  value={flyro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="flyrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="flyroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="flyrono" readOnly value={flyro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={flyro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={flyro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={flyro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={flyro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={flyro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {flyro && <span>Are you sure you want to Cancel this RO <b>{flyro.rono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apflyroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Flyro);