import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Menubar } from 'primereact/menubar';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { useHistory } from "react-router-dom";

const Navbar = (props) => {
    const toast = useRef(null);
    const history = useHistory()
    const items = [
        {
            label: 'Print Media',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/PROTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/MEDIABILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'PM-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/PMBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'PM-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/PMBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/pmbillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },
            {label: 'Masters', items : [
                {label: 'Newspapers', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'PM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/newspaper')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Editions', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'PM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Mediatable')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Print RO T & C', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'PM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDC')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                ]}]
        },
        {
            label: 'Printing',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/FLYTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/FLYBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FLY-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/FLYBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FLY-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/FLYBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/flybillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },
            {label: 'Masters', items : [
                {label: 'Print Types', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLY-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Printtype')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Fabrication', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLY-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Fabrication')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Lamination', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLY-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Lamination')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Paper Types', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLY-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Papertype')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Printing RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLY-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCFL')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'Outdoor',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/OOTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/OOBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'OO-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/OOBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'OO-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/OOBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/oobillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },
            {label: 'Masters', items : [
                {label: 'Cities', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'OO-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/cities')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Media Types', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'OO-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Mediatype')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Lit Types', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'OO-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Littype')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Flex Material', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'OO-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Flexmaterial')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'OO RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'OO-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCOO')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'Flex',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/FLEXTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/FLEXBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FLX-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/FLXBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FLX-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/FLXBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/flxbillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
            },
            {label: 'Masters', items : [
                {label: 'Flex RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FLX-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCFLEX')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'FM Radio',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/FMTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/FMBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FM-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/FMBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'FM-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/FMBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
            {label: 'RO - Bill Pending', command:(e)=>{
                // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                // if (props.isAdmin || i) {
                    history.push('/fmbillpending')
                // } else {
                //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                // }
            }}]
            },
            {label: 'Masters', items : [
                {label: 'FM Stations', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/fmstation')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'FM Main', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Fmmain')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Time Bands', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/timeband')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Spot/Jimgle', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/spot')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'FM RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCFM')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'Electronic Media - TV',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/TVTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/TVBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'TV-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/TVBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'TV-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/TVBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/tvbillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },
                {label: 'Masters', items : [
                {label: 'TV Main', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'TV-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/Tvmain')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'TV Time Bands', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'TV-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/tvtimeband')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Property', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'TV-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/tvproperty')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                // {label: 'FCT Property', command:(e)=>{
                //     const i = props.permissions.find(x => (x.name === 'TV-MASTER'))
                //     if (props.isAdmin || i) {
                //         history.push('/fctproperty')
                //     } else {
                //         toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                //     }
                // }},
                {label: 'TV RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'TV-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCTV')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'Branding',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/BRTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/BRBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'BR-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/BRBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'BR-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/BRBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO Report', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/brroreport')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }
                },
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/brbillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },

                {label: 'Masters', items : [
                {label: 'Branding RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'BR-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCBR')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },        
        {
            label: 'Sponsorship',
            items: [{label: 'Release Order',command:(e)=>{ history.push('/SPTABLE')}},
            {label: 'Bills',command:(e)=>{ history.push('/SPBILL')}},
            {label: 'Multiple Bills Approval',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'SP-BILLAPPROVE'))
                if (props.isAdmin || i) {
                    history.push('/SPBILLApprove')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Approval', life: 3000 }); 
                }
            }},
            {label: 'Bills Dispatch',command:(e)=>{ 
                const i = props.permissions.find(x => (x.name === 'SP-BILLDISPATCH'))
                if (props.isAdmin || i) {
                    history.push('/SPBILLDispatch')
                } else {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Bills Dispatch', life: 3000 }); 
                }
            }},
            {label: 'Reports', items: [
                {label: 'RO - Bill Pending', command:(e)=>{
                    // const i = props.permissions.find(x => (x.name === 'FM-MASTER'))
                    // if (props.isAdmin || i) {
                        history.push('/spbillpending')
                    // } else {
                    //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    // }
                }}]
                },
                {label: 'Masters', items : [
                {label: 'Sponsorship RO T & C', command:(e)=>{
                    const i = props.permissions.find(x => (x.name === 'SP-MASTER'))
                    if (props.isAdmin || i) {
                        history.push('/TANDCSP')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}]}]
        },
        {
            label: 'Master Data',
            items: [
                {label: 'Agencies', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'MEDIA-MASTERS'))
                    if (props.isAdmin || i) {
                        history.push('/Vtable')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},

                {label: 'Streams', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'MEDIA-MASTERS'))
                    if (props.isAdmin || i) {
                        history.push('/stream')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Division', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'MEDIA-MASTERS'))
                    if (props.isAdmin || i) {
                        history.push('/division')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Session', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'MEDIA-MASTERS'))
                    if (props.isAdmin || i) {
                        history.push('/session')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }},
                {label: 'Captions', command:(e)=>{ 
                    const i = props.permissions.find(x => (x.name === 'MEDIA-MASTERS'))
                    if (props.isAdmin || i) {
                        history.push('/caption')
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised For Masters Entry', life: 3000 }); 
                    }
                }}
            ]
        }
    ];
    return (
        <>
        <Toast ref={toast} />
        <Menubar  tabIndex="0" style={{height:"40px", backgroundColor:"#B1DCED"}}  model={items} /> 
        </>
    );
}

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin,
        storeid:state.auth.storeid
    }
  }
  
  export default connect(mapStateToProps)(Navbar);