import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ToggleButton } from 'primereact/togglebutton';
import { InputTextarea } from 'primereact/inputtextarea';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'

const Prodetailverify = (props) => {
    let emptyItem = {
        edition:'',
        editioncaption:props.selcaption,
        onhold:false,
        dop:new Date(new Date(props.pdate).setDate(new Date(props.pdate).getDate() + 1)).toString(),
        position:'',
        positionother:'',
        verified:false,
        vremark:'',
        ecvremark:'',
        actualeditioncaption:props.selcaption,
        actualdop:new Date(new Date(props.pdate).setDate(new Date(props.pdate).getDate() + 1)).toString(),
        actualposition:'',
        actualpositionother:'',
        ecverified:false
    };
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const positionlist = ['Jacket-Front','Jacket P1,P2','Jacket-Inside','Back Page','Front Page','Second Page','Third Page','Fifth Page','Other']
  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();
        if (!Item.edition) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Edition Selected ?'});
            return;
        }
        if (!Item.position) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Position Selected ?'});
            return;
        }
        if (Item.edition.edition.trim()) {
                let _Items = [...Items];
                let _Item = {...Item};
                const index = findIndexByName(Item._id);
                if (index >= 0) 
                {    _Items[index] = _Item;
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Updated', life: 3000 });
                }
                else {
                    _Items.push(_Item);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Created', life: 3000 });
                }
                setItemDialog(false);            
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        const i = props.permissions.find(x => (x.name === 'PM-PHY'))
        if (props.isAdmin || i) {
            setItemDialog(true);
        } else 
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Verify', life: 3000 }); 
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
    
        _Item[`${name}`] = val;
        setItem(_Item);
    };

    const onCaptionChange = (e) => {
        const _Item = {...Item};
        _Item.actualeditioncaption = e.value;
        setItem(_Item);
      };
      
    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={rowData.onhold} icon="pi pi-check" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            </>
        );
    };

    const formatDate = (dt) => {
        let cdt = new Date(dt);
        let month = cdt.getMonth() + 1;
        let day = cdt.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        return (dt)?day+"-"+month+"-"+cdt.getFullYear():'' ;
      };

      
      const onStatusValueChange = (productKey, props, e) => {
        let updatedItems = [...props.value];
        if (e.value){
            updatedItems[props.rowIndex][props.field] = true;
          }
          else {
            updatedItems[props.rowIndex][props.field] = false;
          }
          setItem(updatedItems);
      }
      const onStatusChange = (e, name) => {
        let updatedItems = {...Item};
        if (e.value){
            updatedItems[`${name}`] = true;
          }
          else {
            updatedItems[`${name}`] = false;
          }
          setItem(updatedItems);
      }

      const statusEditor = (productKey,props1) => {
        const i = props.permissions.find(x => (x.name === 'PM-EVERIFY'))
        if (props.isAdmin || i) {
            return (
                <Checkbox disabled={props1.rowData.onhold} className="ml-4" inputId="binary" checked={props1.rowData['ecverified']} value={props1.rowData['ecverified']} onChange={(e) => onStatusValueChange(productKey,props1,e)} />
              );
          } else 
            toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to E-Verify', life: 3000 }); 
    }

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.dop);
    };
    const dateBodyTemplate1 = (rowData) => {
        return formatDate(rowData.actualdop);
    };

    const statusBodyTemplate = (rowData) => {
        return rowData.onhold?<span style={{color:'red'}}>{'On Hold'}</span>:<span style={{color:'green'}}>{'Release'}</span>;
    }
    const vstatusBodyTemplate = (rowData) => {
        return rowData.verified?<span style={{color:'green'}}>{'Verified'}</span>:<span style={{color:'orange'}}>{'Not Verified'}</span>;
    }
    const ecvstatusBodyTemplate = (rowData) => {
        return rowData.ecverified?<span style={{color:'green'}}>{'Verified'}</span>:<span style={{color:'orange'}}>{'Not Verified'}</span>;
    }

    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1"  right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} style={{fontSize:'13px'}}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column field="edition.edition" header="Edition" sortable style={{ width:'12%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="editioncaption" header="Caption" style={{width:'12%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="position" header="Position" style={{width:'8%',mpadding:'0 2px 0 2px'}}></Column>
                    <Column field="dop" header="Publ Date" body={dateBodyTemplate}  style={{width:'7%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="onhold" body={statusBodyTemplate} header="On Hold"  sortable style={{width:'7%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="actualeditioncaption" header="Caption" style={{width:'12%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="actualposition" header="Position" style={{width:'8%',mpadding:'0 2px 0 2px'}}></Column>
                    <Column field="actualdop" header="Publ Date" body={dateBodyTemplate1}  style={{width:'7%', padding:'0 2px 0 2px'}}></Column>
                    <Column field="verified" body={vstatusBodyTemplate} header="Verified" sortable style={{width:'7%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="ecverified" body={ecvstatusBodyTemplate} header="E-Copy Verified" editor={(props) => statusEditor('ecverified',props)} sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{ padding:'0 2px 0 2px'}}></Column>
                </DataTable>
            </div>

            
            <Dialog visible={ItemDialog} style={{ width: '600px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
               <form onSubmit={saveItem} onReset={hideDialog}> 
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="edition" className="w-2 mr-2 mb-0">Edition</label>
                    <Dropdown disabled placeholder="Select Edition" className="w-auto" id="edition" optionLabel="edition" options={props.editions} value={Item.edition}  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Caption</label>
                    <Dropdown disabled placeholder="Select Caption"s tyle={{width:'300px'}} id="caption"  options={props.captions} value={Item.editioncaption}  />
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Publ Date</label>
                    <div className="">
                        <Calendar disabled className="w-auto"  monthNavigator yearNavigator yearRange="2020:2030"  id="pDate" name="dop" value={new Date(Item.dop)} onChange={(e) => onInputChange(e, 'dop')} mask="99/99/9999" dateFormat="dd/mm/yy"  autoFocus/>
                    </div>
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Position</label>
                    <Dropdown disabled placeholder="Select Position" className="w-auto" id="position"   options={positionlist} value={Item.position} />
                    {Item.position === 'Other'?<InputText disabled className="w-auto" id="positiono" value={Item.positionother} />:null}
                </div>
                <div className="field grid mt-4 mb-1 ml-4 m-auto">
                    <label htmlFor="verify" className="w-4 mr-2 mb-0">E-Copy Verified</label>
                    <ToggleButton style={{width:'100px'}} checked={Item.ecverified} onChange={(e) => onStatusChange(e, 'ecverified')} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />                
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label className="w-3 mr-2 mb-0" htmlFor="oterms">Remarks</label>
                    <InputTextarea  rows={2} autoComplete="off" className={'w-full'} id="remarks" value={Item.vremark} onChange={(e) => onInputChange(e, 'ecvremark')} />
                </div>
               <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="verify" className="w-4 mr-2 mb-0">Physically Verified</label>
                    <ToggleButton style={{width:'100px'}} checked={Item.verified} onChange={(e) => onStatusChange(e, 'verified')} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />                
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label className="w-3 mr-2 mb-0" htmlFor="oterms">Remarks</label>
                    <InputTextarea  rows={2} autoComplete="off" className={'w-full'} id="remarks" value={Item.vremark} onChange={(e) => onInputChange(e, 'vremark')} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-3 mr-2 mb-0">Actual Caption</label>
                    <Dropdown filter filterBy='name' placeholder="Select Actual Caption"style={{ width:'300px'}} id="caption"  options={props.captions} value={Item.actualeditioncaption} onChange={onCaptionChange} />
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-3 mr-2 mb-0">Actual Publ Date</label>
                    <div className="">
                        <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2020:2030"  id="pDate" name="dop" value={new Date(Item.actualdop)} onChange={(e) => onInputChange(e, 'actualdop')} mask="99/99/9999" dateFormat="dd/mm/yy" />
                    </div>
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-3 mr-2 mb-0">Actual Position</label>
                    <Dropdown  filter filterBy='position' placeholder="Select Actual Position"className="w-auto" id="position"   options={positionlist} value={Item.actualposition} onChange={(e) => onInputChange(e,'actualposition')} />
                    {Item.actualposition === 'Other'?<InputText  className="w-auto" id="positiono" value={Item.actualpositionother} onChange={(e) => onInputChange(e,'actualpositionother')} />:null}
                </div>

                <div className="formgrid grid mt-5">
                    <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    <div className="field col">
                        <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        unitgstin:state.auth.unitgstin,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Prodetailverify);


