import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToggleButton } from 'primereact/togglebutton';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { InputNumber } from 'primereact/inputnumber';

const Oorodetailverify = (props) => {
    let emptyItem = {
        displaycity:'',
        ooarea:'',
        oolocation:'',
        mediatype:'',
        littype:'',
        unit:'',
        width:'',
        height:'',
        tsize:'',
        stdate:new Date().toString(),
        enddate:new Date().toString(),
        period:'',
        frequency:0,
        qty:0,
        rate:0,
        amt:0,
        flexmaterial:null,
        flexprintrate:0,
        flexamt:0,
        flexmountingcharges:0,
        totalamt:0,
        bno:0
    };
    const sizeunits = ['Sq.Ft', 'Sq.M', 'Sq.In'];
    const [actionType, setActionType] = useState('save');
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };


    const saveItem =  (event) => {
        event.preventDefault();

        if (!Item.oolocation) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Location Defined ?'});
            return;
        }
        if (Item.oolocation.trim()) {
            if (actionType === 'copy') {
                let _Items = [...Items];
                 props.fmsubs.map( e => {
                    const index = findIndexByName(e._id);
                    if (index < 0) 
                    {
                        let _Item = {...Item};
                        _Item.fmsub = e;
                        _Items.push(_Item);
                    }                   
                    return 1;
                })
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
                setItemDialog(false);            
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OO Detail Created', life: 3000 });
            }
            else {
                let _Items = [...Items];
                let _Item = {...Item};
                if (!_Item.vperson.trim()) {
                    setItemDialog(false);            
                    setItem(emptyItem);
                    return;
                }
                _Item.verified = true;
                const index = findIndexByName(Item._id);
                if (index >= 0) 
                {    _Items[index] = _Item;
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OO Detail Updated', life: 3000 });
                }
                else {
                    _Items.push(_Item);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'OO Detail Created', life: 3000 });
                }
                setItemDialog(false);            
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
            }
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        const item = {...Item};
        if (!item.verified) {
            item.actualsdate = item.stdate;
            item.actualedate = item.enddate;
        }
        setItem(item);
        setItemDialog(true);
    };


    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item[`${name}`] = val;
        setItem(_Item);
    };
    const onInputDateChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newooro = {...Item};
        newooro[`${name}`] = val;
        newooro.period = ((Math.ceil((new Date(newooro.enddate).getTime() - new Date(newooro.stdate).getTime()) / (1000*60*60*24))+1)/30);
        setItem(newooro);
      }
    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText  type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button  icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            </>
        );
    };
    const tamtBodyTemplate = (rowData) => {
        return rowData.totalamt.toFixed(2);
    };
    const vstatusBodyTemplate = (rowData) => {
        return rowData.verified?<span style={{color:'green',fontWeight:'bold'}}>{'Verified'}</span>:<span style={{color:'orange',fontWeight:'bold'}}>{'Not Verified'}</span>;
    }
    const onStatusChange = (e, name) => {
        let updatedItems = {...Item};
        if (e.value){
            updatedItems[`${name}`] = true;
          }
          else {
            updatedItems[`${name}`] = false;
          }
          setItem(updatedItems);
      }


    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1" right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="displaycity.name" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column field="displaycity.name" header="City" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="ooarea" header="Area" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="oolocation" header="Location" style={{width:'18%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="mediatype.name" header="Media" sortable estyle={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="littype.name" header="Lit Type"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="tsize" header="Size" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="period" header="Period" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="totalamt"  header="Total Amount"  body={tamtBodyTemplate} style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="verified"  header="Verified"  body={vstatusBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '600px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveItem} onReset={hideDialog}> 
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto ">
                            <label  className=" mr-2 mb-0">Display City</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Display City" style={{width:'100%'}} optionLabel="name" options={props.dc} value={Item.displaycity}  />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label htmlFor="area" className=" mr-2 mb-0">Area</label>
                            <InputText  className="" value={Item.ooarea}  />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label htmlFor="location" className="mr-2 mb-0">Location</label>
                            <InputText  className="" value={Item.oolocation}  />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Media Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Media Type"style={{width:'50%'}} optionLabel="name" options={props.mediatypes} value={Item.mediatype}  />
                        </div>
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Lit Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Lit Type"style={{width:'50%'}} optionLabel="name" options={props.littypes} value={Item.littype}  />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0" >Select Unit</label>
                            <Dropdown placeholder="Unit" style={{width:'50%'}} options={sizeunits} value={Item.unit}  />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0" >Height</label>
                            <InputNumber style={{width:'26.5%'}} prefix="H:" mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.height} />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0"  >Width</label>
                            <InputNumber  style={{width:'26.5%'}} prefix="W:"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.width} />
                        </div>
                        <div className='field grid mb-1  m-auto'>
                            <label className="w-3 mr-2 mb-0"  >Total Size</label>
                            <InputNumber  style={{width:'26.5%'}}  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={Item.tsize} readOnly/>
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1 m-auto">
                            <label className="w-3 mr-2 mb-0">Qty/Side</label>
                            <InputNumber style={{width:'26.5%'}}locale="en-IN"  value={Item.qty}  />
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1  m-auto">
                            <label  className="w-3 mr-2 mb-0" >Start Date</label>
                            <Calendar monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.stdate)} onChange={(e) => onInputDateChange(e, 'stdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
                        </div>
                        <div  className="field grid mb-1  m-auto">
                            <label className="w-3 mr-2 mb-0">End Date</label>
                            <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.enddate)} onChange={(e) => onInputDateChange(e, 'enddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
                        </div>
                        <div  className="field grid mb-1  m-auto">
                            <label className="w-3 mr-2 mb-0" >Period</label>
                            <InputNumber  style={{width:'26.5%'}} minFractionDigits={6} maxFractionDigits={6}  value={Item.period}/>
                        </div>
                    </div>
                    <div className=" mt-1">
                        <div  className="field grid mb-1  m-auto">
                            <label className="w-3 mr-2 mb-0">Flex Material Type</label>
                            <Dropdown  filter filterBy='name' placeholder="Select Flex Mat Type"style={{width:'50%'}} optionLabel="name" options={props.flexmaterials} value={Item.flexmaterial} />
                        </div>
                    </div>
                    <div  className="field grid mb-1  m-auto">
                        <label className="w-3 mr-2 mb-0">Verified On</label>
                        <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.vdate)} onChange={(e) => onInputDateChange(e, 'vdate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                    </div>
                    <div  className="field grid mb-1  m-auto">
                        <label className="w-3 mr-2 mb-0">Verified By</label>
                        <InputText style={{width:'300px'}}className=""  htmlFor="fmrono"  value={Item.vperson} onChange={(e) => onInputChange(e, 'vperson')}/>
                    </div>
                    <div  className="field grid mb-1  m-auto">
                        <label className="w-3 mr-2 mb-0">Remarks</label>
                        <InputTextarea style={{width:'350px'}} rows={4} autoComplete="off" className='' id="remarks" value={Item.vremark} onChange={(e) => onInputChange(e, 'vremark')} />
                    </div>
                    <div className="field grid mb-1  m-auto">
                        <label htmlFor="verify" className="w-3 mr-2 mb-0">Is Cancelled</label>
                        <ToggleButton style={{width:'100px'}} checked={Item.vcancel} onChange={(e) => onStatusChange(e, 'vcancel')} onIcon="pi pi-check" offIcon="pi pi-times" aria-label="Confirmation" />                
                    </div>

                    <div className=" mt-1">
                        <div  className="field grid mb-1  m-auto">
                            <label  className="w-3 mr-2 mb-0" >Actual Start Date</label>
                            <Calendar monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.actualsdate)} onChange={(e) => onInputDateChange(e, 'actualsdate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                        </div>
                        <div  className="field grid mb-1  m-auto">
                            <label className="w-3 mr-2 mb-0">Actual End Date</label>
                            <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.actualedate)} onChange={(e) => onInputDateChange(e, 'actualedate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                        </div>
                    </div>
                    <div className="formgrid grid mt-5">
                        <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    <div className="field col">
                        <Button onClick={e => {
                            setActionType('save');
                        
                        }} type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>

        </div>
    );
}

export default Oorodetailverify;


