import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './tvmainmodule.css'


import { useHistory } from "react-router-dom";

const Tvmaintable = (props) => {

  const history = useHistory()
  const [Items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  useEffect(() => {
    let authOptions = {
        method: 'GET',
        url: '/tvmain/all',
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
    };
    axios(authOptions)
    .then(response => {
        setItems(response.data)
    })
    .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setItems([]);
    });
},[]); // eslint-disable-line react-hooks/exhaustive-deps

const editItem = (Item) => {
    history.push({
      pathname: '/Tvmainentry',
      state: { mode:'Edit', tvmaindata:Item }
    });
  };

  const openNew = () => {
    history.push({
      pathname: '/Tvmainentry',
      state: { mode:'New', tvmaindata:null }
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <Button label="New" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
    );
};

  const rightToolbarTemplate = () => {
      return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      )
  }

  const actionBodyTemplate = (rowData) => 
    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-primary mr-2 " onClick={() => editItem(rowData)} />;
  
  return (
    <div className="AR">
      <div className="Headerar">
        <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
        <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>TV Channels Management</h2>
        <div className="Userar">
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.tvmainname}</h4>
        </div>
      </div>
      <div className="TCar">
        <Toast ref={toast} />
        <div className="card h-auto">
          <Toolbar className="p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
            <DataTable  sortField="name" sortOrder={-1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Items} selection={selectedItem} onSelectionChange={(e) => setSelectedItem(e.value)}
                dataKey="name" paginator rows={20} rowsPerPageOptions={[20, 25, 30]} style={{padding:'0px',fontSize:'14px'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                globalFilter={globalFilter} scrollable scrollHeight="320px" >
                <Column field="name" header="Main Channel Name" sortable style={{ width:'75%',padding:'0 2px 0 2px'}}></Column>
                <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
            </DataTable>
            <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
              <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
            </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin,
      storeid:state.auth.storeid
  }
}

export default connect(mapStateToProps)(Tvmaintable);