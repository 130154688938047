
import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-allen';
import logo from "../../assets/allen.png";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import styles from '../caption/caption.module.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { useHistory } from "react-router-dom";

const TandCsp = (props) => {
    const history = useHistory();
    const toast = useRef(null);
    const [tandcsp, settandcsp] = useState({tandc:''});

    useEffect(() => {
        let authOptions = {
            method: 'GET',
            url: '/tandcsp/',
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
        .then(response => {
            settandcsp(response.data);
        })
        .catch(error => {
            toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            settandcsp('');
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const onInputChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newtc = {...tandcsp};
        newtc[`${name}`] = val;
        settandcsp(newtc);
      }
    
    const saveItem =  (event) => {
        event.preventDefault();
        let authOptions = {
            method: 'PUT',
            url: '/tandcsp/', 
            data: tandcsp,
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
            .then(response => {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'T & C  Saved', life: 3000 });
            history.goBack()
        })
            .catch(error => {
            toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    return (
        <div className={styles.PER}>
            <Toast ref={toast} />
            <div className={styles.Headerper}>
                <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
                <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>Printing RO - Terms & Conditions</h2>
                <div className={styles.Userper}>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
                </div>
            </div>
            <div className="flex  justify-content-center" style={{ margin:'auto',top:'-20vh',position: 'relative'}}>
                <form  onSubmit={saveItem} onReset={()=>history.goBack()}> 
                    <div  className="m-auto">
                        <InputTextarea rows={20} cols={100}  style={{ height: '420px', width:'700px' }} value={tandcsp.tandc} onChange={(e) => onInputChange(e, 'tandc')} />
                    </div>
                    <div style={ {width:'700px' }} className="flex justify-content-center mt-5">
                        <div className="">
                            <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                        </div>
                        <div className="">
                            <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      storeid:state.auth.storeid,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin
  }
}

export default connect(mapStateToProps)(TandCsp);