/* export const SET_BATCHES = 'SET_BATCHES';
export const FETCH_BATCHES_FAILED = 'FETCH_BATCHES_FAILED';
export const FETCH_BATCHES_START = 'FETCH_ORDERS_START';
export const INITIALIZE_BATCH = 'INITIALIZE_BATCH'

export const SAVE_BATCH_SUCCESS = 'SAVE_BATCH_SUCCESS';
export const SUBMIT_BATCH_SUCCESS = 'SUBMIT_BATCH_SUCCESS';

export const AUTH_BATCH_SUCCESS = 'AUTH_BATCH_SUCCESS';
export const AUTH_BATCH_FAILED = 'AUTH_BATCH_FAILED';
export const AUTH_BATCH_START = 'AUTH_BATCH_START';


export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';

export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';

export const PURCHASE_BURGER_SUCCESS = 'PURCHASE_BURGER_SUCCESS';
export const PURCHASE_BURGER_FAIL = 'PURCHASE_BURGER_FAIL';
export const PURCHASE_BURGER_START = 'PURCHASE_BURGER_START'; 
export const PURCHASE_INIT = 'PURCHASE_INIT';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';
*/
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'; 
//export const SET_TREE = 'SET_TREE';