import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import Fmsub from "./fmsubs";
import Costalloc from "../../printmedia/media/costalloc";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import axios from '../../../axios-allen';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './fmmainmodule.css'
import { Dropdown } from 'primereact/dropdown';

const Fmmainentry = (props) => {
  let fmmaininit = {
    name : '',
    fmstation:'',
    fmsubs:[],
    centres:[]
  };
  const history = useHistory();
  const toast = useRef(null);
  const [fmmain,setfmmain] = useState(fmmaininit);
  const [saved,setSaved] = useState(false);
  const [fmstations, setFmstations] = useState([]);
  const [selfmstation, setselFmstation] = useState(null);


  
  useEffect(() => {
    axios.get('/fmstation/all', {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        const fmstation = response.data;
        setFmstations(fmstation);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
      });
  },[]); 
  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      setfmmain(history.location.state.fmmaindata);
      setselFmstation(history.location.state.fmmaindata.fmstation);
    }
  },[history.location.state.mode,history.location.state.fmmaindata]);

  const onFmstationChange = (e) => {
    e.preventDefault();
    let newfmmain = {...fmmain}
      newfmmain.fmstation = e.target.value;
      setfmmain(newfmmain);
    setselFmstation(e.target.value);
  }
  
  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newfmmain = {...fmmain}
    newfmmain[`${name}`] = val;
    setfmmain(newfmmain);
  }

  const saveFmsub = (i) => {
    let newfmmain = {...fmmain}
      newfmmain.fmsubs = i;
    setfmmain(newfmmain);
  }

  const saveCentre = (i) => {
    let newfmmain = {...fmmain}
      newfmmain.centres = i;
    setfmmain(newfmmain);
  }

  const fmmainSave = (event) => {
    event.preventDefault();
    if (fmmain.centres.map(item => item.alloc).reduce((acc,curr)=>acc+curr) !== 100 ) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please allocate 100%', life: 3000 });
      return;
    }
    if (fmmain.name==='') {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Name', life: 3000 });
      return;
    };
    if (!fmmain.fmstation) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the FM Station', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      let authOptions = {
        method: 'POST',
        url: '/fmmain/', 
        data: fmmain,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'FM Main Created', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      let authOptions = {
        method: 'PUT',
        url: '/fmmain/', 
        data: fmmain,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'FM Main Detail Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

// Delete
/*  const prDelete = (event) => {
    event.preventDefault();
    if (fmmain.finalstatus === "Draft") {
      axios.delete('/requisition/'+fmmain.prno, {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'pr Deleted', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    }
  }
  

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const prCancel = (event) => {
    event.preventDefault();
    prApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={prCancel} />
    </>
  );
*/
  if (saved)  history.goBack();

  let Fmsubcomponent=null;
  let Costcomponent=null;
  Fmsubcomponent = (<Fmsub  mode={history.location.state.mode} Fmsub={fmmain.fmsubs} SaveFmsubs={saveFmsub}/>)
  Costcomponent = (<Costalloc  mode={history.location.state.mode} Centre={fmmain.centres} SaveCentres={saveCentre}/>)


  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if  (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={fmmainSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (history.location.state.mode === 'Edit' ) {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={fmmainSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  return (
    <div >
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">FM Station - Main Station Form</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>
      <div style={{width:'67%',margin:'auto'}} className="h-auto pb-2 surface-200 px-4">
        <div style={{}} className="formgrid grid">
        <label className="mb-0 mx-2" style={{fontSize:'1.3em'}} htmlFor="fmstation">FM Station</label>
              <Dropdown filter filterBy='name' style={{width:'98%'}} id='fmstation' autoFocus  value={selfmstation} className="p-0 ml-2" options={fmstations}  onChange={(e) => onFmstationChange(e)} optionLabel="name" placeholder="Select Fmstation" />
          <label htmlFor="fmmainname" className="mt-1 p-0">Main Station Name*</label>
          <InputText className="w-full" id="fmmainname"  autoComplete="off" value={fmmain.name} onChange={(e) => onInputChange(e, 'name')} />
        </div>
      </div>
      <div className='w-8 m-auto'>
        {Fmsubcomponent}
        {Costcomponent}
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
            {BtnOptions}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitame:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Fmmainentry);