import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { InputNumber } from 'primereact/inputnumber';

const Fmrodetailverify = (props) => {
    let emptyItem = {
        fmsub:'',
        spot:'',
        spotduration:'',
        timeband:'',
        ssdt:props.sdt,
        sedt:props.edt,
        sdays:props.days,
        frequency:0,
        fct:0,
        paid:0,
        totalsec:0,
        rate:0,
        amt:0,
        verified:false,
        vperson:'',
        vremark:'',
        vdate:new Date(new Date(props.pdate).setDate(new Date(props.pdate).getDate() + 1)).toString(),
        bno:0
    };
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);


    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();
        if (!Item.fmsub) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Fmsub Selected ?'});
            return;
        }
        if (Item.fmsub.fmsub.trim()) {

            let _Items = [...Items];
            let _Item = {...Item};
            if (!_Item.vperson.trim()) {
                setItemDialog(false);            
                setItem(emptyItem);
                return;
            }
            _Item.verified = true;
            const index = findIndexByName(Item._id);
            if (index >= 0) 
            {    _Items[index] = _Item;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Fmsub Updated', life: 3000 });
            }
            else {
                _Items.push(_Item);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Fmsub Created', life: 3000 });
            }
            setItemDialog(false);            
            setItems(_Items); 
            props.SaveItems(_Items);
            setItem(emptyItem);
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        setItemDialog(true);
    };


    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputDateChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newfmro = {...Item};
        newfmro[`${name}`] = val;
        setItem(newfmro);
      }

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button  icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            </>
        );
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item[`${name}`] = val;
        setItem(_Item);
    };
    const vstatusBodyTemplate = (rowData) => {
        return rowData.verified?<span style={{color:'green',fontWeight:'bold'}}>{'Verified'}</span>:<span style={{color:'orange',fontWeight:'bold'}}>{'Not Verified'}</span>;
    }

    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1"  right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column field="fmsub.fmsub" header="FM Sub" sortable style={{width:'18%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="spot" header="Spot" sortable style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="spotduration" header="Duration" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="timeband.name" header="Time Band" sortable estyle={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="frequency" header="Frequency"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="fct" header="FCT" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="paid" header="Paid" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="totalsec" header="Total" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="rate" header="Rate"   style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="amt"  header="Amount"   style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="verified"  header="Verified"  body={vstatusBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                   <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '700px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
               <form onSubmit={saveItem} onReset={hideDialog}> 
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-2 mr-2 mb-0">FM Sub Stn</label>
                    <Dropdown filter filterBy='fmsub' placeholder="Select Fmsub"className="w-auto" optionLabel="fmsub" options={props.fmsubs} value={Item.fmsub}  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-2 mr-2 mb-0">Spot</label>
                    <InputText style={{width:'300px'}}className=""  htmlFor="fmrono"  value={Item.spot} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Spot Duration</label>
                    <InputNumber locale="en-IN"  className="w-auto"  value={Item.spotduration}  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Time Band</label>
                    <Dropdown  filter filterBy='name' placeholder="Select Time Band"style={{width:'300px'}} optionLabel="name" options={props.timebands} value={Item.timeband}  />
                </div>
                <div className="flex justify-content-center mt-1">
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-8 mr-2 mb-0" >Start Date</label>
                    <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.ssdt)}  mask="99/99/9999" dateFormat="dd/mm/yy"  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-8 mr-2 mb-0">End Date</label>
                    <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.sedt)} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-8 mr-2 mb-0" >No of Days</label>
                    <InputNumber style={{}}   value={Item.sdays}/>
                </div>
                </div>

                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Frequency</label>
                    <InputNumber locale="en-IN" className="w-auto"  value={Item.frequency}  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Verified On</label>
                    <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.vdate)} onChange={(e) => onInputDateChange(e, 'vdate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Verified By</label>
                    <InputText style={{width:'300px'}}className=""  htmlFor="fmrono"  value={Item.vperson} onChange={(e) => onInputChange(e, 'vperson')}/>
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Remarks</label>
                    <InputTextarea style={{width:'500px'}} rows={4} autoComplete="off" className='' id="remarks" value={Item.vremark} onChange={(e) => onInputChange(e, 'vremark')} />
                </div>
                <div className="formgrid grid mt-5">
                    <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    <div className="field col">
                        <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
                </div>

               </form>
            </Dialog>
        </div>
    );
}

export default Fmrodetailverify;