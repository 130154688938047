import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'

const Prodetail = (props) => {
    let emptyItem = {
        edition:'',
        editioncaption:props.selcaption,
        onhold:false,
        dop:new Date(new Date(props.pdate).setDate(new Date(props.pdate).getDate() + 1)).toString(),
        position:'',
        positionother:'',
        actualeditioncaption:props.selcaption,
        actualdop:new Date(new Date(props.pdate).setDate(new Date(props.pdate).getDate() + 1)).toString(),
        actualposition:'',
        actualpositionother:'',
    };
    const [actionType, setActionType] = useState('save');
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [id, setId] = useState(1);

    const positionlist = ['Jacket-Front','Jacket P1,P2','Jacket-Inside','Back Page','Front Page','Second Page','Third Page','Fifth Page','Other']
  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const openNew = () => {
        if (props.editions.length === 0) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Newspaper Selected ?'});
            return;
        }
        setItem(emptyItem);
        setItemDialog(true);
    };

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();
        if (!Item.edition) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Edition Selected ?'});
            return;
        }
        if (!Item.position) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Position Selected ?'});
            return;
        }
        if (Item.edition.edition.trim()) {
            if (actionType === 'copy') {
                let _Items = [...Items];
                let currid = id;
                 props.editions.map( e => {
                    const index = findIndexByName(e._id);
                    if (index < 0) 
                    {
                        let _Item = {...Item};
                        _Item._id = currid;
                        currid = currid + 1;
                        _Item.edition = e;
                        _Items.push(_Item);
                    }                   
                    return 1;
                })
                setId(currid);
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
                setItemDialog(false);            
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Created', life: 3000 });
            }
            else {
                let _Items = [...Items];
                let _Item = {...Item};
                if (_Item._id) {
                    const index = findIndexByName(Item._id);
                    if (index >= 0) 
                    {    _Items[index] = _Item;
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Print media RO Detail Updated', life: 3000 });
                    }
                    else {
                        _Items.push(_Item);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Print media RO Detail Created', life: 3000 });
                    }
                } else {
                    _Item._id = id;
                    setId(_Item._id+1)
                    _Items.push(_Item);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Print media RO Detail Created', life: 3000 });
                }
                setItemDialog(false);            
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
            }
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        setItemDialog(true);
    };

    const confirmDeleteItem = (e,Item) => {
        e.preventDefault();
        setItem(Item);
        setDeleteItemDialog(true);
    };

    const deleteItem = () => {
        let _Items = Items.filter(val => val._id !== Item._id);
        setItems(_Items);
        setDeleteItemDialog(false);
        setItem(emptyItem);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Edition Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };

    const deleteSelectedItems = () => {
        let _Items = Items.filter(val => !selectedItems.includes(val));
        setItems(_Items);
        setDeleteItemsDialog(false);
        setSelectedItems(null);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Editions Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
    
        _Item[`${name}`] = val;
        setItem(_Item);
    };
    const onholdChange  = (e) => {
        e.preventDefault();
        let _Item = {...Item};
        if (e.checked){
          _Item.onhold = true;
          _Item.dop='';
        }
        else {
            _Item.onhold = false;
        }
        setItem(_Item);
      };
      

    const onEditionChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.edition = val;
        setItem(_Item);
      };
      
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disabled} label="New" icon="pi pi-plus" className="p-button-text p-button-success mr-2" onClick={openNew} />
            <Button  label="Delete" icon="pi pi-trash" className="p-button-danger p-button-text" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length || props.disabled} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disabled} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            <Button disabled={props.disabled} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={(e) => confirmDeleteItem(e,rowData)} />
            </>
        );
    };

    const deleteItemDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteItem} />
        </>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItems} />
        </>
    );

    const formatDate = (dt) => {
        let cdt = new Date(dt);
        let month = cdt.getMonth() + 1;
        let day = cdt.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        return (dt)?day+"-"+month+"-"+cdt.getFullYear():'' ;
      };

      const onStatusValueChange = (productKey, props, e) => {
        let updatedItems = [...props.value];
        if (e.checked){
            updatedItems[props.rowIndex][props.field] = true;
            updatedItems[props.rowIndex]['dop']='';
          }
          else {
            updatedItems[props.rowIndex][props.field] = false;
          }
          setItem(updatedItems);
      }
      const ondopChange = (productKey, props, e) => {
        let updatedItems = [...props.value];
            updatedItems[props.rowIndex][props.field] = e.value;
          setItem(updatedItems);
      }
      const onEditionCaptionChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.editioncaption = val;
        setItem(_Item);
      };
      
    const statusEditor = (productKey,props) => {
        return (
            <Checkbox className="ml-4" inputId="binary" checked={props.rowData['onhold']} value={props.rowData['onhold']} onChange={(e) => onStatusValueChange(productKey,props,e)} />
        );
    }
    const dopEditor = (productKey,props) => {
        return (
            <Calendar  className="w-6"  monthNavigator yearNavigator yearRange="2020:2030"  id="pDate" name="dop" value={new Date(props.rowData['dop'])} onChange={(e) => ondopChange(productKey,props,e)} mask="99/99/9999" dateFormat="dd/mm/yy"  autoFocus/>
        );
    }
    
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.dop);
    };
  
    const statusBodyTemplate = (rowData) => {
        return rowData.onhold?<span style={{color:'red'}}>{'On Hold'}</span>:<span style={{color:'green'}}>{'Release'}</span>;
    }

    const positionBodyTemplate = (rowData) => {
        return rowData.position === 'Other'?<span> {rowData.positionother}</span>:<span> {rowData.position}</span>;
    }
    let cpy = null;
    if (Items.length === 0)
    cpy= <div className="field col">
            <Button type="submit" onClick={e => {
                            setActionType('copy');
                        
                        }} label="Save And Copy for All Edition" icon="pi pi-check" className="p-button-text"  />
        </div>;

    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="edition.edition" header="Edition" sortable style={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="editioncaption" header="Caption" style={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="position" body={positionBodyTemplate} header="Position" style={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="dop" header="Publ Date" body={dateBodyTemplate} editor={(props) => dopEditor('dop',props)} style={{width:'15%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="onhold" body={statusBodyTemplate} header="On Hold" editor={(props) => statusEditor('onhold',props)} sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '700px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
               <form onSubmit={saveItem} onReset={hideDialog}> 
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="edition" className="w-2 mr-2 mb-0">Edition</label>
                    <Dropdown filter filterBy='edition' placeholder="Select Edition"className="w-auto" id="edition" optionLabel="edition" options={props.editions} value={Item.edition} onChange={onEditionChange} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Caption</label>
                    <Dropdown  filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={props.captions} value={Item.editioncaption} onChange={onEditionCaptionChange} />
                </div>

                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Publ Date</label>
                    <div className="">
                        <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2020:2030"  id="pDate" name="dop" value={new Date(Item.dop)} onChange={(e) => onInputChange(e, 'dop')} mask="99/99/9999" dateFormat="dd/mm/yy"  autoFocus/>
                    </div>
                    <div className="mt-2">
                        <Checkbox className="ml-4" inputId="binary" checked={Item.onhold} onChange={(e) => onholdChange(e)} />
                        <label className="ml-1"  htmlFor="binary">Put On Hold</label>
                    </div>
                </div>
                <div className="field grid mb-1 ml-4 m-auto">
                    <label htmlFor="proDate" className="w-2 mr-2 mb-0">Position</label>
                    <Dropdown  filter filterBy='position' placeholder="Select Position"className="w-auto" id="position"   options={positionlist} value={Item.position} onChange={(e) => onInputChange(e,'position')} />
                    {Item.position === 'Other'?<InputText  className="w-auto" id="positiono" value={Item.positionother} onChange={(e) => onInputChange(e,'positionother')} />:null}
                </div>

                <div className="formgrid grid mt-5">
                    <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    {cpy}
                    <div className="field col">
                        <Button onClick={e => {
                            setActionType('save');
                        
                        }} type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>

            <Dialog visible={deleteItemDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete <b>{Item.itemname}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteItemsDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete the selected Items?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default Prodetail;


