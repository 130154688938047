import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import styles from './newspaper.module.css';
import { useHistory } from "react-router-dom";
const Newspapertable = (props) => {
    const history = useHistory();
    let emptyNewspaper = {
        name: '',
    };

    const [newspaper, setNewspaper] = useState(emptyNewspaper);
    const [newspapers, setNewspapers] = useState(null);
    const [newspaperDialog, setNewspaperDialog] = useState(false);
    const [deleteNewspaperDialog, setDeleteNewspaperDialog] = useState(false);
    const [mode,setMode] = useState('');


    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        let authOptions = {
            method: 'GET',
            url: '/newspaper/all',
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
        .then(response => {
            setNewspapers(response.data)
        })
        .catch(error => {
            toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            setNewspapers([]);
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps


    const openNew = () => {
        setNewspaper(emptyNewspaper);
        setSubmitted(false);
        setMode('New');
        setNewspaperDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setNewspaperDialog(false);
    }

    const hideDeleteNewspaperDialog = () => {
        setDeleteNewspaperDialog(false);
    }

    const saveNewspaper = () => {
        setSubmitted(true);

        if (newspaper.name.trim()) {
            let _newspapers = [...newspapers];
            let _newspaper = {...newspaper};
            if (mode === 'Edit') {
                const index = findIndexById(newspaper._id);
                _newspapers[index] = _newspaper;
                let authOptions = {
                  method: 'PUT',
                  url: '/newspaper/', 
                  data: _newspaper,
                  headers : {
                      'x-auth-token' : localStorage.getItem('token')
                  }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Newspaper Edited', life: 3000 });
                    setNewspapers(_newspapers);
                    setNewspaperDialog(false);
                    setNewspaper(emptyNewspaper);
                })
                  .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
            else {
                let authOptions = {
                    method: 'POST',
                    url: '/newspaper/', 
                    data: _newspaper,
                    headers : {
                        'x-auth-token' : localStorage.getItem('token')
                    }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Newspaper Created', life: 3000 });
                     _newspapers.push(response.data);
                     setNewspapers(_newspapers);
                     setNewspaperDialog(false);
                     setNewspaper(emptyNewspaper);
                })
                    .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
        }
    }

    const editNewspaper = (newspaper) => {
        setNewspaper({...newspaper});
        setMode('Edit');
        setNewspaperDialog(true);
    }

    const confirmDeleteNewspaper = (newspaper) => {
        setNewspaper(newspaper);
        setDeleteNewspaperDialog(true);
    }

    const deleteNewspaper = () => {
        let authOptions = {
            method: 'delete',
            url: '/newspaper/', 
            data: newspaper,
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
          };
          axios(authOptions)
            .then(response => {
                setDeleteNewspaperDialog(false);
                let _newspapers = newspapers.filter(val => val.name !== newspaper.name);
                setNewspapers(_newspapers);
                setNewspaper(emptyNewspaper);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Newspaper Deleted', life: 3000 });
            })
            .catch(error => {
              toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < newspapers.length; i++) {
            if (newspapers[i]._id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _newspaper = {...newspaper};
        _newspaper[`${name}`] = val;
        setNewspaper(_newspaper);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success p-mr-2" onClick={() => editNewspaper(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-warning" onClick={() => confirmDeleteNewspaper(rowData)} />
           </React.Fragment>
        );
    }

    const header = (
        <div className={styles.tableheader}>
            <h4 className="mx-0 my-1">Manage Newspapers</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const newspaperDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveNewspaper} />
        </React.Fragment>
    );
    const deleteNewspaperDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNewspaperDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteNewspaper} />
        </React.Fragment>
    );

    return (
        <div className={styles.PER}>
            <Toast ref={toast} />
            <div className={styles.Headerper}>
                <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
                <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>Newspapers Management</h2>
                <div className={styles.Userper}>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
                </div>
            </div>
            <div className={styles.datatablecruddemo} style={{width:'60%', margin:'auto',top:'-30vh',position: 'relative'}}>
                <div className={styles.card}>
                    <Toolbar className="mb-0" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable sortField="name" sortOrder={1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={newspapers} 
                        dataKey="name" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} newspapers"
                        globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                        <Column field="name" header="Newspaper Name" sortable style={{ width:'80%',minWidth: '16rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>
                </div>
                <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
                    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
                </div>
            </div>
            <Dialog visible={newspaperDialog} style={{ width: '450px' }} header="Newspaper Details" modal className="p-fluid" footer={newspaperDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">Newspaper Name*</label>
                    <InputText id="name" value={newspaper.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !newspaper.name })} />
                    {submitted && !newspaper.name && <small className="p-error">Name is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteNewspaperDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteNewspaperDialogFooter} onHide={hideDeleteNewspaperDialog}>
                <div className={styles.confirmationcontent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {newspaper && <span>Are you sure you want to delete <b>{newspaper.name}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin,
        storeid:state.auth.storeid
    }
  }
  
  export default connect(mapStateToProps)(Newspapertable);