import * as actionTypes from './actionsTypes';
import axios from '../../axios-allen';

export const authStart = () => {
    return {
        type:actionTypes.AUTH_START
    };
};

export const authSuccess = (isfirst,token,username,desig,admin,eid,id,unitid,unitname,unitgstin,premises,role,permissions,sid,acs) => {
    return {
        type:actionTypes.AUTH_SUCCESS,
        token:token,
        username:username,
        desig:desig,
        isAdmin:admin,
        isFirst:isfirst,
        empid:eid,
        userid:id,
        unitid:unitid,
        unitname:unitname,
        unitgstin:unitgstin,
        premises:premises,
        role:role,
        permissions:permissions,
        accstores:acs,
        storeid:sid
    };
};

export const authFail = (error) => {
    return {
        
        type:actionTypes.AUTH_FAIL,
        error:error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    //localStorage.removeItem('expirationDate');
    //localStorage.removeItem('empid');
    //localStorage.removeItem('username');
    //localStorage.removeItem('userid');
    return {
        type:actionTypes.AUTH_LOGOUT
    };
};

const checkAuthTimeout = (expiresIn) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        },expiresIn * 1000)
    }
}

export const auth = (eid, pwd) => {
    return async dispatch => {
        dispatch(authStart());
        const authData = {
            empid:eid,
            password:pwd
        }
        var authOptions = {
            method: 'POST',
            url: '/auth',
            data: authData,
            json: true
        };
        axios(authOptions)
        .then(response => {
            //const expirationDate = new Date(new Date().getTime()+response.data.expiresIn*1000);
            localStorage.setItem('token',response.data.token);
            //localStorage.setItem('expirationDate',expirationDate);
            //localStorage.setItem('userId',response.data.userId);
            //localStorage.setItem('username',response.data.username);
            //localStorage.setItem('userid',response.data.userid);
            dispatch(authSuccess(response.data.isFirst,response.data.token, response.data.username, response.data.desig,response.data.isAdmin,response.data.empid,response.data.userid, response.data.unitid, response.data.unitname,response.data.gstin,response.data.premises,response.data.role,response.data.permissions,response.data.storeid,response.data.accstores))
            //dispatch(checkAuthTimeout(response.data.expiresIn))
        })
        .catch((error) => {
            dispatch(authFail(error));
        });
    };
};

export const authpwd = (eid, pwd, npwd) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            empid:eid,
            password:pwd
        }
        var authOptions = {
            method: 'POST',
            url: '/auth',
            data: authData,
            json: true
        };
        axios(authOptions)
        .then(response => {
            //const expirationDate = new Date(new Date().getTime()+response.data.expiresIn*1000);
            localStorage.setItem('token',response.data.token);
            //localStorage.setItem('expirationDate',expirationDate);
            //localStorage.setItem('userId',response.data.userId);
            //localStorage.setItem('uimage',response.data.uimage);
            //localStorage.setItem('userid',response.data.userid);
            const authData1 = {
                empid:eid,
                password:pwd,
                newpassword: npwd
            };
            var authOptions1 = {
                method: 'PUT',
                url: '/user/pwd',
                data: authData1,
                json: true
            };
            axios(authOptions1)
            .then(response => {
                dispatch(authSuccess(response.data.isFirst,response.data.token, response.data.username, response.data.desig,response.data.isAdmin,response.data.empid,response.data.userid, response.data.unitid, response.data.unitname,response.data.gstin,response.data.premises,response.data.role,response.data.permissions,response.data.storeid,response.data.accstores))})
            .catch(error => {
                dispatch(authFail(error));
            });
        })
        .catch(error => {
            dispatch(authFail(error));
        });
    }
}        
export const setAuthRedirectPath = (path) => {
    return {
        type:actionTypes.SET_AUTH_REDIRECT_PATH,
        path:path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token=localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (new Date() > expirationDate) {
                //dispatch(logout());
            } else {
                dispatch(authSuccess(token,localStorage.getItem('userId')));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            }
        }
    }
}
