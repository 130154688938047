import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Oocostalloc = (props) => {
  const [sessions,setsessions] = useState([]);
  let ooroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    focqty:0,
    costalloc:[],
    advt:[],
    cost: {
      displayamt:0,
      flexamt:0,
      grossamt:0,
      discper:0,
      disc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [ooro,setooro] = useState(ooroinit);
  const [saved,setSaved] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [subcentres,setsubcentres] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRows1, setExpandedRows1] = useState(null);
  const [centreCostperTotal, setCentreCostperTotal] = useState('');
  const [centreCostamtTotal, setCentreCostamtTotal] = useState('');
  const [currentCentre, setCurrentCentre] = useState('');
  const [streamCostperTotal, setStreamCostperTotal] = useState('');
  const [streamCostamtTotal, setStreamCostamtTotal] = useState('');
  const [currentStream, setCurrentStream] = useState('');
  const [divCostperTotal, setDivCostperTotal] = useState('');
  const [divCostamtTotal, setDivCostamtTotal] = useState('');

  let disable=true;


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmediacost',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/stream/allwithdiv', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsubcentres(response.data.streams);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsubcentres([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.oorodata,units]);
  useEffect(() =>  {
    const setfmCosts = async () => {  
      const newpro = {...history.location.state.oorodata};
      let newcostalloc=[];
      if (!newpro.costalloc[0].streams || newpro.costalloc[0].streams.length === 0) {
        newcostalloc =  newpro.costalloc.map( e => {
          const aper = e.alloc?e.alloc:e.allocper;
          const allamt=Number(((newpro.cost.netamt-newpro.cost.gst-newpro.cost.roundoff) * (aper) / 100).toFixed(3));
          const s = subcentres.map(e => {return(e)});
          return ({'centre':e.centre,'allocper':aper,'allocamt':allamt,'streams':s})
        });
        const nc1 = newcostalloc.map( c =>  {
          const s1 = c.streams;
          const amt  = 0; //Number((c.allocamt / s1.length).toFixed(3));
          const per  = 0; //Number((amt / c.allocamt *  100).toFixed(3));
          const strm = s1.map(s => {
            return ({'stream':s.stream,'allocper':per,'allocamt':amt,'division':s.division});
          });
          c.streams = strm;
          return (c);
        });
        newpro.costalloc = nc1;
      }
      const cc    = newpro.costalloc.length>0?newpro.costalloc.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0;
      const ccamt = newpro.costalloc.length>0?newpro.costalloc.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0;
      setCentreCostperTotal("Total Allocated % : "+cc.toFixed(3));
      setCentreCostamtTotal("Total Allocated Amt : "+ccamt.toFixed(3));
      setooro(newpro);
    }
    setfmCosts();
  },[history.location.state.oorodata,subcentres]);

  useEffect(() => {
      const newooro = {...history.location.state.oorodata};
      const selcentres = units.filter((el) => {
        return newooro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
  },[history.location.state.mode,history.location.state.oorodata,units]);

  const ooroSave = (event) => {
    event.preventDefault();
    if (!ooro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newooro = {...ooro};
    if (ooro.session === "") {
      const xx =sessions.splice(-1);
      newooro.session = xx[0];
    };
    if (ooro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!ooro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!ooro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (ooro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!ooro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!ooro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newooro.status.push(status);
      newooro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/ooro/', 
        data: newooro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Verified',
        sdate:new Date(),
      };
      newooro.status.push(status);
      newooro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/ooro/', 
        data: newooro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
}
const allowExpansion = (rowData) => {
  return rowData.items.length > 0;
};
const onEditorValueChanged = (productKey, props, value) => {
  let updatedItems = [...props.value];
  updatedItems[props.rowIndex][props.field] = value;
  const newpo = {...ooro};
  const centreID = newpo.costalloc.findIndex(e => {
    return e.centre._id === currentCentre.centre._id;
  });
  const streamID = newpo.costalloc[centreID].streams.findIndex(e => {
    return e.stream._id === currentStream.stream._id;
  });
  if (props.field === 'allocper') {
    updatedItems[props.rowIndex]["allocamt"] = Number((newpo.costalloc[centreID].streams[streamID].allocamt * value / 100).toFixed(3));
  } else {
    updatedItems[props.rowIndex]["allocper"] = Number((value / newpo.costalloc[centreID].streams[streamID].allocamt *  100).toFixed(3));
  }
  newpo.costalloc[centreID].streams[streamID].division = updatedItems;
  const cc = updatedItems.length>0?updatedItems.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0;
  const ccamt = updatedItems.length>0?updatedItems.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0;
  setDivCostperTotal("Total Allocated % : "+cc.toFixed(3));
  setDivCostamtTotal("Total Allocated Amt : "+ccamt.toFixed(3));
  setooro(newpo);
}

const caperEditord = (productKey, props) => {
  return <InputNumber min={0} max={100} mode="decimal" minFractionDigits={3} maxFractionDigits={3}  inputStyle={{width:'50%'}} value={props.rowData['allocper']}  
  onChange={(e) => onEditorValueChanged(productKey, props, e.value)}/>
}
const caamtEditord = (productKey, props) => {
  return <InputNumber  inputStyle={{width:'50%'}} mode="decimal" minFractionDigits={3} maxFractionDigits={3} value={props.rowData['allocamt']}  
  onChange={(e) => onEditorValueChanged(productKey, props, e.value)}/>
}

const onEditorValueChanges = (productKey, props, value) => {
  let updatedItems = [...props.value];
  updatedItems[props.rowIndex][props.field] = value;
  const newpo = {...ooro};
  const centreID = newpo.costalloc.findIndex(e => {
    return e.centre._id === currentCentre.centre._id;
  });
  if (props.field === 'allocper') {
    updatedItems[props.rowIndex]["allocamt"] = Number((newpo.costalloc[centreID].allocamt * value / 100).toFixed(3));
  } else {
    updatedItems[props.rowIndex]["allocper"] = Number((value / newpo.costalloc[centreID].allocamt *  100).toFixed(3));
  }
  newpo.costalloc[centreID].streams = updatedItems;
  const cc = updatedItems.length>0?updatedItems.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0;
  const ccamt = updatedItems.length>0?updatedItems.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0;
  setStreamCostperTotal("Total Allocated % : "+cc.toString());
  setStreamCostamtTotal("Total Allocated Amt : "+ccamt.toString());
  setooro(newpo);
}

const caperEditors = (productKey, props) => {
  return <InputNumber min={0} max={100}  inputStyle={{width:'50%'}} mode="decimal" minFractionDigits={3} maxFractionDigits={3} value={props.rowData['allocper']}  
  onChange={(e) => onEditorValueChanges(productKey, props, e.value)}/>
}
const caamtEditors = (productKey, props) => {
  return <InputNumber  inputStyle={{width:'50%'}} mode="decimal" minFractionDigits={3} maxFractionDigits={3}  value={props.rowData['allocamt']}  
  onChange={(e) => onEditorValueChanges(productKey, props, e.value)}/>
}

const rowExpansionTemplate1 = (data) => {
  setCurrentCentre(data);
  setStreamCostperTotal("Total Allocated % : "+(data.streams.length>0?data.streams.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0).toFixed(3));
  setStreamCostamtTotal("Total Allocated Amt : "+(data.streams.length>0?data.streams.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0).toFixed(3));
  const hdr1=(<div className="flex flex-row m-0 justify-content-evenly">
            <span > {streamCostperTotal}</span>
            <span > {streamCostamtTotal}</span></div>)
  return (
      <div style={{width:'80%', margin:'auto',padding:'5px 0px 0 0px', fontSize: 'small'}}>
          <DataTable expandedRows={expandedRows1} onRowToggle={(e) => setExpandedRows1(e.data)} rowExpansionTemplate={rowExpansionTemplate2} 
            responsiveLayout="scroll" value={data.streams} className="p-datatable-sm" style={{padding:'0px',fontSize:'14px'}}
            header={hdr1}
            >
            <Column expander={allowExpansion} style={{ width: '3%' ,padding:'0 2px 0 2px'}} />
            <Column field="stream.name" header="Stream"  style={{ width:'30%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="allocper" header="Cost(%)" editor={(props) => caperEditors('ooro.costalloc', props)} style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="allocamt" header="Cost-Amt" editor={(props) => caamtEditors('ooro.costalloc', props)}  style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
          </DataTable>

      </div>
  );
}
const rowExpansionTemplate2 = (data) => {
  setCurrentStream(data);
  setDivCostperTotal("Total Allocated % : "+(data.division.length>0?data.division.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0).toFixed(3));
  setDivCostamtTotal("Total Allocated Amt : "+(data.division.length>0?data.division.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0).toFixed(3));
  const hdr2=(<div className="flex flex-row m-0 justify-content-evenly">
            <span > {divCostperTotal}</span>
            <span > {divCostamtTotal}</span></div>)
return (
      <div style={{width:'80%', margin:'auto'}}>
          <DataTable responsiveLayout="scroll" value={data.division} className="p-datatable-sm" style={{padding:'0px',fontSize:'14px'}} header={hdr2}>
            <Column field="division.name" header="Division"  style={{ width:'30%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="allocper" header="Cost(%)" editor={(props) => caperEditord('ooro.costalloc', props)} style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="allocamt" header="Cost-Amt" editor={(props) => caamtEditord('ooro.costalloc', props)} style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
          </DataTable>
      </div>
  );
}
const onEditorValueChange = (productKey, props, value) => {
  let updatedItems = [...props.value];
  updatedItems[props.rowIndex][props.field] = value;
  if (props.field === 'allocper') {
    updatedItems[props.rowIndex]["allocamt"] = Number(((ooro.cost.netamt-ooro.cost.gst-ooro.cost.roundoff) * value / 100).toFixed(3));
  } else {
    updatedItems[props.rowIndex]["allocper"] = Number((value / (ooro.cost.netamt-ooro.cost.gst-ooro.cost.roundoff) *  100).toFixed(3));
  }
  const newpo = {...ooro};
  newpo.costalloc = updatedItems;
  const cc = updatedItems.length>0?updatedItems.map(item => item.allocper).reduce((acc,curr)=>acc+curr):0;
  const ccamt = updatedItems.length>0?updatedItems.map(item => item.allocamt).reduce((acc,curr)=>acc+curr):0;
  setCentreCostperTotal("Total Allocated % : "+cc.toFixed(3));
  setCentreCostamtTotal("Total Allocated Amt : "+ccamt.toFixed(3));
  setooro(newpo);
}

const caperEditor = (productKey, props) => {
  return <InputNumber min={0} max={100} mode="decimal" minFractionDigits={3} maxFractionDigits={3}  inputStyle={{width:'50%'}} value={props.rowData['allocper']}  
  onChange={(e) => onEditorValueChange(productKey, props, e.value)}/>
}
const caamtEditor = (productKey, props) => {
  return <InputNumber  inputStyle={{width:'50%'}} mode="decimal" minFractionDigits={3} maxFractionDigits={3} value={props.rowData['allocamt']}  
  onChange={(e) => onEditorValueChange(productKey, props, e.value)}/>
}

const BtnOptions = (<>
  <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={ooroSave} />
  <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>);

const hdr = (<div className="flex flex-row m-0 justify-content-evenly">
<span > {centreCostperTotal}</span>
<span > {centreCostamtTotal}</span></div>)


  if (saved)  history.goBack();

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Outdoor - RO Cost Allocation</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="oorono" readOnly value={ooro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="ooroDate" name="roDate" value={new Date(ooro.rodate)}  mask="99/99/9999" dateFormat="dd/mm/yy"  />
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={ooro.session}  />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={ooro.division}  />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="ooroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={ooro.agency} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="ooroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={ooro.caption}  />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={ooro.bstate} 
              options={states} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="oorono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={ooro.centres} 
              options={selUnits} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'5px 0px 0 0px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
            <div  className="field justify-content-center grid mb-1 ml-29 m-auto">
              <label htmlFor="page" className="mr-2 mb-0">Total Amt to Allocate : </label>
              <InputNumber disabled={disable} inputStyle={{width:'100%'}}  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={ooro.cost.netamt-ooro.cost.gst-ooro.cost.roundoff} />
            </div>
          </div>
          <div style={{width:'90%',margin:'auto',padding:'5px 0px 0 0px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
            <DataTable expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate1} 
              responsiveLayout="scroll" value={ooro.costalloc} className="p-datatable-sm" header={hdr}
              dataKey="centre.name" paginator rows={25} rowsPerPageOptions={[10, 25, 50]} style={{padding:'0px',fontSize:'14px'}}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
              >
              <Column expander={allowExpansion} style={{ width: '3%' ,padding:'0 2px 0 2px'}} />
              <Column field="centre.name" header="Centre"  style={{ width:'30%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
              <Column field="allocper" header="Cost(%)" editor={(props) => caperEditor('ooro.costalloc', props)} style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
              <Column field="allocamt" header="Cost-Amt" editor={(props) => caamtEditor('ooro.costalloc', props)} style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            </DataTable>
          </div>
          <div className="flex flex-row justify-content-around w-6 mt-0 py-2 m-auto">
            {BtnOptions}
          </div>

    </div>

  );
}

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Oocostalloc);