import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import logo from "../../assets/allen.png";
import axios from '../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import styles from './billspending.module.css';
import { useHistory } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';

const Pendingbills = (props) => {
    const history = useHistory();
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [items, setitems] = useState([]);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        setloading(true);
        let authOptions = {
            method: 'POST',
            url: '/brand/billpendingreport/', 
            data: {storeid:props.storeid},
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
          .then(response => {
            setitems(response.data);
            setloading(false);
          })
          .catch(error => {
            toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
            setloading(false);
          });
    },[props.storeid]); 

  
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
            </React.Fragment>
        )
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-sm" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const formatDate = (dt) => {
        let cdt = new Date(dt);
        let month = cdt.getMonth() + 1;
        let day = cdt.getDate();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        return day+"-"+month+"-"+cdt.getFullYear() ;
    };
        
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.rodate);
    };

    let sst = <div className={styles.TC} >
    <div >
        <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
        <DataTable resizableColumns sortField="grnno" sortOrder={1} columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={items} 
            dataKey="rono" paginator rows={25} rowsPerPageOptions={[10, 25, 50]} responsiveLayout="scroll" style={{padding:'0px',fontSize:'14px'}}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
            globalFilter={globalFilter} >
            <Column field="rono" header="RO No" sortable style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 22px'}}></Column>
            <Column body={dateBodyTemplate} header="Date"  style={{ width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="division" header="Division"  style={{textAlign:'center', width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="centre" header="Centre"  style={{textAlign:'center', width:'15%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="agency" header="Agency" sortable style={{textAlign:'left', width:'25%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="mobile" header="Mobile No"  style={{textAlign:'center', width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="netamt" header="Net Amt"  style={{textAlign:'right', width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
            <Column field="odays" header="Days"  style={{textAlign:'center', width:'10%',minWidth: '16rem',padding:'0 2px 0 2px'}}></Column>
       </DataTable>
    </div>
    <div className="flex flex-row justify-content-around w-6 mt-1 pb-1 m-auto">
        <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
    </div>
    </div>;
    if (loading)  
        sst = <ProgressSpinner style={{marginLeft:'48%', width: '40px', height: '40px'}} strokeWidth="6"  animationDuration=".7s"/> //<Spinner />

    return (
        <div className={styles.PER}>
            <Toast ref={toast} />
            <div className={styles.Headerper}>
                <img src={logo} onClick={() => history.push('/MEDIA')} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
                <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>Branding - Pending Bills Report</h2>
                <div className={styles.Userper}>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
                </div>
            </div>
            {sst}
        </div>
    );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin,
        storeid:state.auth.storeid
    }
  }
  

  export default connect(mapStateToProps)(Pendingbills);