import React from 'react';
import { Panel } from 'primereact/panel';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { Fieldset } from 'primereact/fieldset';

const Statuslog = (props) => {
    const formatDate = (cdt) => {
        const date=new Date(cdt);
        if (!date) return null;
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return day+'-'+month+'-'+date.getFullYear();
    };
    let St=null;
    if (props.statuses)
        St = (props.statuses.map((element,k) => <Panel key={k}  header={element.status + ' By ' +element.username +' On '+formatDate(element.sdate.toString().slice(0,10))}>
            {element.comment}
        </Panel>));
    return  <>
    <Fieldset legend="Status Log" toggleable collapsed='true'>
    {St}
    </Fieldset>
    </>;
  
}

export default Statuslog;