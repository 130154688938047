import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../Components/state';

import Statuslog from "../statuslog"
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Brro = (props) => {
  const [sessions,setsessions] = useState([]);
  let brroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    costalloc:[],
    advt:{
      media:'',
      publication:'',
      edition:'',
      size:'',
      position:'',
      colorb:'',
      dtpublish:'',
      amt:0
    },
    cost: {
      grossamt:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  
  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [brro,setbrro] = useState(brroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [oldRo,setOldRo] = useState('');
  const medias = [
    'Souvenior',
    'Brochure',
    'Booklet',
    'Magazine',
    'Newspaper',
    'Other'
    ]
  const position = [
    'Front Page',
    'Front Inside Page',
    'Back Inside Page',
    'Back Page',
    'Good Page Position',
    'Inside Page',
    'Center Page',
    'Page 3',
    'Page 5',
    'Page 3 or 5',
    'Other'
  ]
  const colours = [
    'Colour',
    'B&W'
  ]

  let disable=true;
  if (brro.finalstatus === 'Submitted' || brro.finalstatus === 'Draft' || brro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newbrroa) => {
    let tnet=0;
    const newbrro = {...newbrroa.cost};
    newbrro.grossamt = newbrroa.advt.amt;
    
    tnet = newbrro.grossamt;
    newbrro.gst = Math.round((tnet * newbrro.gstper/100)*100)/100;
    tnet = tnet + newbrro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newbrro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newbrro.roundoff = Math.round(-roff*100)/100;
    }
    newbrro.netamt = tnet;
    if (newbrroa.agency.dtype === 'Unregistered' ) {
      tnet = newbrro.netamt-newbrro.gst;
      newbrro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newbrro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newbrro.roundoff = Math.round(-roff*100)/100;
      }
      newbrro.netamt=tnet;
    }
    newbrroa.cost = newbrro;
    setbrro(newbrroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.brrodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newbrro = {...history.location.state.brrodata};
      const selcentres = units.filter((el) => {
        return newbrro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselUnits(selcentres!==null?selcentres:'');
      setbrro(newbrro);
      //setselfmmain(ed);
    }
  },[history.location.state.mode,history.location.state.brrodata,units]);

  const brroSave = (event) => {
    event.preventDefault();
    if (!brro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newbrro = {...brro};
    if (brro.session === "") {
      const xx =sessions.splice(-1);
      newbrro.session = xx[0];
    };
    if (brro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!brro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!brro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (!brro.advt) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!brro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!brro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newbrro.status.push(status);
      newbrro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/brand/', 
        data: newbrro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newbrro.status.push(status);
      newbrro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/brand/', 
        data: newbrro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const brroCancel = (event) => {
    event.preventDefault();
    brroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={brroCancel} />
    </>
  );

  //Approve
  const brroApproveReject = (type) => {  
    if (brro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/brand/approve/', 
        data: {'rono':brro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const brroApprove = (event) => {
    event.preventDefault();
    brroApproveReject('Approved');
  }
  const brroReject = (event) => {
    event.preventDefault();
    brroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'BR-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apbrroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={brroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={brroReject} />
    </>
  );

  const onVendorChange = (e) => {
    const brronew = {...brro};
    brronew.agency = e.value;
    brronew.bunit = units.find(x => x.name === 'KOTA-HO');
    brronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+brronew.bunit.address+"\n"+brronew.bunit.city+"-"+brronew.bunit.pincode+" "+brronew.bunit.state+"\nGSTIN:"+brronew.bunit.gstin;
    setbrro(brronew);
  };
 const onCaptionChange = (e) => {
  const brronew = {...brro};
  brronew.caption = e.value;
  //recalculate(brronew); 
  setbrro(brronew);
};
const onSessionChange = (e) => {
  const brronew = {...brro};
  brronew.session = e.value;
  //recalculate(brronew);    
  setbrro(brronew);
};
const onDivisionChange = (e) => {
  const brronew = {...brro};
  brronew.division = e.value;
  //recalculate(brronew);    
  setbrro(brronew);
};
const onCentreChange = (e) => {
  e.preventDefault();
  const brronew = {...brro};
  brronew.centres = e.value;
  brronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
  //let final=[];
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    brronew.costalloc = final2;
  }
  setbrro(brronew);
};


const onStateChange  = (e) => {
  e.preventDefault();
  const brronew = {...brro};
  brronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });

  setselUnits(selcentres!==null?selcentres:'');

  //setselUnits(units.filter(es => es.state === e.value));
  setbrro(brronew);
};
const onBunitChange = (e) => {
  const brronew = {...brro};
  brronew.bunit = e.target.value;
  brronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setbrro(brronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}
const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/brand/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newbrro = response.data;
      newbrro.rono = ''
      newbrro.status = []
      newbrro.rodate = new Date();
      const selcentres = units.filter((el) => {
        return newbrro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      setbrro(newbrro);
    })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setbrro(brroinit);
  });
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && brro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (brro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={brroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newbrro = {...brro};
    newbrro[`${name}`] = val;
    setbrro(newbrro);
  }

  const onInputChangeadvt = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let it = {...brro}
    let cst = {...it.advt}
    cst[`${name}`] = (val);
    it.advt = cst
    setbrro(it);
  }

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newbrro = {...brro}
    let cst = {...newbrro.cost}
    cst[`${name}`] = (val);
    newbrro.cost = cst
    recalculate(newbrro);
    //setbrro(newbrro);
  }

  const onInputNumberChange = (e, name) => {
    let val = (e.value || 0);
    let it = {...brro}
    let cst = {...it.advt}
    cst[`${name}`] = (val);
    it.advt = cst
    recalculate(it);        
  }

  const onMediaChange = (e) => {
    const brronew = {...brro};
    brronew.advt.media = e.value;
    setbrro(brronew);
  };
  
  const onColorChange = (e) => {
    const brronew = {...brro};
    brronew.advt.colorb = e.value;
    setbrro(brronew);
  };
  
  const onPositionChange = (e) => {
    const brronew = {...brro};
    brronew.advt.position = e.value;
    setbrro(brronew);
  };

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Branding - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="brrono" readOnly value={brro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="brroDate" name="roDate" value={new Date(brro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={brro.session} onChange={onSessionChange} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={brro.division} onChange={onDivisionChange} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Copy Old RO</label>
            <InputText disabled={disable} style={{width:'250px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={brro.agency} onChange={onVendorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="brroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={brro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={brro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="brrono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={brro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-between mt-1 surface-100">
            <div  className="w-2 mb-1 ">
                <label  className=" mr-2 mb-0">Medias</label>
                <Dropdown  disabled={disable}  placeholder="Select print Media" style={{width:'100%'}}  options={medias} value={brro.advt.media} onChange={onMediaChange} />
                {brro.advt.media === 'Other'?<InputText  className="w-auto"  value={brro.advt.mediaother} onChange={(e) => onInputChangeadvt(e,'mediaother')} />:null}
          </div>
            <div  className="w-2 mb-1">
                <label  className=" mr-2 mb-0">Publication</label>
                <InputText  disabled={disable} style={{width:'100%'}} value={brro.advt.publication} onChange={(e) => onInputChangeadvt(e,'publication')} />
            </div>
            <div  className="w-2 mb-1">
                <label  className=" mr-2 mb-0">Edition</label>
                <InputText  disabled={disable} style={{width:'100%'}} value={brro.advt.edition} onChange={(e) => onInputChangeadvt(e,'edition')} />
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Size</label>
                <InputText  disabled={disable} style={{width:'100%'}} value={brro.advt.size} onChange={(e) => onInputChangeadvt(e,'size')} />
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Position</label>
                <Dropdown disabled={disable}  placeholder="Select Position" style={{width:'100%'}}  options={position} value={brro.advt.position} onChange={onPositionChange} />
                {brro.advt.position === 'Other'?<InputText  className="w-auto"  value={brro.advt.positionother} onChange={(e) => onInputChangeadvt(e,'positionother')} />:null}
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="area" className=" mr-2 mb-0">Colour</label>
                <Dropdown disabled={disable}  placeholder="Select Colour" style={{width:'100%'}}  options={colours} value={brro.advt.colorb} onChange={onColorChange} />
            </div>
            <div  className="w-2 mb-1">
                <label className="mr-2 mb-0">Date Publish</label>
                <Calendar disabled={disable}  style={{width:'100%'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" id="brroDate" name="dtpublish" value={new Date(brro.advt.dtpublish)} onChange={(e) => onInputChangeadvt(e, 'dtpublish')} mask="99/99/9999" dateFormat="dd/mm/yy" required/>
            </div>
            <div  className="w-2 mb-1">
                <label htmlFor="location" className="mr-2 mb-0">Amount</label>
                <InputNumber disabled={disable} style={{width:'100%'}} locale="en-IN" minFractionDigits={2}  value={brro.advt.amt} onChange={(e) => onInputNumberChange(e,'amt')} />
            </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="brrono" readOnly value={brro.cost.grossamt}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="brrono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="brrono"  value={brro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="brrono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="brroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="brrono" readOnly value={brro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={brro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={brro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={brro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={brro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={brro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {brro && <span>Are you sure you want to Cancel this RO <b>{brro.rono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apbrroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Brro);