import * as actionTypes from '../actions/actionsTypes'

const initialState = {
    token:null,
    userid:null,
    isAdmin:null,
    isFirst:null,
    empid:null,
    username:null,
    desig:null,
    unitid:null,
    unitgstin:null,
    unitname:null,
    premises:null,
    role:null,
    permissions:null,
    accstores:null,
    storeid:null,
    error:null,
    loading:false,
    authRedirectPath:'/'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START :
            return {
                ...state,
                error:null,
                loading:true
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                userid:action.userid,
                token:action.token,
                empid:action.empid,
                isAdmin:action.isAdmin,
                isFirst:action.isFirst,
                username:action.username,
                desig:action.desig,
                unitid:action.unitid,
                unitname:action.unitname,
                unitgstin:action.unitgstin,
                premises:action.premises,
                role:action.role,
                permissions:action.permissions,
                accstores:action.accstores,
                storeid:action.storeid,
                error:null,
                loading:false
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error:action.error,
                loading:false
            }
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                token:null,
                isAdmin:null,
                isFirst:null,
                userid:null,
                empid:null,
                username:null,
                desig:null,
                unitid:null,
                unitname:null,
                unitgstin:null,
                premises:null,
                accstores:null,
                role:null,
                permissions:null,
                storeid:null,
            }
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return {
                ...state,
                authRedirectPath:action.path
            }
        
        default : return state;
    }
}

export default reducer;