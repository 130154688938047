import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import axios from '../../../axios-allen';


const Costalloc = (props) => {
    let emptyCentre = {
        centre:'',
        alloc:0
    };

    const [units,setunits] = useState([]);
    const [Centres, setCentres] = useState([]);
    const [CentreDialog, setCentreDialog] = useState(false);
    const [deleteCentreDialog, setDeleteCentreDialog] = useState(false);
    const [Centre, setCentre] = useState(emptyCentre);
    const [costtotal, setcosttotal] = useState("");
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        let authOptions = {
            method: 'GET',
            url: '/unit/all',
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
          };
          axios(authOptions)
            .then(response => {
              setunits(response.data);
            })
            .catch(error => {
              toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
              setunits([]);
          });
          const cc = props.Centre.length>0?props.Centre.map(item => item.alloc).reduce((acc,curr)=>acc+curr):0;
          setcosttotal("Total : "+cc);
          setCentres(props.Centre);
    },[props.Centre]);

    const openNew = () => {
        setCentre(emptyCentre);
        setCentreDialog(true);
    };

    const hideDialog = () => {
        setCentre(emptyCentre);
        setCentreDialog(false);
    };

    const hideDeleteCentreDialog = () => {
        setDeleteCentreDialog(false);
    };

    const saveCentre = (event) => {
        event.preventDefault();
        const cc = Centre.length>0?Centre.map(item => item.alloc).reduce((acc,curr)=>acc+curr):0;
        setcosttotal("Total : "+cc);
        if (Centre.centre.name.trim()) {
            let _Centres = [...Centres];
            let _Centre = {...Centre};
            const index = findIndexByName(Centre.centre.name);
            if (index >= 0) 
            {    _Centres[index] = _Centre;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Centre Updated', life: 3000 });
            }
            else {
                _Centres.push(_Centre);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Centre Created', life: 3000 });
            }
            setCentres(_Centres); 
            props.SaveCentres(_Centres);
            setCentreDialog(false);
            setCentre(emptyCentre);
        }
    };

    const editCentre = (Centre) => {
        setCentre(Centre);
        setCentreDialog(true);
    };

    const confirmDeleteCentre = (Centre) => {
        setCentre(Centre);
        setDeleteCentreDialog(true);
    };

    const deleteCentre = () => {
        let _Centres = Centres.filter(val => val.centre.name !== Centre.centre.name);
        setCentres(_Centres);
        setDeleteCentreDialog(false);
        setCentre(emptyCentre);
        props.SaveCentres(_Centres);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Centre Deleted', life: 3000 });
        const cc = _Centres.length>0?_Centres.map(item => item.alloc).reduce((acc,curr)=>acc+curr):0;
        setcosttotal("Total : "+cc);
  };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Centres.length; i++) {
            if (Centres[i].centre.name === name) {
                index = i;
                break;
            }
        }
        return index;
    };

     const onInputChange = (e, name) => {
        let val = (e.value || 0);
        let _Centre = {...Centre};
        _Centre[`${name}`] = parseFloat(val);
        setCentre(_Centre);
    };
    const onCentreChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Centre = {...Centre};
        _Centre.centre = val;
        setCentre(_Centre);
    }
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disable} label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disable && !props.receiving} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={() => editCentre(rowData)} />
            <Button disabled={props.disable} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteCentre(rowData)} />
            </>
        );
    };

    const deleteCentreDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCentreDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCentre} />
        </>
    );

    return (
        <div className="itemtablepr">
            <Toast ref={toast} />

            <div className="card">
                <h3 style={{margin:'0',textAlign:'center',padding:'0'}}>List Of Centre</h3>
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable  resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Centres} 
                    dataKey="edition" sortField="edition" sortOrder={1} paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Centre"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >
                    <Column field="centre.name" header="Centre Name" sortable style={{width:'70%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="alloc" header="Cost%" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column header={costtotal} body={actionBodyTemplate} style={{padding:'0 0 0 8px'}}></Column>
                </DataTable>
            </div>
            
            <Dialog visible={CentreDialog} style={{ width: '700px' }} header="Centre Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveCentre} onReset={hideDialog}> 
                    <div className="field col mt-1">
                        <label htmlFor="centres">Centre Name*</label>
                        <Dropdown 
                        value={Centre.centre} 
                        options={units} 
                        onChange={onCentreChange} 
                        optionLabel="name" placeholder="Select Centres" 
                        />
                    </div>
                    <div className="field col mt-1">
                        <label htmlFor="cost">Cost %</label>
                        <InputNumber  autoComplete="off" id="cost" mode="decimal" minFractionDigits={3} maxFractionDigits={3} value={Centre.alloc} required onChange={(e) => onInputChange(e, 'alloc')} />
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                        </div>
                        <div className="field col">
                            <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteCentreDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCentreDialogFooter} onHide={hideDeleteCentreDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Centre && <span>Are you sure you want to delete <b>{Centre.edition}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default Costalloc;