import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './bill.css'

import { useHistory } from "react-router-dom";

const Billdispatch = (props) => {

  const history = useHistory()
  const [Items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [frDate, setfrDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [dispatchItemDialog, setDispatchItemDialog] = useState(false);
  const [dispatchItemsDialog, setDispatchItemsDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const [despDate, setdespDate] = useState(new Date());
  const [saved,setSaved] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);
  const [selectedStatuses, setSelectedStatuses] = useState(['Approved']);

  const statuses = [
    "Approved",
    "Dispatched",
    "Inwarded",
    "Posted"
  ];

  useEffect(() => {
    const i = props.permissions.find(x => (x.name === 'SP-BILLDISPATCH'))
    if (props.isAdmin || i) {
      axios.get('/bill/robilldesp/SP', {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setItems([]);
      });
      } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised for Bill Diapatches', life: 3000 }); 
  }, [props.storeid,props.isAdmin,props.permissions]);

  const billsearch = (event) => {
      event.preventDefault();  
      if ((frDate === null || toDate === null) && selectedStatuses.length===0) {
        return;
      }
    // const s = frDate.getFullYear()+"-"+((frDate.getMonth()+1) <10?"0"+(frDate.getMonth()+1):(frDate.getMonth()+1))+"-"+frDate.getDate()
    // const s1 = toDate.getFullYear()+"-"+((toDate.getMonth()+1) <10?"0"+(toDate.getMonth()+1):(toDate.getMonth()+1))+"-"+toDate.getDate() 
     let authOptions = {
      method: 'POST',
      url: '/bill/robillsearch/SP', //+ props.storeid, 
      data: {'sdt':frDate, 'edt':toDate, 'sts': selectedStatuses},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
    .then(response => {
      setItems(response.data);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    });
  }

  const formatDate = (dt) => {
    let cdt = new Date(dt);
    let month = cdt.getMonth() + 1;
    let day = cdt.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return day+"-"+month+"-"+cdt.getFullYear() ;
  };

  const hideDispatchItemDialog = () => {
    setDispatchItemDialog(false);
  };

    const hideDispatchItemsDialog = () => {
    setDispatchItemsDialog(false);
  };
  const confirmDispatchItem = (Item) => {
    setSelectedItem(Item);
    setDispatchItemDialog(true);
  };

  const confirmDispatchSelected = () => {
    setDispatchItemsDialog(true);
  };

  const billDispatch = () => {  
    if (selectedItem.finalstatus === "Approved") {
      setBlockedDocument(true);
      let newbill = {...selectedItem}
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Dispatched',
        sdate:new Date(),
      };
      newbill.sendaccdate = despDate;
      newbill.status.push(status);
      newbill.finalstatus = 'Dispatched';
      let authOptions = {
        method: 'PUT',
        url: '/bill/sp/', 
        data: {'bill':newbill,'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Dispatched', life: 3000 });
        setBlockedDocument(false);
        setDispatchItemDialog(false);
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setBlockedDocument(false);
        setDispatchItemDialog(false);
        setSaved(true);
      });
    } 
  }

  const billsDispatch = () => {  
    if (selectedItems.length >0 ) {
      setBlockedDocument(true);
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Dispatched',
        sdate: new Date()
      }
      let authOptions = ""
      for (let i=0;i<selectedItems.length;i++) {
        let newbill = {...selectedItems[i]}
        newbill.status.push(status);
        newbill.finalstatus = 'Dispatched';
        newbill.sendaccdate = despDate;
        authOptions = {
            method: 'PUT',
            url: '/bill/sp/', 
            data: {'bill':newbill,'selitem':selectedItems},
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
        .then(response => {
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
      }
      setBlockedDocument(false);
      setDispatchItemsDialog(false);
      setSaved(true);
    } 
  }

  const dispatchItemDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideDispatchItemDialog} />
      <Button label="Dispatch" id="Dispatch" className="p-button-text p-button-success" onClick={billDispatch} />
    </>
  );
  const dispatchItemsDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideDispatchItemsDialog} />
      <Button label="Dispatch" id="Dispatched" className="p-button-text p-button-success" onClick={billsDispatch} />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
      <Button  label="Dispatch" icon="pi pi-arrow-circle-right" className="p-button-danger p-button-text" onClick={confirmDispatchSelected} disabled={!selectedItems || !selectedItems.length} />
      );
  };

  const rightToolbarTemplate = () => {
      return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      )
  }

  const recddateBodyTemplate = (rowData) => formatDate(rowData.recddate);
  const billdateBodyTemplate = (rowData) => formatDate(rowData.billdate);
  const senddateBodyTemplate = (rowData) => rowData.finalstatus === 'Dispatched' || rowData.finalstatus === 'Inwarded'?formatDate(rowData.sendaccdate):'';
  const inwdateBodyTemplate = (rowData) => rowData.finalstatus === 'Inwarded'?formatDate(rowData.recdaccdate):'';

  const actionBodyTemplate = (rowData) => 
    <Button icon="pi pi-arrow-circle-right" className="p-button-rounded p-button-text p-button-primary mr-2 " onClick={() => confirmDispatchItem(rowData)} />;
  
  if (saved)  {
    const i = props.permissions.find(x => (x.name === 'SP-BILLDISPATCH'))
    if (props.isAdmin || i) {
      axios.get('/bill/robilldesp/SP', {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setItems([]);
      });
      } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised for Bill Dispatches', life: 3000 }); 
  }

  return (
    <div className="BILL">
      <BlockUI blocked={blockedDocument} fullScreen />
      <div className="Headerbill">
        <img src={logo} onClick={() => history.push('/MEDIA')} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
        <h2 style={{marginTop:'5px', textAlign:'center'}}>Media Module - Sponsorship<br/>Sponsorship Bills Dispatch</h2>
        <div className="Userbill">
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
        </div>
      </div>
      <div className="Sformbill h-auto mt-2">
        <div className="">
          <label htmlFor="frDate" className="mr-4 h-2rem">Date-From</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="frDate" className="h-2rem" value={frDate} onChange={(e) => setfrDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <label htmlFor="toDate" className="mx-4 h-2rem">Date-To</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="toDate" className="h-2rem" value={toDate} minDate={frDate} onChange={(e) => settoDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <MultiSelect className="ml-4 my-1 h-auto" value={selectedStatuses} options={statuses} onChange={(e) => setSelectedStatuses(e.value)}  placeholder="Select Status" />
        </div>
        <Button icon="pi pi-search" className="ml-2 p-button-text p-button-success" onClick={billsearch}/>
      </div>
      <div className="TCbill">
        <Toast ref={toast} />
        <div className="card h-auto">
            <Toolbar className="p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
            <DataTable  selectionMode="multiple" sortField="ubn" sortOrder={-1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                dataKey="ubn" paginator rows={20} rowsPerPageOptions={[20, 25, 30]} style={{padding:'0px',fontSize:'14px'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                globalFilter={globalFilter} scrollable scrollHeight="320px" >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="ubn" header="Bill UBN" sortable style={{textAlign:'center', width:'8%',padding:'0 2px 0 2px'}}></Column>
                <Column field="recddate" header="Recd Date" sortable style={{textAlign:'center',width:'9%',padding:'0 2px 0 2px'}} body={recddateBodyTemplate}></Column>
                <Column field="bno" header="Bill No" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="billdate" header="Bill Date" sortable style={{textAlign:'center',width:'9%',padding:'0 2px 0 2px'}} body={billdateBodyTemplate} ></Column>
                <Column field="rono" header="RO No" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}}  ></Column>
                <Column field="vendor.name" header="Agency" sortable style={{textAlign:'center',width:'20%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="sendaccdate" header="Disp Date" sortable style={{textAlign:'center',width:'9%',padding:'0 2px 0 2px'}} body={senddateBodyTemplate} ></Column>
                <Column field="recdaccdate" header="Inw Date" sortable style={{textAlign:'center',width:'9%',padding:'0 2px 0 2px'}} body={inwdateBodyTemplate} ></Column>
               <Column field="finalstatus" header="Status" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}}></Column>
                <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
            </DataTable>
            <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
              <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
            </div>
        </div>
        <Dialog visible={dispatchItemDialog} style={{ width: '490px' }} header="Confirm" modal footer={dispatchItemDialogFooter} onHide={hideDispatchItemDialog}>
          <div className="confirmation-content">
            {selectedItem && <><label>Dispatch Date </label><Calendar  style={{width:'140px'}}   monthNavigator yearNavigator yearRange="2010:2030" value={new Date(despDate)} onChange={(e) => setdespDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy" /></>}
          </div>
        </Dialog>

        <Dialog visible={dispatchItemsDialog} style={{ width: '490px' }} header="Confirm" modal footer={dispatchItemsDialogFooter} onHide={hideDispatchItemsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
            {selectedItems && <><label>Dispatch Date </label><Calendar  style={{width:'140px'}}   monthNavigator yearNavigator yearRange="2010:2030" value={new Date(despDate)} onChange={(e) => setdespDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy" /></>}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin,
      storeid:state.auth.storeid
  }
}

export default connect(mapStateToProps)(Billdispatch);