import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../Components/state';

import Statuslog from "../statuslog"
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Spro = (props) => {
  const [sessions,setsessions] = useState([]);
  let sproinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    costalloc:[],
    advt:{
      event:'',
      venue:'',
      dtevent:'',
      deliverables:'',
      amt:0
    },
    cost: {
      grossamt:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [spro,setspro] = useState(sproinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [oldRo,setOldRo] = useState('');

  let disable=true;
  if (spro.finalstatus === 'Submitted' || spro.finalstatus === 'Draft' || spro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newsproa) => {
    let tnet=0;
    const newspro = {...newsproa.cost};
    newspro.grossamt = newsproa.advt.amt;
    
    tnet = newspro.grossamt;
    newspro.gst = Math.round((tnet * newspro.gstper/100)*100)/100;
    tnet = tnet + newspro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newspro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newspro.roundoff = Math.round(-roff*100)/100;
    }
    newspro.netamt = tnet;
    if (newsproa.agency.dtype === 'Unregistered' ) {
      tnet = newspro.netamt-newspro.gst;
      newspro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newspro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newspro.roundoff = Math.round(-roff*100)/100;
      }
      newspro.netamt=tnet;
    }
    newsproa.cost = newspro;
    setspro(newsproa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.sprodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newspro = {...history.location.state.sprodata};
      const selcentres = units.filter((el) => {
        return newspro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselUnits(selcentres!==null?selcentres:'');
      setspro(newspro);
      //setselfmmain(ed);
    }
  },[history.location.state.mode,history.location.state.sprodata,units]);

  const sproSave = (event) => {
    event.preventDefault();
    if (!spro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newspro = {...spro};
    if (spro.session === "") {
      const xx =sessions.splice(-1);
      newspro.session = xx[0];
    };
    if (spro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!spro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!spro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (spro.advt.event.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Event detail', life: 3000 });
      return;
    };
    if (!spro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!spro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newspro.status.push(status);
      newspro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/sponsor/', 
        data: newspro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newspro.status.push(status);
      newspro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/sponsor/', 
        data: newspro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const sproCancel = (event) => {
    event.preventDefault();
    sproApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={sproCancel} />
    </>
  );

  //Approve
  const sproApproveReject = (type) => {  
    if (spro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/sponsor/approve/', 
        data: {'rono':spro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const sproApprove = (event) => {
    event.preventDefault();
    sproApproveReject('Approved');
  }
  const sproReject = (event) => {
    event.preventDefault();
    sproApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'SP-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apsproveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={sproApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={sproReject} />
    </>
  );

  const onVendorChange = (e) => {
    const spronew = {...spro};
    spronew.agency = e.value;
    spronew.bunit = units.find(x => x.name === 'KOTA-HO');
    spronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+spronew.bunit.address+"\n"+spronew.bunit.city+"-"+spronew.bunit.pincode+" "+spronew.bunit.state+"\nGSTIN:"+spronew.bunit.gstin;
    setspro(spronew);
  };
 const onCaptionChange = (e) => {
  const spronew = {...spro};
  spronew.caption = e.value;
  //recalculate(spronew); 
  setspro(spronew);
};
const onSessionChange = (e) => {
  const spronew = {...spro};
  spronew.session = e.value;
  //recalculate(spronew);    
  setspro(spronew);
};
const onDivisionChange = (e) => {
  const spronew = {...spro};
  spronew.division = e.value;
  //recalculate(spronew);    
  setspro(spronew);
};
const onCentreChange = (e) => {
  e.preventDefault();
  const spronew = {...spro};
  spronew.centres = e.value;
  spronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
  //let final=[];
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    spronew.costalloc = final2;
  }
  setspro(spronew);
};


const onStateChange  = (e) => {
  e.preventDefault();
  const spronew = {...spro};
  spronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });

  setselUnits(selcentres!==null?selcentres:'');

  //setselUnits(units.filter(es => es.state === e.value));
  setspro(spronew);
};
const onBunitChange = (e) => {
  const spronew = {...spro};
  spronew.bunit = e.target.value;
  spronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setspro(spronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}
const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/sponsor/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newspro = response.data;
      newspro.rono = ''
      newspro.rodate = new Date();
      newspro.status = []
      const selcentres = units.filter((el) => {
        return newspro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      setspro(newspro);
    })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setspro(sproinit);
  });
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={sproSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && spro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={sproSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (spro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={sproSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newspro = {...spro};
    newspro[`${name}`] = val;
    setspro(newspro);
  }

  const onInputChangeadvt = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let it = {...spro}
    let cst = {...it.advt}
    cst[`${name}`] = (val);
    it.advt = cst
    setspro(it);
  }

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newspro = {...spro}
    let cst = {...newspro.cost}
    cst[`${name}`] = (val);
    newspro.cost = cst
    recalculate(newspro);
    //setspro(newspro);
  }

  const onInputNumberChange = (e, name) => {
    let val = (e.value || 0);
    let it = {...spro}
    let cst = {...it.advt}
    cst[`${name}`] = (val);
    it.advt = cst
    recalculate(it);        
  }

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Sponsorship - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="sprono" readOnly value={spro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="sproDate" name="roDate" value={new Date(spro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={spro.session} onChange={onSessionChange} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={spro.division} onChange={onDivisionChange} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Copy Old RO</label>
            <InputText disabled={disable} style={{width:'250px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={spro.agency} onChange={onVendorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="sproDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={spro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={spro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="sprono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={spro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-3 mx-6">
            <div  className="field mb-1 ">
                <label style={{width:'20%'}} className=" mr-2 mb-0">Event</label>
                <InputText  disabled={disable}  value={spro.advt.event} onChange={(e) => onInputChangeadvt(e,'event')} />
            </div>
            <div  className="field mb-1 m-auto">
                <label style={{width:'20%'}} className=" mr-2 mb-0">Venue</label>
                <InputText  disabled={disable}  value={spro.advt.venue} onChange={(e) => onInputChangeadvt(e,'venue')} />
            </div>
            <div  className="field mb-1 m-auto">
                <label style={{width:'20%'}} className="mr-2 mb-0">Date Event</label>
                <InputText  disabled={disable}  value={spro.advt.dtevent} onChange={(e) => onInputChangeadvt(e,'dtevent')} />
            </div>
            <div  className="field mb-1 m-auto">
                <label style={{width:'20%'}} htmlFor="location" className="mr-2 mb-0">Amount</label>
                <InputNumber disabled={disable}  locale="en-IN" minFractionDigits={2}  value={spro.advt.amt} onChange={(e) => onInputNumberChange(e,'amt')} />
            </div>
        </div>
        <div  className="w-4 mx-6">
            <div  className="field mb-1 m-auto">
                <label style={{width:'20%'}} className=" mr-2 mb-0">Deliverables</label>
                <InputTextarea  style={{width:'100%'}} disabled={disable} rows={7} autoComplete="off" value={spro.advt.deliverables} onChange={(e) => onInputChangeadvt(e,'deliverables')} />
            </div>
        </div>
        <div  className="w-4 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="sprono" readOnly value={spro.cost.grossamt}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="sprono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="sprono"  value={spro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="sprono" readOnly value={spro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="sprono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="sprono" readOnly value={spro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="sproDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="sprono" readOnly value={spro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={spro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={spro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={spro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={spro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={spro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {spro && <span>Are you sure you want to Cancel this RO <b>{spro.rono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apsproveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Spro);