import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const FmsubsEntry = (props) => {
    let emptyFmsub = {
        fmsub:''
    };

    const [Fmsubs, setFmsubs] = useState([]);
    const [FmsubDialog, setFmsubDialog] = useState(false);
    const [deleteFmsubDialog, setDeleteFmsubDialog] = useState(false);
    const [Fmsub, setFmsub] = useState(emptyFmsub);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
          setFmsubs(props.Fmsub);
    },[props.Fmsub]);

    const openNew = () => {
        setFmsub(emptyFmsub);
        setFmsubDialog(true);
    };

    const hideDialog = () => {
        setFmsub(emptyFmsub);
        setFmsubDialog(false);
    };

    const hideDeleteFmsubDialog = () => {
        setDeleteFmsubDialog(false);
    };

    const saveFmsub = (event) => {
        event.preventDefault();
               
        if (Fmsub.fmsub.trim()) {
            let _Fmsubs = [...Fmsubs];
            let _Fmsub = {...Fmsub};
            const index = findIndexByName(Fmsub.fmsub);
            if (index >= 0) 
            {    _Fmsubs[index] = _Fmsub;
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Fmsub Updated', life: 3000 });
            }
            else {
                _Fmsubs.push(_Fmsub);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Fmsub Created', life: 3000 });
            }
            setFmsubs(_Fmsubs); 
            props.SaveFmsubs(_Fmsubs);
            setFmsubDialog(false);
            setFmsub(emptyFmsub);
        }
    };

    const editFmsub = (Fmsub) => {
        setFmsub(Fmsub);
        setFmsubDialog(true);
    };

    const confirmDeleteFmsub = (Fmsub) => {
        setFmsub(Fmsub);
        setDeleteFmsubDialog(true);
    };

    const deleteFmsub = () => {
        let _Fmsubs = Fmsubs.filter(val => val.fmsub !== Fmsub.fmsub);
        setFmsubs(_Fmsubs);
        setDeleteFmsubDialog(false);
        setFmsub(emptyFmsub);
        props.SaveFmsubs(_Fmsubs);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Fmsub Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Fmsubs.length; i++) {
            if (Fmsubs[i].fmsub === name) {
                index = i;
                break;
            }
        }
        return index;
    };

     const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Fmsub = {...Fmsub};
        _Fmsub[`${name}`] = val;
        setFmsub(_Fmsub);
    };
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disable} label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disable && !props.receiving} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={() => editFmsub(rowData)} />
            <Button disabled={props.disable} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteFmsub(rowData)} />
            </>
        );
    };

    const deleteFmsubDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteFmsubDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteFmsub} />
        </>
    );

    return (
        <div className="itemtablepr">
            <Toast ref={toast} />

            <div className="card">
                <h3 style={{margin:'0',textAlign:'center',padding:'0'}}>List Of Sub Station</h3>
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable  resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Fmsubs} 
                    dataKey="fmsub" sortField="fmsub" sortOrder={1} paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Fmsub"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >
                    <Column field="fmsub" header="Sub Station Name" sortable style={{width:'80%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 0 0 8px'}}></Column>
                </DataTable>
            </div>
            
            <Dialog visible={FmsubDialog} style={{ width: '700px' }} header="Sub Station Details" modal className="p-fluid"  onHide={hideDialog}>
                <form onSubmit={saveFmsub} onReset={hideDialog}> 
                    <div className="field col mt-1">
                        <label htmlFor="fmsub">Sub Station Name*</label>
                        <InputText  autoComplete="off" id="fmsub" value={Fmsub.fmsub} required onChange={(e) => onInputChange(e, 'fmsub')} />
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                        </div>
                        <div className="field col">
                            <Button type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={deleteFmsubDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteFmsubDialogFooter} onHide={hideDeleteFmsubDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Fmsub && <span>Are you sure you want to delete <b>{Fmsub.fmsub}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default FmsubsEntry;