import React, { useState,useRef,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import { InputNumber } from 'primereact/inputnumber';

const Tvrodetail = (props) => {
    let emptyItem = {
        tvsub:'',
        tvproperty:'',
        spot:'',
        spotduration:'',
        tvtimeband:'',
        ssdt:props.sdt,
        sedt:props.edt,
        sdays:props.days,
        frequency:0,
        fct:0,
        paid:0,
        totalsec:0,
        rate:0,
        amt:0,
        bno:0
    };
    const [actionType, setActionType] = useState('save');
    const [Items, setItems] = useState([]);
    const [ItemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [deleteItemsDialog, setDeleteItemsDialog] = useState(false);
    const [Item, setItem] = useState(emptyItem);
    const [id, setId] = useState(1);
    const [selectedItems, setSelectedItems] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);

  
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        setItems(props.Items);
    },[props.Items]);

    const openNew = () => {
        if (props.tvsubs.length === 0) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No TV Channel Selected ?'});
            return;
        }
        setItem(emptyItem);
        setItemDialog(true);
    };

    const hideDialog = () => {
        setItem(emptyItem);
        setItemDialog(false);
    };

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false);
    };

    const hideDeleteItemsDialog = () => {
        setDeleteItemsDialog(false);
    };

    const saveItem =  (event) => {
        event.preventDefault();
        if (!Item.tvsub) {
            toast.current.show({severity: 'error', summary: 'Error Message', detail:'No Tvsub Selected ?'});
            return;
        }
        if (Item.tvsub.tvsub.trim()) {
            if (actionType === 'copy') {
                let _Items = [...Items];
                let currid = id;
                 props.tvsubs.map( e => {
                    let _Item = {...Item};
                    _Item._id = currid;
                    currid=currid+1;    
                    _Item.tvsub = e;
                    _Items.push(_Item);
                    return 1;
                })
                setId(currid)
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
                setItemDialog(false);            
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvsub Created', life: 3000 });
            }
            else {
                let _Items = [...Items];
                let _Item = {...Item};
                if (_Item._id) {
                    const index = findIndexByName(Item._id);
                    if (index >= 0) 
                    {    _Items[index] = _Item;
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TV Detail Updated', life: 3000 });
                    }
                    else {
                        _Items.push(_Item);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TV Detail Created', life: 3000 });
                    }
                } else {
                    _Item._id = id;
                    setId(_Item._id+1)
                    _Items.push(_Item);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'TV Detail Created', life: 3000 });
                }
                setItemDialog(false);            
                setItems(_Items); 
                props.SaveItems(_Items);
                setItem(emptyItem);
            }
        }
    };

    const editItem =  (e,Item) => {
        e.preventDefault();
        setItem({...Item});
        setItemDialog(true);
    };

    const confirmDeleteItem = (Item) => {
        setItem(Item);
        setDeleteItemDialog(true);
    };

    const deleteItem = () => {
        let _Items = Items.filter(val => val._id !== Item._id);
        setItems(_Items);
        setDeleteItemDialog(false);
        setItem(emptyItem);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Detail Deleted', life: 3000 });
    };

    const findIndexByName = (name) => {
        let index = -1;
        for (let i = 0; i < Items.length; i++) {
            if (Items[i]._id === name) {
                index = i;
                break;
            }
        }
        return index;
    };

    const confirmDeleteSelected = () => {
        setDeleteItemsDialog(true);
    };


    const deleteSelectedItems = () => {
        let _Items = Items.filter(val => !selectedItems.includes(val));
        setItems(_Items);
        setDeleteItemsDialog(false);
        setSelectedItems(null);
        props.SaveItems(_Items);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted Selected' , life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = e.value || 0;
        let _Item = {...Item};
        _Item[`${name}`] = val;
        _Item.totalsec = _Item.sdays * _Item.frequency * _Item.spotduration;
        _Item.paid = _Item.totalsec - _Item.fct;
        _Item.amt = _Item.rate * _Item.paid / 10;
        setItem(_Item);
    };
    const onTvsubChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.tvsub = val;
        setItem(_Item);
      };
    const onInputDateChange = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let newfmro = {...Item};
        newfmro[`${name}`] = val;
        newfmro.sdays = Math.ceil((new Date(newfmro.sedt).getTime() - new Date(newfmro.ssdt).getTime()) / (1000*60*60*24))+1;
        setItem(newfmro);
      }
      const onInputDaysChange = (e, name) => {
        const val = e.value || 0;
        let _Item = {...Item};
        _Item[`${name}`] = val;
        //_Item.sedt = new Date(new Date(_Item.ssdt).getTime()+(val*1000*60*60*24));
        setItem(_Item);
      };
    
    const leftToolbarTemplate = () => {
        return (
            <>
            <Button disabled={props.disabled} label="New" icon="pi pi-plus" className="p-button-text p-button-success mr-2" onClick={openNew} />
            <Button  label="Delete" icon="pi pi-trash" className="p-button-danger p-button-text" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length || props.disabled} />
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <>
            <Button disabled={props.disabled} icon="pi pi-pencil" className="p-button-text p-button-success mr-2 " onClick={(e) => editItem(e,rowData)} />
            <Button disabled={props.disabled} icon="pi pi-trash" className="p-button-text p-button-danger " onClick={() => confirmDeleteItem(rowData)} />
            </>
        );
    };

    const deleteItemDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteItem} />
        </>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItems} />
        </>
    );

    const onSpotChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.spot = val;
        setItem(_Item);
      };
    const onTimeBandChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.tvtimeband = val;
        setItem(_Item);
    };
    const onPropertyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        let _Item = {...Item};
        _Item.tvproperty = val;
        setItem(_Item);
    };
     
    const onFreqChange = (e) => {
        const val = e.value || 0;
        let _Item = {...Item};
        _Item.frequency = val;
        _Item.totalsec = _Item.sdays * _Item.frequency * _Item.spotduration;
        if (props.fctper && props.fctper>0) {
            _Item.fct =  Math.round((_Item.totalsec * props.fctper/100)*100)/100;
        }
        _Item.paid = _Item.totalsec - _Item.fct;
        _Item.amt = _Item.rate * _Item.paid / 10;
        setItem(_Item);
    };
    let cpy = null;
    if (Items.length === 0)
    cpy= <div className="field col">
            <Button type="submit" onClick={e => {
                            setActionType('copy');
                        
                        }} label="Save And Copy for All Tvsub" icon="pi pi-check" className="p-button-text"  />
        </div>;

    return (
        <div className="itemtable">
            <Toast ref={toast} />

            <div className="card">
                
                <Toolbar className="mt-0 p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                <DataTable disabled={props.disabled}  editMode="cell" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                    globalFilter={globalFilter} scrollable scrollHeight="261px" >

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="tvsub.tvsub" header="TV Sub" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="tvproperty.name" header="Property" sortable style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="spot" header="Spot" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="spotduration" header="Duration" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="tvtimeband.name" header="Time Band" sortable estyle={{width:'20%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="frequency" header="Frequency"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="fct" header="FCT" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="paid" header="Paid" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="totalsec" header="Total" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="rate" header="Rate"   style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column field="amt"  header="Amount"   style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
                    <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
                </DataTable>
            </div>
            

            <Dialog visible={ItemDialog} style={{ width: '700px' }} header="Advertisement Details" modal className="p-fluid"  onHide={hideDialog}>
               <form onSubmit={saveItem} onReset={hideDialog}> 
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-2 mr-2 mb-0">TV Sub Stn</label>
                    <Dropdown filter filterBy='tvsub' placeholder="Select Tvsub"className="w-auto" optionLabel="tvsub" options={props.tvsubs} value={Item.tvsub} onChange={onTvsubChange} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-2 mr-2 mb-0">Property</label>
                    <Dropdown  filter filterBy='name' placeholder="Select Property"style={{width:'300px'}} optionLabel="name" options={props.tvpropertys} value={Item.tvproperty} onChange={onPropertyChange} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-2 mr-2 mb-0">Spot</label>
                    <InputText style={{width:'300px'}}className=""  htmlFor="fmrono"  value={Item.spot} onChange={onSpotChange}/>
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Spot Duration</label>
                    <InputNumber locale="en-IN"  className="w-auto"  value={Item.spotduration} onChange={(e) => onInputChange(e,'spotduration')} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Time Band</label>
                    <Dropdown  filter filterBy='name' placeholder="Select Time Band"style={{width:'300px'}} optionLabel="name" options={props.timebands} value={Item.tvtimeband} onChange={onTimeBandChange} />
                </div>
                <div className="flex justify-content-center mt-1">
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label  className="w-8 mr-2 mb-0" >Start Date</label>
                    <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.ssdt)} onChange={(e) => onInputDateChange(e, 'ssdt')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-8 mr-2 mb-0">End Date</label>
                    <Calendar  style={{}} monthNavigator yearNavigator yearRange="2010:2030"  value={new Date(Item.sedt)} onChange={(e) => onInputDateChange(e, 'sedt')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-8 mr-2 mb-0" >No of Days</label>
                    <InputNumber style={{}}  onChange={(e) => onInputDaysChange(e, 'sdays')} value={Item.sdays}/>
                </div>
                </div>

                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Frequency</label>
                    <InputNumber locale="en-IN" className="w-auto"  value={Item.frequency} onChange={onFreqChange} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">FCT</label>
                    <InputNumber locale="en-IN"  className="w-auto"  value={Item.fct} onChange={(e) => onInputChange(e,'fct')} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Paid</label>
                    <InputNumber locale="en-IN" className="w-auto"  value={Item.paid} readOnly />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Total Secs</label>
                    <InputNumber locale="en-IN" className="w-auto"  value={Item.totalsec} readOnly />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                    <label className="w-2 mr-2 mb-0">Rate/10Sec</label>
                    <InputNumber locale="en-IN" minFractionDigits={2}  className="w-auto"  value={Item.rate} onChange={(e) => onInputChange(e,'rate')} />
                </div>
                <div  className="field grid mb-1 ml-4 m-auto">
                  <label className="w-2 mr-2 mb-0">Amount</label>
                  <InputNumber locale="en-IN" minFractionDigits={2}  className="w-auto"  value={Item.amt} readOnly/>
              </div>
            <div className="formgrid grid mt-5">
                    <div className="field col">
                        <Button type="reset" label="Cancel" icon="pi pi-times" className="p-button-text"  />
                    </div>
                    {cpy}
                    <div className="field col">
                        <Button onClick={e => {
                            setActionType('save');
                        
                        }} type="submit" label="Save" icon="pi pi-check" className="p-button-text"  />
                    </div>
               </div>
               </form>
            </Dialog>

            <Dialog visible={deleteItemDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete <b>{Item.itemname}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteItemsDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteItemsDialogFooter} onHide={hideDeleteItemsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                    {Item && <span>Are you sure you want to delete the selected Items?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default Tvrodetail;


