import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import Navbar from '../navbar'
import logo from "../../assets/allen.png";
import media from "../../assets/advt.png";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
//import Fatable from "./fatable"
import styles from './media.module.css'
import * as actions from '../../store/actions/index'


const Mediapage = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const onSignout = () => {
      props.onlogout();
    }
    return (
      <div className={styles.Usr}>
        <Sidebar className="flex align-items-center " visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
          <h2>User Profile....</h2>
          <h5>Emp/User ID : {props.empid}</h5>
          <h5>Name        : {props.username}</h5>
          <h5>Designation : {props.desig}</h5>
          <h5>Role        : {props.role}</h5>
          <Button className="p-button-text ml-4" style={{}} onClick={onSignout}>Sign Out</Button>
        </Sidebar>
        <div className={styles.Headerusr}>
        <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
          <h2 style={{marginTop:'0', textAlign:'center'}}>Media & Marketing</h2>
          <div className={styles.Userusr} onClick={(e) => setVisibleRight(true)}>
            <i style={{'fontSize': '1.5em'}} className="pi pi-user mr-6 mt-2 text-right" ></i>
            <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
            <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
          </div>
        </div>
        <Navbar/>
        <img src={media} alt="Allen" height={400} width={300} style={{display: 'block', marginTop:'30px', marginBottom:'auto', height:'70%',marginLeft: 'auto', marginRight: 'auto',width: '50%'}} ></img>
      </div>
    );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      premises:state.auth.premises,
      role:state.auth.role,
      empid:state.auth.empid,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin,
      desig:state.auth.desig
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onlogout:() => dispatch(actions.logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mediapage);