import React, { useState,useRef,useEffect } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { BlockUI } from 'primereact/blockui';
import Navbar from '../../navbar'
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import './bill.css'

import { useHistory } from "react-router-dom";

const Billapprove = (props) => {

  const history = useHistory()
  const [Items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [frDate, setfrDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [approveItemDialog, setApproveItemDialog] = useState(false);
  const [approveItemsDialog, setApproveItemsDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const [saved,setSaved] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);
  const [selectedStatuses, setSelectedStatuses] = useState(['Submitted']);

  const statuses = [
    "Submitted",
    "Approved",
    "Rejected",
    "Despatched"
  ];

  useEffect(() => {
    const i = props.permissions.find(x => (x.name === 'PM-BILLAPPROVE'))
    if (props.isAdmin || i) {
      axios.get('/bill/robillapr/PM', {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setItems([]);
      });
      } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised for Bill Approvals', life: 3000 }); 
  }, [props.storeid,props.isAdmin,props.permissions]);

  const billsearch = (event) => {
      event.preventDefault();  
      if ((frDate === null || toDate === null) && selectedStatuses.length===0) {
        return;
      }
    // const s = frDate.getFullYear()+"-"+((frDate.getMonth()+1) <10?"0"+(frDate.getMonth()+1):(frDate.getMonth()+1))+"-"+frDate.getDate()
    // const s1 = toDate.getFullYear()+"-"+((toDate.getMonth()+1) <10?"0"+(toDate.getMonth()+1):(toDate.getMonth()+1))+"-"+toDate.getDate() 
     let authOptions = {
      method: 'POST',
      url: '/bill/robillsearch/PM', //+ props.storeid, 
      data: {'sdt':frDate, 'edt':toDate, 'sts': selectedStatuses},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
    .then(response => {
      setItems(response.data);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    });
  }

  const formatDate = (dt) => {
    let cdt = new Date(dt);
    let month = cdt.getMonth() + 1;
    let day = cdt.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return day+"-"+month+"-"+cdt.getFullYear() ;
  };

  const hideApproveItemDialog = () => {
    setApproveItemDialog(false);
  };

    const hideApproveItemsDialog = () => {
    setApproveItemsDialog(false);
  };
  const confirmApproveItem = (Item) => {
    setSelectedItem(Item);
    setApproveItemDialog(true);
  };

  const confirmApproveSelected = () => {
    setApproveItemsDialog(true);
  };

  const billApprove = (event) => {
    event.preventDefault();
    billApproveReject('Approved');
  }
  const billReject = (event) => {
    event.preventDefault();
    billApproveReject('Rejected');
  }
  const billsApprove = (event) => {
    event.preventDefault();
    billsApproveReject('Approved');
  }
  const billsReject = (event) => {
    event.preventDefault();
    billsApproveReject('Rejected');
  }

  const billApproveReject = (type) => {  
    if (selectedItem.finalstatus === "Submitted") {
      setBlockedDocument(true);
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate: new Date()
      }
      let authOptions = {
        method: 'PUT',
        url: '/bill/approve/pm', 
        data: {'status':status, 'bill':selectedItem, 'action':type === 'Rejected'?'Draft':type, 'storeid':props.storeid},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Approved', life: 3000 });
        setBlockedDocument(false);
        setApproveItemDialog(false);
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setBlockedDocument(false);
        setApproveItemDialog(false);
        setSaved(true);
      });
    } 
  }

  const billsApproveReject = (type) => {  
    if (selectedItems.length >0 ) {
      setBlockedDocument(true);
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate: new Date()
      }
      let authOptions = ""
      for (let i=0;i<selectedItems.length;i++) {
        authOptions = {
          method: 'PUT',
          url: '/bill/approve/pm', 
          data: {'status':status, 'bill':selectedItems[i], 'action':type === 'Rejected'?'Draft':type, 'storeid':props.storeid},
          headers : {
              'x-auth-token' : localStorage.getItem('token')
          }
        };
        axios(authOptions)
        .then(response => {
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
      }
      setBlockedDocument(false);
      setApproveItemsDialog(false);
      setSaved(true);
    } 
  }

  const approveItemDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveItemDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={billApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={billReject} />
    </>
  );
  const approveItemsDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveItemsDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={billsApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={billsReject} />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
        <Button  label="Approve Selected" icon="pi pi-check" className="p-button-success p-button-rounded p-button-raised" onClick={confirmApproveSelected} disabled={!selectedItems || !selectedItems.length} />
      );
  };

  const rightToolbarTemplate = () => {
      return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      )
  }

  const recddateBodyTemplate = (rowData) => formatDate(rowData.recddate);
  const billdateBodyTemplate = (rowData) => formatDate(rowData.billdate);

  const actionBodyTemplate = (rowData) => 
    <Button icon="pi pi-check" className="p-button-rounded p-button-text p-button-primary mr-2 " onClick={() => confirmApproveItem(rowData)} />;
  
    if (saved)  {
      const i = props.permissions.find(x => (x.name === 'PM-BILLAPPROVE'))
      if (props.isAdmin || i) {
        axios.get('/bill/robillapr/PM', {headers : {
          'x-auth-token' : localStorage.getItem('token')
        }})
        .then(response => {
          setItems(response.data);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
          setItems([]);
        });
        } else 
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised for Bill Approvals', life: 3000 }); 
  
    }

  return (
    <div style={{fontSize: 'small'}} className="BILL">
      <BlockUI blocked={blockedDocument} fullScreen />

      <div style={{height:'auto'}} className="Headerbill">
        <img src={logo} onClick={() => history.push('/MEDIA')} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
        <h2 style={{marginTop:'5px', textAlign:'center'}}>Media Module - Newspaper<br/>Newspaper Bills Approval</h2>
        <div className="Userbill">
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
          <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
        </div>
      </div>
      <Navbar/>

      <div style={{ width:'90%',height: '8vh', margin: '0 auto', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center'}} className=" h-auto mt-2">
        <div className="">
          <label htmlFor="frDate" className="mr-4 h-2rem">Date-From</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="frDate" className="h-2rem" value={frDate} onChange={(e) => setfrDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <label htmlFor="toDate" className="mx-4 h-2rem">Date-To</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" id="toDate" className="h-2rem" value={toDate} minDate={frDate} onChange={(e) => settoDate(e.target.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
        </div>
        <div className=" ">
          <MultiSelect className="ml-4 my-1 h-auto" value={selectedStatuses} options={statuses} onChange={(e) => setSelectedStatuses(e.value)}  placeholder="Select Status" />
        </div>
        <Button icon="pi pi-search" className="ml-2 p-button-text p-button-success" onClick={billsearch}/>
      </div>
      <div className="">
        <Toast ref={toast} />
        <div className="w-11 m-auto card h-auto">
            <Toolbar className="p-1" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
            <DataTable  selectionMode="multiple" sortField="ubn" sortOrder={-1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Items} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                dataKey="ubn" paginator rows={10} rowsPerPageOptions={[10, 20, 25, 30]} style={{padding:'0px',fontSize:'14px'}}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
                globalFilter={globalFilter} scrollable scrollHeight="400px" >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="bno" header="Bill No" sortable style={{textAlign:'center',width:'12%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="billdate" header="Bill Dt" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}} body={billdateBodyTemplate} ></Column>
                <Column field="vendor.name" header="Agency" sortable style={{textAlign:'center',width:'20%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="ro.media.0.newspaper.name" header="Newspaper" sortable style={{textAlign:'center',width:'12%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="rono" header="RO No" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}}  ></Column>
                <Column field="netamt" header="Net Amt" sortable style={{textAlign:'center',width:'10%',padding:'0 2px 0 2px'}} ></Column>
                <Column field="recddate" header="Recd Dt" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}} body={recddateBodyTemplate}></Column>
                <Column field="ubn" header="UBN" sortable style={{textAlign:'center', width:'6%',padding:'0 2px 0 2px'}}></Column>
                <Column field="finalstatus" header="Status" sortable style={{textAlign:'center',width:'8%',padding:'0 2px 0 2px'}}></Column>
                <Column body={actionBodyTemplate} style={{padding:'0 2px 0 8px'}}></Column>
            </DataTable>
            <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
              <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
              <Button  label="Approve Selected" icon="pi pi-check" className="p-button-success p-button-rounded p-button-raised" onClick={confirmApproveSelected} disabled={!selectedItems || !selectedItems.length} />
           </div>
        </div>
        <Dialog visible={approveItemDialog} style={{ width: '490px' }} header="Confirm" modal footer={approveItemDialogFooter} onHide={hideApproveItemDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
            {selectedItem && <span>Are you sure you want to approve <b>{selectedItem.ubn}</b>?</span>}
          </div>
        </Dialog>

        <Dialog visible={approveItemsDialog} style={{ width: '490px' }} header="Confirm" modal footer={approveItemsDialogFooter} onHide={hideApproveItemsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
            {selectedItems && <span>Are you sure you want to approve the selected Bills?</span>}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      username:state.auth.username,
      unitname:state.auth.unitname,
      unitid:state.auth.unitid,
      userid:state.auth.userid,
      empid:state.auth.empid,
      premises:state.auth.premises,
      role:state.auth.role,
      permissions:state.auth.permissions,
      isAuth:state.auth.token!==null,
      isAdmin:state.auth.isAdmin,
      storeid:state.auth.storeid
  }
}

export default connect(mapStateToProps)(Billapprove);