import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import styles from './tvproperty.module.css';
import { useHistory } from "react-router-dom";
const Tvpropertytable = (props) => {
    const history = useHistory();
    let emptyTvproperty = {
        name: '',
    };

    const [tvproperty, setTvproperty] = useState(emptyTvproperty);
    const [tvpropertys, setTvpropertys] = useState(null);
    const [tvpropertyDialog, setTvpropertyDialog] = useState(false);
    const [deleteTvpropertyDialog, setDeleteTvpropertyDialog] = useState(false);
    const [mode,setMode] = useState('');


    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        let authOptions = {
            method: 'GET',
            url: '/tvproperty/all',
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
        .then(response => {
            setTvpropertys(response.data)
        })
        .catch(error => {
            toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            setTvpropertys([]);
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps


    const openNew = () => {
        setTvproperty(emptyTvproperty);
        setSubmitted(false);
        setMode('New');
        setTvpropertyDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTvpropertyDialog(false);
    }

    const hideDeleteTvpropertyDialog = () => {
        setDeleteTvpropertyDialog(false);
    }

    const saveTvproperty = () => {
        setSubmitted(true);

        if (tvproperty.name.trim()) {
            let _tvpropertys = [...tvpropertys];
            let _tvproperty = {...tvproperty};
            if (mode === 'Edit') {
                const index = findIndexById(tvproperty._id);
                _tvpropertys[index] = _tvproperty;
                let authOptions = {
                  method: 'PUT',
                  url: '/tvproperty/', 
                  data: _tvproperty,
                  headers : {
                      'x-auth-token' : localStorage.getItem('token')
                  }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvproperty Edited', life: 3000 });
                    setTvpropertys(_tvpropertys);
                    setTvpropertyDialog(false);
                    setTvproperty(emptyTvproperty);
                })
                  .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
            else {
                let authOptions = {
                    method: 'POST',
                    url: '/tvproperty/', 
                    data: _tvproperty,
                    headers : {
                        'x-auth-token' : localStorage.getItem('token')
                    }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvproperty Created', life: 3000 });
                     _tvpropertys.push(response.data);
                     setTvpropertys(_tvpropertys);
                     setTvpropertyDialog(false);
                     setTvproperty(emptyTvproperty);
                })
                    .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
        }
    }

    const editTvproperty = (tvproperty) => {
        setTvproperty({...tvproperty});
        setMode('Edit');
        setTvpropertyDialog(true);
    }

    const confirmDeleteTvproperty = (tvproperty) => {
        setTvproperty(tvproperty);
        setDeleteTvpropertyDialog(true);
    }

    const deleteTvproperty = () => {
        let authOptions = {
            method: 'delete',
            url: '/tvproperty/', 
            data: tvproperty,
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
          };
          axios(authOptions)
            .then(response => {
                setDeleteTvpropertyDialog(false);
                let _tvpropertys = tvpropertys.filter(val => val.name !== tvproperty.name);
                setTvpropertys(_tvpropertys);
                setTvproperty(emptyTvproperty);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tvproperty Deleted', life: 3000 });
            })
            .catch(error => {
              toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < tvpropertys.length; i++) {
            if (tvpropertys[i]._id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tvproperty = {...tvproperty};
        _tvproperty[`${name}`] = val;
        setTvproperty(_tvproperty);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success p-mr-2" onClick={() => editTvproperty(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-warning" onClick={() => confirmDeleteTvproperty(rowData)} />
           </React.Fragment>
        );
    }

    const header = (
        <div className={styles.tableheader}>
            <h4 className="mx-0 my-1">Manage TV Property</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const tvpropertyDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveTvproperty} />
        </React.Fragment>
    );
    const deleteTvpropertyDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTvpropertyDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTvproperty} />
        </React.Fragment>
    );

    return (
        <div className={styles.PER}>
            <Toast ref={toast} />
            <div className={styles.Headerper}>
                <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
                <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>TV Property Management</h2>
                <div className={styles.Userper}>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
                </div>
            </div>
            <div className={styles.datatablecruddemo} style={{width:'60%', margin:'auto',top:'-30vh',position: 'relative'}}>
                <div className={styles.card}>
                    <Toolbar className="mb-0" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable sortField="name" sortOrder={1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={tvpropertys} 
                        dataKey="name" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tvpropertys"
                        globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                        <Column field="name" header="TV Property Name" sortable style={{ width:'80%',minWidth: '16rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>
                </div>
                <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
                    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
                </div>
            </div>
            <Dialog visible={tvpropertyDialog} style={{ width: '450px' }} header="Tvproperty Details" modal className="p-fluid" footer={tvpropertyDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">TV Property Name*</label>
                    <InputText id="name" value={tvproperty.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !tvproperty.name })} />
                    {submitted && !tvproperty.name && <small className="p-error">Name is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteTvpropertyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTvpropertyDialogFooter} onHide={hideDeleteTvpropertyDialog}>
                <div className={styles.confirmationcontent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {tvproperty && <span>Are you sure you want to delete <b>{tvproperty.name}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin,
        storeid:state.auth.storeid
    }
  }
  
  export default connect(mapStateToProps)(Tvpropertytable);