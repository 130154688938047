import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Prodetail from "./prodetail";

import Statuslog from "../../statuslog"
import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Proentry = (props) => {
  const [sessions,setsessions] = useState([]);
  let proinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    centres:[],
    costalloc:[],
    isSpaceIncentive:false,
    siamt:null,
    fixedRate:null,
    media:[],
    caption:'',
    color:'COLOR',
    unit:'',
    height:'',
    width:'',
    page:'',
    rate:0,
    tsize:0,
    amt:0,
    advt:[],
    cost: {
      grossamt:0,
      pdiscper:0,
      pdisc:0,
      addldiscper:0,
      addldisc:0,
      agencydiscper:0,
      agencydisc:0,
      gstper:5,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bstate:[],
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };
  const pagelist = [
    {nm:'Full Page', h:52, w:33},
    {nm:'Half-Ver', h:52, w:16},
    {nm:'Half-Hor', h:25, w:33},
    {nm:'Qtr', h:25, w:16},
    {nm:'SemiQtr',h:20, w:12},
    {nm:'JktFull', h:45, w:33},
    {nm:'other',h:'',w:''}
];
const sizeunits = ['Sq.Cm', 'Col.Cm'];

  const advcolor = ['B&W', 'COLOR']

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [pro,setpro] = useState(proinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [medias,setmedias] = useState([]);
  const [selmedia,setselmedia] = useState([]);
  const [editions,seteditions] = useState([]);
  const [newspapers, setNewspapers] = useState([]);
  const [selnewspaper, setselNewspaper] = useState(null);
  const [oldRo,setOldRo] = useState('');
  let i;
  if (!props.isAdmin) {
    i = props.permissions.find(x => x.name === 'PM-VIEWWITHOUTRATE');
  }
  let disable=true;
  if ((pro.finalstatus === 'Submitted' || pro.finalstatus === 'Draft' || pro.finalstatus === 'Review' || history.location.state.mode === 'New') && !i)
  {
   disable=false;
  }

  const recalculate = (newproa) => {
    let tnet=0;
    const newpro = {...newproa.cost};
    if (newproa.isSpaceIncentive) {
      newpro.grossamt=0;
      newpro.pdiscper=0;
      newpro.pdisc=0;
      newpro.addldisc=0;
      newpro.addldiscper=0;
      newpro.agencydiscpro=0;
      newpro.agencydisc=0;
      //newpro.gstper=0;
      newpro.gst=0;
      newpro.roundoff=0;
      newpro.netamt=0;
    }
    else
    {
      newpro.grossamt = newproa.amt;
      //newpro.grossamt = newproa.advt.map(item => item.amt).reduce((acc,curr)=>acc+curr, 0);
      if (newproa.fixedRate > 0) newpro.grossamt = newproa.fixedRate;
      tnet = newpro.grossamt;
      newpro.pdisc = Math.round((tnet * newpro.pdiscper/100)*100)/100;
      tnet = tnet + newpro.pdisc;
      newpro.addldisc = Math.round((tnet * newpro.addldiscper/100)*100)/100;
      tnet = tnet - newpro.addldisc;
      newpro.agencydisc = Math.round((tnet * newpro.agencydiscper/100)*100)/100;
      tnet = tnet - newpro.agencydisc;
      newpro.gst = Math.round((tnet * newpro.gstper/100)*100)/100;
      tnet = tnet + newpro.gst;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newpro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newpro.roundoff = Math.round(-roff*100)/100;
      }
      newpro.netamt = tnet;
      if (newproa.agency.dtype === 'Unregistered' ) {
        tnet = newpro.netamt-newpro.gst;
        newpro.gst=0;
        let roff = tnet - parseInt(tnet);
        if (roff>0.50) {
          tnet = parseInt(tnet)+1;
          newpro.roundoff = Math.round((1-roff)*100)/100;
        }
        else {
          tnet = parseInt(tnet);
          newpro.roundoff = Math.round(-roff*100)/100;
        }
          newpro.netamt=tnet;
      }
    }
    newproa.cost = newpro;
    if (newproa.isSpaceIncentive || newproa.fixedRate>0) {
      const items = newproa.advt.map((x) => {
        return {...x, rate:0, amt:0};
      });
      newproa.advt = items;
    }
    setpro(newproa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/newspaper/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      const newspaper = response.data;
      setNewspapers(newspaper);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'a. Error Message', detail: error.response.data});
    });
  },[]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      let authOptions = {
        method: 'PUT',
        url: '/media/np',
        data: {'npid':history.location.state.prodata.media[0].newspaper._id},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setmedias(response.data);
        setselNewspaper(history.location.state.prodata.media[0].newspaper);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setmedias([]);
      });
    } else {
      // axios.get('/printmedia/lastDate', {headers : {
      //   'x-auth-token' : localStorage.getItem('token')
      // }})
      // .then(response => {
      //   setNewDate(new Date(response.data.lastDate));
      // })
      // .catch(error => {
      //   toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      // });
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
      }
  },[history.location.state.mode,history.location.state.prodata]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newpro = {...history.location.state.prodata};
      const ed =medias.filter(n => history.location.state.prodata.media.some(n2 => n.name === n2.name));//medias.find(e => e._id === history.location.state.prodata.media._id);
      let final=[];
      const final1 = history.location.state.prodata.media.map(e => final.concat(e.editions));
      final = [].concat(...final1);
      const newadvt = newpro.advt.map(ea => ({ ...ea, edition : final.find(e => e._id === ea.edition)}))
      newpro.advt = newadvt;
      const selcentres = units.filter((el) => {
        return newpro.bstate.some((f) => {
          return f === el.state;
        });
      });
      setselUnits(selcentres!==null?selcentres:'');
      setselmedia(ed);
      seteditions(final);
      newpro.page={'nm':newpro.page,'h':newpro.page==='other'?'':newpro.height,'w':newpro.page==='other'?'':newpro.width};
      setpro(newpro);
    }
  },[history.location.state.mode,history.location.state.prodata,medias,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newpro = {...pro};
    newpro[`${name}`] = val;
    setpro(newpro);
  }

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newpro = {...pro}
    let cst = {...newpro.cost}
    cst[`${name}`] = (val);
    newpro.cost = cst
    recalculate(newpro);
    //setpro(newpro);
  }

  // const proDraft = (event) => {
  //   event.preventDefault();
  //   if (!pro.agency) {
  //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
  //     return;
  //   };
  //   if (!pro.media) {
  //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Media', life: 3000 });
  //     return;
  //   };
  //   if (!pro.centres) {
  //     toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Centre/s', life: 3000 });
  //     return;
  //   };
  //   if (history.location.state.mode === 'New') {
  //     let newpro = {...pro}
  //     newpro.finalstatus = 'Draft';
  //     let authOptions = {
  //       method: 'POST',
  //       url: '/printmedia/', 
  //       data: newpro,
  //       headers : {
  //           'x-auth-token' : localStorage.getItem('token')
  //       }
  //     };
  //     axios(authOptions)
  //       .then(response => {
  //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Draft Saved', life: 3000 });
  //         setSaved(true);
  //       })
  //       .catch(error => {
  //         toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
  //       });
  //   }
  //   else
  //   {
  //     let newpro = {...pro}
  //     newpro.finalstatus = 'Draft';
  //     let authOptions = {
  //       method: 'PUT',
  //       url: '/printmedia/', 
  //       data: newpro,
  //       headers : {
  //           'x-auth-token' : localStorage.getItem('token')
  //       }
  //     };
  //     axios(authOptions)
  //       .then(response => {
  //         toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Draft Edited', life: 3000 });
  //         setSaved(true);
  //       })
  //       .catch(error => {
  //         toast.current.show({severity: 'error', summary: 'Error Message', detail: error.respronse.data});
  //       });
  //   }
  // }

  const proSave = (event) => {
    event.preventDefault();
    if (!pro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newpro = {...pro};
    newpro.page=newpro.page.nm;
    if (pro.session === "" || !pro.session) {
      const xx =sessions.splice(-1);
      newpro.session = xx[0];
      //toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Session', life: 3000 });
      //return;
    };
    if (pro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!pro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!pro.media) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Print Media', life: 3000 });
      return;
    };
    if (pro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!pro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!pro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (pro.isSpaceIncentive || pro.fixedRate>0) {
      const items = newpro.advt.map((x) => {
        return {...x, rate:0, amt:0};
      });
      newpro.advt = items;
    }
    let final=[];
    const final2 = newpro.media.map(e => final.concat(e.centres));
    //let pcentres = [...newpro.costalloc]
    const pcentres = [].concat(...final2);
    newpro.costalloc = pcentres;   
  
    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newpro.status.push(status);
      const advts = newpro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newpro.advt = advts;
      newpro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/printmedia/', 
        data: newpro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newpro.status.push(status);
      const advts = newpro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newpro.advt = advts;
      newpro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/printmedia/', 
        data: newpro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

// Delete
/*  const prDelete = (event) => {
    event.preventDefault();
    if (qi.finalstatus === "Draft") {
      axios.delete('/requisition/'+qi.prno, {headers : {
        'x-auth-token' : localStorage.getItem('token')
      }})
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'pr Deleted', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    }
  }
  */
  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const proCancel = (event) => {
    event.preventDefault();
    proApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={proCancel} />
    </>
  );

  //Approve
  const proApprove = (event) => {
    event.preventDefault();
    
    proApproveReject('Approved');
  }
  const proReject = (event) => {
    event.preventDefault();
    proApproveReject('Rejected');
  }
  const proApproveReject = (type) => {  
    
    if (pro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/printmedia/approve/', 
        data: {'rono':pro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'PM-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const approveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={proApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={proReject} />
    </>
  );

  if (saved)  history.goBack();

  const onVendorChange = (e) => {
    const pronew = {...pro};
    pronew.agency = e.value;
    pronew.bunit = units.find(x => x.name === 'KOTA-HO');
    pronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+pronew.bunit.address+"\n"+pronew.bunit.city+"-"+pronew.bunit.pincode+" "+pronew.bunit.state+"\nGSTIN:"+pronew.bunit.gstin;
    setpro(pronew);
  };
 const onColorChange = (e) => {
   const pronew = {...pro};
   pronew.color = e.value;
   //recalculate(pronew);    
   setpro(pronew);
 };
 const onCaptionChange = (e) => {
  const pronew = {...pro};
  pronew.caption = e.value;
  //recalculate(pronew); 
  setpro(pronew);
};
const onSessionChange = (e) => {
  e.preventDefault();
  const pronew = {...pro};
  pronew.session = e.value;
  //recalculate(pronew);    
  setpro(pronew);
};
const onDivisionChange = (e) => {
  const pronew = {...pro};
  pronew.division = e.value;
  //recalculate(pronew);    
  setpro(pronew);
};
 const onMediaChange = (e) => {
  const pronew = {...pro};
  pronew.media = e.value;
  let final=[];
  const final1 = e.value.map(e => final.concat(e.editions));
  seteditions([].concat(...final1));
  setselmedia(e.value);
  //pronew.centres = e.target.value.centres;
  final=[];
  const final2 = e.value.map(e => final.concat(e.centres));
  let pcentres = [...pronew.costalloc]
  pcentres = [].concat(...final2);
  pronew.costalloc = pcentres;   
  setpro(pronew);
};
// const onEditionChange = (e) => {
//   const pronew = {...pro};
//   pronew.advt.edition = e.value;
//   pronew.advt.media = selmedia;
//   //recalculate(pronew);    
//   setpro(pronew);
// };
const onCentreChange = (e) => {
  const pronew = {...pro};
  pronew.centres = e.value;
  // e.value.map(e1 => { 
  //   const f = pronew.datealloc.find(e2 => e2.cname === e1.name);
  //   if (!f) pronew.datealloc.push({'unit':e1._id, 'cname':e1.name, 'dop':pronew.datealloc.length===0?new Date().toString():pronew.datealloc[0].dop, 'alloc':0})
  //   return true;
  // })
  // let data = pronew.datealloc.filter(item => e.value.findIndex(f => f.name === item.cname)+1);
  // //recalculate(pronew); 
  // pronew.datealloc = data;   
  setpro(pronew);
};

const onSIChange  = (e) => {
  e.preventDefault();
  const pronew = {...pro};
  if (e.checked) 
    pronew.isSpaceIncentive = true;
  else
    pronew.isSpaceIncentive = false;
    recalculate(pronew);    
  setpro(pronew);
};

const onFRChange  = (e) => {
  let val = (e.value || 0);
  let newpro = {...pro}
  newpro.fixedRate = val
  recalculate(newpro);
  setpro(newpro);
};
const onSIAmtChange  = (e) => {
  let val = (e.value || 0);
  let newpro = {...pro}
  newpro.siamt = val
  setpro(newpro);
};

const onNewspaperChange  = (e) => {
  e.preventDefault();
  setselNewspaper(e.value);
  let authOptions = {
    method: 'PUT',
    url: '/media/np',
    data: {'npid':e.value._id},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
    setmedias(response.data);
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setmedias([]);
  });
};

const onStateChange  = (e) => {
  e.preventDefault();
  const pronew = {...pro};
  pronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });
  setselUnits(selcentres!==null?selcentres:'');
  //setselUnits(units.filter(es => es.state === e.value));
  setpro(pronew);
};
const onBunitChange = (e) => {
  const pronew = {...pro};
  pronew.bunit = e.target.value;
  pronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setpro(pronew);
}

const saveItems = (i) => {
  let newpro = {...pro};
  newpro.advt = i;
  recalculate(newpro);
  //setpro(newpro);
}
const onInputChangePage = (e, name) => {
  const val = (e.target && e.target.value) || '';
  let _Item = {...pro};
  _Item[`${name}`] = val;
  _Item.unit = "Sq.Cm"
  _Item.height = val.h;
  _Item.width = val.w;
  _Item.tsize = _Item.height * _Item.width;
  _Item.amt = _Item.tsize * _Item.rate;
  recalculate(_Item);
};

const onInputNumberChange = (e, name) => {
  const val = e.value || 0;
  let _Item = {...pro};
  _Item[`${name}`] = val;
  _Item.tsize = _Item.height * _Item.width;
  _Item.amt = _Item.tsize * _Item.rate;
  recalculate(_Item);
};

// const footer = (<div style={{display:'flex', flexDirection: 'column', alignItems:'end', marginRight:'0'}}>
//     <div className=" field flex mb-0">
//       <label htmlFor="gst" style={{width:'150px',paddingLeft:'100px', marginBottom:'0px'}}>G.S.T.</label>
//       <InputNumber  name='gst' id='gst' inputStyle={{width:'150px', textAlign:'right'}} placeholder='GST' value={pro.gst} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'gst')}/>
//     </div>
//     <div className=" field flex mb-0">
//       <label htmlFor="net" style={{width:'150px', paddingLeft:'50px', marginBottom:'0px'}}>Net Amount</label>
//       <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  placeholder='NetAmt' value={pro.netamt} mode="decimal" locale="en-IN" minFractionDigits={2}/>
//     </div>
//   </div>
// );
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}

const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/printmedia/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newfmro = response.data;
      newfmro.rono='';
      newfmro.rodate = new Date();
      newfmro.status = []
      let authOptions = {
        method: 'PUT',
        url: '/media/np',
        data: {'npid':newfmro.media[0].newspaper._id},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        const newpro = newfmro;
        const ed =response.data.filter(n => newpro.media.some(n2 => n.name === n2.name));//medias.find(e => e._id === history.location.state.prodata.media._id);
        let final=[];
        const final1 = newpro.media.map(e => final.concat(e.editions));
        final = [].concat(...final1);
        const newadvt = newpro.advt.map(ea => ({ ...ea, edition : final.find(e => e._id === ea.edition)}))
        newpro.advt = newadvt;
        const selcentres = units.filter((el) => {
          return newpro.bstate.some((f) => {
            return f === el.state;
          });
        });
        setmedias(response.data);
        setselNewspaper(newfmro.media[0].newspaper);
        setselUnits(selcentres!==null?selcentres:'');
        setselmedia(ed);
        seteditions(final);
        newpro.page={'nm':newpro.page,'h':newpro.page==='other'?'':newpro.height,'w':newpro.page==='other'?'':newpro.width};
        setpro(newpro);
  
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setmedias([]);
      });
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message...', detail: error.response.data});
    setpro(proinit);
  });
}




  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={proSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && pro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={proSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (pro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={proSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  // const formatDate = (dt) => {
  //   let cdt = new Date(dt);
  //   let month = cdt.getMonth() + 1;
  //   let day = cdt.getDate();
  //   if (month < 10) {
  //       month = '0' + month;
  //   }
  //   if (day < 10) {
  //       day = '0' + day;
  //   }
  //   return day+"-"+month+"-"+cdt.getFullYear() ;
  // };
//   const dateBodyTemplate = (rowData) => {
//     return formatDate(rowData.dop);
// };
  let Ratedisplay = <InputNumber disabled={disable} inputStyle={{width:'100%'}} mode="decimal" minFractionDigits={2} maxFractionDigits={8} value={pro.rate} onValueChange={(e) => onInputNumberChange(e,'rate')}/>;
    if (i) {
      Ratedisplay=null;      
    }
  

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Print - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
        <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-3 ml-1">RO_No</label>
              <InputText style={{width:'140px'}}className="" disabled={disable}  htmlFor="fmrono" readOnly value={pro.rono}/>
            </div>
            <div  className="field grid mb-1 ml-2">
              <label htmlFor="rro" className=" mr-1 ml-2 mb-0">Copy RO</label>
              <InputText disabled={disable} style={{width:'140px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="ml-1 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}} showOnFocus={true} minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="fmroDate" name="roDate" value={new Date(pro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy"  />
            </div>
            <div  className="field grid mb-1">
              <label htmlFor="session" className="ml-4 mr-2 mb-0">Session</label>
              <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'145px'}} id="session"  options={sessions} value={pro.session} onChange={onSessionChange} />
            </div>
          </div>
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1">
              <label htmlFor="division" className="ml-1 mr-2 mb-0">Division</label>
              <Dropdown disabled={disable} filter filterBy='name' style={{width:'350px'}} placeholder="Select Division" id="division"  options={divisions} value={pro.division} onChange={onDivisionChange} />
            </div>
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="project" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={pro.agency} onChange={onVendorChange} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="np" className="w-2 mr-2 mb-0">NewsPaper</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Newspaper"style={{width:'300px'}} id="np" optionLabel="name"  options={newspapers} value={selnewspaper} onChange={onNewspaperChange} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="media" className="w-2 mr-2 mb-0">Main Edition</label>
            <MultiSelect 
              style={{width:'300px'}}
              filter filterBy='name' 
              disabled={disable} 
              value={selmedia} 
              optionLabel="name"
              options={medias} 
              onChange={onMediaChange} 
              placeholder="Select Main Edition" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="project" className="w-2 mr-2 mb-0">Color</label>
            <Dropdown disabled={disable} placeholder="Select Color" style={{width:'300px'}}  id="color"  options={advcolor} value={pro.color} onChange={onColorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="proDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={pro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={pro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
              
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="prono" >Centres</label>
            <MultiSelect 
            style={{width:'300px'}}
              disabled={disable} 
              value={pro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
          <div  className="formgrid grid w-10">
            <div className="field col mb-0">
              <label  htmlFor="binary" >Is Space Incentive</label>
              <Checkbox  className="" disabled={disable} inputId="binary"  checked={pro.isSpaceIncentive} onChange={onSIChange} />
            </div>
            <div className="field col mb-0">
              <label className="mb-0" htmlFor="siamt">S.I. Amt</label>
              <InputNumber inputStyle={{width:'100%'}} disabled={disable}  inputId="siamt" onChange={onSIAmtChange} value={pro.siamt}/>
            </div>
            <div className="field col mb-0">
              <label className="mb-0" htmlFor="framt">Fixed Amt</label>
              <InputNumber inputStyle={{width:'100%'}} disabled={disable}  inputId="framt" onChange={onFRChange} value={pro.fixedRate}/>
            </div>
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto',padding:'5px 0px 0 0px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="field grid mb-1 ml-2 m-auto">
          <label htmlFor="page" className="mr-2 mb-0">Page</label>
          <Dropdown disabled={disable} placeholder="Select Page" className="w-auto" id="page"  optionLabel="nm" options={pagelist} value={pro.page} onChange={(e) => onInputChangePage(e,'page')} />
        </div>
        <div  className="field justify-content-center grid mb-1 ml-2 m-auto">
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="unit">Select Unit</label>
            <Dropdown  disabled={disable} placeholder="Unit" className="w-auto" id="unit"  options={sizeunits} value={pro.unit} onChange={(e) => onInputChange(e,'unit')} />
          </div>
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="size" >Height</label>
            <InputNumber disabled={disable} inputStyle={{width:'100%'}} prefix="H:" mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={pro.height} onValueChange={(e) => onInputNumberChange(e, 'height')}/>
          </div>
          <label className="s-1 m-0 mt-3 p-0" htmlFor="size" >X</label>
          <div className='col-3 mx-0'>
            <label className="w-2 mr-2 mb-0" htmlFor="size" >Width</label>
            <InputNumber disabled={disable} inputStyle={{width:'100%'}} prefix="W:"  mode="decimal" minFractionDigits={2} maxFractionDigits={2} value={pro.width} onValueChange={(e) => onInputNumberChange(e, 'width')}/>
          </div>
        </div>
        <div  className="field justify-content-center grid mb-1 ml-2 m-auto">
            <div className='col-3 mx-0'>
                <label className="w-2 mr-2 mb-0" htmlFor="size" >Rate</label>
                {Ratedisplay}
            </div>
            <div className='col-3 mx-0'>
                <label className="w-2 mr-2 mb-0" htmlFor="size" >Total Size</label>
                <InputNumber disabled={disable} inputStyle={{width:'100%'}} mode="decimal" minFractionDigits={2} maxFractionDigits={2} readOnly value={pro.tsize} />
            </div>
            <div className='col-3 mx-0'>
                <label className="s-1 m-0 p-0" htmlFor="size" >Amount</label>
                <InputNumber disabled={disable} inputStyle={{width:'100%'}} mode="decimal" minFractionDigits={2} maxFractionDigits={2} readOnly value={pro.amt} />
            </div>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Prodetail disabled={disable} captions={captions} selcaption={pro.caption} pdate={pro.rodate} Items={pro.advt} mode={history.location.state.mode}  SaveItems={saveItems} editions={editions} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="prono" readOnly value={pro.cost.grossamt}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="division" style={{width:'20%'}} className="mr-2 mb-0">Publication(Prm/Disc)(+/-)</label>
            <InputNumber  minFractionDigits={2} min="-100" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}} suffix="%" disabled={disable}  htmlFor="prono"  value={pro.cost.pdiscper} onValueChange={(e) => onInputChangeamt(e, 'pdiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}} className="w-auto"  disabled={true} htmlFor="prono" readOnly value={pro.cost.pdisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.grossamt+pro.cost.pdisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="project" style={{width:'20%'}}className=" mr-2 mb-0">Less: Additional Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="prono"  value={pro.cost.addldiscper} onValueChange={(e) => onInputChangeamt(e, 'addldiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.addldisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.grossamt+pro.cost.pdisc-pro.cost.addldisc}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="project" style={{width:'20%'}}className=" mr-2 mb-0">Less: Agency Disc</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="prono"  value={pro.cost.agencydiscper} onValueChange={(e) => onInputChangeamt(e, 'agencydiscper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.agencydisc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.grossamt+pro.cost.pdisc-pro.cost.addldisc-pro.cost.agencydisc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="prono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="prono"  value={pro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="prono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="proDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="prono" readOnly value={pro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={pro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={pro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={pro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={pro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={pro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {pro && <span>Are you sure you want to Cancel this RO <b>{pro.prono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={approveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Proentry);