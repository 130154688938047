import React, {useState,useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as actions from '../../store/actions/index'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import  "./Auth.module.css"
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';


const Auth = (props) => {
    let controlsinit = {
        empid:'',
        password:'',
        newpassword: '',
        rpassword: ''
    };
    const [controls, setControls] = useState(controlsinit);
    const [clickPwd, setClickPwd] = useState(0);
    const [error, setError] = useState('');
    const toast = useRef(null);
    const history = useHistory();
    //background-color:#51b2db ;
    useEffect(() => {
        if (props.isAuth)  {
            if (props.isFirst) {
                setClickPwd(1);
                return;
            }        
            if (!props.isAdmin) {
                const i = props.permissions.find(x => x.name === 'MEDIA')
                if (!i) {
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'User Without relevant Permission', life: 3000 }); 
                }
                else
                    history.push('/MEDIA');
            }
            else
                history.push('/MEDIA');
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isAuth,props.isAdmin,props.permissions,props.isFirst]);

    
    const submitHandler = async (event) => {
        event.preventDefault();
        setError(null);
        if (controls.empid !== '') {
            if (clickPwd)
                if (controls.newpassword === controls.rpassword){
                    await props.onAuthpwd(controls.empid, controls.password,controls.newpassword);
                }
                else
                    toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Passwords Do Not Match', life: 3000 }); 
            else
            {
                await props.onAuth(controls.empid, controls.password);
            }
        }
        else {
            toast.current.show({ severity: 'error', summary: 'No User ID', detail: 'Please Enter User ID', life: 3000 }); 
        }
    };  

    const inputChangedHandler = (event) => {
        let updatedForm = {
            ...controls
        };
        updatedForm[event.target.name] = event.target.value;
        setControls(updatedForm); 
    };
    
    
    const passwordChangeHandler = (event) => {
        setClickPwd(1);
    }

    //const onKeyDown = (event)=> {
    //    if (event.keyCode === 13) {
    //        submitHandler(event);
    //    }
    //}
    if (props.error && (props.error.response.data !== error)) 
    {        
        //toast.current.show({ severity: 'error', summary: 'Failed', detail: props.error.response.data, life: 6000 }); 
        alert(props.error.response.data);
        setError(props.error.response.data); 
    }
       
    let authRedirect=null;
    let pwdForm = null;
    if (clickPwd === 1)
        pwdForm = (<div><span className="p-float-label mt-4">
                <Password name="newpassword" mediumRegex feedback={true} value={controls.newpassword} onChange={inputChangedHandler} required toggleMask />
                <label >New Password</label>
            </span>
            <span className="p-float-label mt-4">
            <Password name="rpassword" feedback={false} value={controls.rpassword} onChange={inputChangedHandler} required />
            <label >Retype Password</label>
        </span></div>)

    let form = <form onSubmit={submitHandler}>
        <span className="mb-4 mt-6 p-float-label">
            <InputText name="empid"   value={controls.empid} onChange={inputChangedHandler} required autoFocus />
            <label >Employee ID</label>
        </span>
        <span className="p-float-label mb-4">
            <Password name="password" value={controls.password} feedback={false} onChange={inputChangedHandler} required />
            <label >Password</label>
        </span>
        {pwdForm}
        <Button label="Submit" type="submit" className="mt-4" ></Button>
        <Button disabled={clickPwd} className="p-button-text text-right" onClick={passwordChangeHandler}>
            Change password?
        </Button>
    </form>
        
    if (props.loading)
        form = <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/> //<Spinner />
    return (
        <div className="p-fluid">
            <Toast ref={toast}></Toast>
            <h4 className="mb-6">Sign In</h4>
            {authRedirect}
            {form}
        </div>
    );
    
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (empid, password) => dispatch(actions.auth(empid, password)),
        onAuthpwd: (empid, password, newpwd) => dispatch(actions.authpwd(empid, password, newpwd)),
        onSetAuthRedirectPath:() => dispatch(actions.setAuthRedirectPath('/MEDIA')),
        //onlogout:() => dispatch(actions.logout()),
    };
};

const mapStateToProps = state => {
    return {
        loading:state.auth.loading,
        error:state.auth.error,
        isAuth:state.auth.token!==null,
        isFirst:state.auth.isFirst,
        isAdmin:state.auth.isAdmin,
        permissions:state.auth.permissions,
        authRedirectPath:state.auth.authRedirectPath
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);