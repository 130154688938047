import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import Proprint from '../tvro/tvroprint'
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import Statuslog from "../../../Containers/statuslog"
import { BlockUI } from 'primereact/blockui';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'

const TvBillentry = (props) => {

  const billinit = {
    storeid: '',
    ubn: '',
    bno: '',
    rono: 0,
    pono:'',
    agencyrono:'',
    recddate: new Date().toString(),
    billdate: new Date().toString(),
    vendor: '',
    grns:[],
    items:[],
    other:[],
    gross:'',
    cgst:'',
    sgst:'',
    igst:'',
    roff:'',
    netamt:'',
    setoff:'',
    remarks:'',
    status:[],
    sendaccdate: '',
    recdaccdate: '',
    finalstatus:''
  };
  const history = useHistory();
  const toast = useRef(null);
  const [disablegst,setdisablegst] = useState(false);
  const [vendorlist,setv] = useState(null);
  const [bill,setbill] = useState(billinit);
  const [rono,setrono] = useState([]);
  const [seleditions,setseleditions] = useState([]);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [poDialog, setpoDialog] = useState(false);
  const [selectedRO,setselectedRO] = useState(null);
  const [interstate,setinterstate] = useState(false);
  const [billimage, setbillimage] = useState(null);
  const [url, seturl] = useState('');
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [billedAmt, setBilledAmt] = useState(0);
  const [blockedDocument, setBlockedDocument] = useState(false);
  const dt = useRef(null);


  let disable=true;
  if (bill.finalstatus === 'Draft' || bill.finalstatus === 'Review' || history.location.state.mode === 'New'){disable=false;}

  const recalculate = (newbill) => {
    let tnet=newbill.gross;
    let tgst=Math.round((tnet * selectedRO.cost.gstper/100)*100)/100;
    if (newbill.vendor.dtype === 'Unregistered') {
      tgst=0;
      setdisablegst(true);
    }
    newbill.igst=tgst;
    newbill.cgst=0;
    newbill.sgst=0;
    if (!interstate) {
      newbill.cgst = Math.round((tgst/2)*100)/100;
      newbill.sgst = Math.round((tgst/2)*100)/100;
      newbill.igst=0;
    }
    tnet = tnet+tgst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newbill.roff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newbill.roff = Math.round(-roff*100)/100;
    }
    newbill.netamt=tnet;
    setbill(newbill);
  };

  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all/',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
  },[props.storeid]);

  const getImageURL = async (fnm) => {
    let authOptions = {
        method: 'PUT',
        url: '/vendor/image/', 
        data: {'img':'uploads/bills/'+fnm},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
          .then(response => {
        seturl(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
}

  useEffect(() => {
    if (history.location.state.mode === 'Edit') {
      const e = history.location.state.billdata;
      setSelectedItems(e.advt);
      getImageURL(e.ubn+".jpg");
      let nrono = [...rono];
      nrono.push(e.rono);
      setrono(nrono);
      let authOptions = {
        method: 'PUT',
        url: '/tvro/byrono/', 
        data: {'vendor':e.agency, 'storeid':props.storeid, 'rono':e.rono},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          setBilledAmt(response.data.billedamt-e.netamt);
          const newpro = response.data.pro;
          let final=[];
          const final1 = response.data.pro.tvmain.map(e => final.concat(e.tvsubs));
          final = [].concat(...final1);
          const newadvt = newpro.advt.map(ea => ({ ...ea, tvsub : final.find(e => e._id === ea.tvsub)}));
          setseleditions(newadvt);
          setSelectedItems(newadvt.filter(eb=>eb.bno !== 0));
          setinterstate(false);
          setselectedRO(response.data.pro);
          setbill(e);
          if (e.vendor.gstin !== null && props.unitgstin !== null)
            if (e.vendor.gstin.slice(0,2) !== props.unitgstin.slice(0,2)) setinterstate(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
          setselectedRO(null);
          setseleditions([]);
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.unitgstin,history.location.state.mode,history.location.state.billdata]); 


  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newbill = {...bill};
    newbill[`${name}`] = val;
    setbill(newbill);
  }

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    if (isNaN(val) === false) {
      let newbill = {...bill}
      newbill[`${name}`] = (val);
      newbill.netamt =  (val)+(newbill.cgst)+ (newbill.sgst)+ (newbill.igst);
      recalculate(newbill);
    }
  }

  const billDraft = (event) => {
    event.preventDefault();
    if (seleditions.length>0 && !selectedItems) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Releases Selected', life: 3000 });
      return;
    }
  if (!bill.vendor) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Vendor Selected', life: 3000 });
      return;
    }
    if (!bill.netamt || bill.netamt === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Nil Bill Amount', life: 3000 });
      //return;
    }
    if (!bill.bno) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Bill Number', life: 3000 });
      return;
    }
    if (Math.round((bill.netamt+billedAmt+bill.setoff)*100)/100 > Math.round(selectedRO.cost.netamt*100)/100) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Total Bill Amount Exceeds RO Amount', life: 3000 });
      return;
    }
    setBlockedDocument(true);
    if (history.location.state.mode === 'New') {
      let newbill = {...bill}
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newbill.status.push(status);
      newbill.storeid = props.storeid;
      newbill.finalstatus = 'Draft';
      let authOptions = {
        method: 'POST',
        url: '/bill/tv/', 
        data: {'bill':newbill,'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          if (billimage) {
            const filename = response.data.ubn+".jpg";
            let formData = new FormData();
            formData.append('billimage', billimage ,filename);
            axios.put('/vendor/bill', formData, {
              headers: {
                'Content-Type': "multipart/form-data"
              }})
              .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Draft Saved', life: 3000 });
                setBlockedDocument(false);
                setSaved(true);
              })
              .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
          }
          else {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Draft Submitted', life: 3000 });
            setBlockedDocument(false);
            setSaved(true);
          }
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      let newbill = {...bill}
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newbill.status.push(status);
      newbill.storeid = props.storeid;
      newbill.finalstatus = 'Draft';
      let authOptions = {
        method: 'PUT',
        url: '/bill/tv/', 
        data: {'bill':newbill,'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          if (billimage) {
            const filename = response.data.ubn+".jpg";
            let formData = new FormData();
            formData.append('billimage', billimage ,filename);
            axios.put('/vendor/bill', formData, {
              headers: {
                'Content-Type': "multipart/form-data"
              }})
              .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Draft Saved', life: 3000 });
                setBlockedDocument(false);
                setSaved(true);
              })
              .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
          }
          else {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Draft Saved', life: 3000 });
            setBlockedDocument(false);
            setSaved(true);
          }
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const billSave = (event) => {
    event.preventDefault();
    if (!bill.vendor) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Vendor Selected', life: 3000 });
      return;
    }
    if (seleditions.length>0 && !selectedItems) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Releases Selected', life: 3000 });
      return;
    }
    if (!bill.netamt || bill.netamt === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Nil Bill Amount', life: 3000 });
      return;
    }
    if (!bill.bno) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Bill Number', life: 3000 });
      return;
    }
    if (Math.round((bill.netamt+billedAmt+bill.setoff)*100)/100 > Math.round(selectedRO.cost.netamt*100)/100) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Total Bill Amount Exceeds RO Amount', life: 3000 });
      return;
    }
    setBlockedDocument(true);
    if (history.location.state.mode === 'New') {
      let newbill = {...bill}
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Submitted',
        sdate:new Date(),
      };
      newbill.status.push(status);
      newbill.storeid = props.storeid;
      newbill.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/bill/tv/', 
        data: {'bill':newbill,'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          if (billimage) {
            const filename = response.data.ubn+".jpg";
            let formData = new FormData();
            formData.append('billimage', billimage ,filename);
            axios.put('/vendor/bill', formData, {
              headers: {
                'Content-Type': "multipart/form-data"
              }})
              .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Submitted', life: 3000 });
                setBlockedDocument(false);
                setSaved(true);
              })
              .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
          }
          else {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill  Submitted', life: 3000 });
            setBlockedDocument(false);
            setSaved(true);
          }
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      let newbill = {...bill}
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Submitted',
        sdate:new Date(),
      };
      newbill.status.push(status);
      newbill.storeid = props.storeid;
      newbill.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/bill/tv/', 
        data: {'bill':newbill,'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          if (billimage) {
            const filename = response.data.ubn+".jpg";
            let formData = new FormData();
            formData.append('billimage', billimage ,filename);
            axios.put('/vendor/bill', formData, {
              headers: {
                'Content-Type': "multipart/form-data"
              }})
              .then(response => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Submitted', life: 3000 });
                setBlockedDocument(false);
                setSaved(true);
              })
              .catch(error => {
                toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
          }
          else {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Submitted', life: 3000 });
            setBlockedDocument(false);
            setSaved(true);
          }
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const poCancel = (event) => {
    event.preventDefault();
    poApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={poCancel} />
    </>
  );

  //Approve
  const poApprove = (event) => {
    event.preventDefault();
    poApproveReject('Approved');
  }
  const poReject = (event) => {
    event.preventDefault();
    poApproveReject('Rejected');
  }
  const poApproveReject = (type) => {  
    if (bill.finalstatus === "Submitted" )  {

      setBlockedDocument(true);
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate: new Date()
      }
      let authOptions = {
        method: 'PUT',
        url: '/bill/approve/tv', 
        data: {'status':status, 'bill':bill, 'action':type === 'Rejected'?'Draft':type, 'storeid':props.storeid, 'selitem':selectedItems},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Bill Approved', life: 3000 });
        setBlockedDocument(false);
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setBlockedDocument(false);
    setSaved(true);
  }
  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    if (!props.isAdmin) {
      const i = props.permissions.find(x => x.name === 'FM-BILLAPPROVE')
      if (!i) {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
        }
        else
          setApproveDialog(true);
    }
  };
  const approveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={poApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={poReject} />
    </>
  );

  if (saved)  history.goBack();

  const onVendorChange = (e) => {
    e.preventDefault()
    const billnew = {...bill};
    billnew.vendor = e.value;
    setinterstate(false);
    if (e.value.gstin !== null && props.unitgstin !== null)
      if (e.value.gstin.slice(0,2) !== props.unitgstin.slice(0,2)) setinterstate(true);
    let authOptions = {
      method: 'POST',
      url: '/tvro/billpending/', 
      data: {'vendor':billnew.vendor, 'storeid':props.storeid},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setrono(response.data.map(i=>i.rono));
        billnew.items=[];
        billnew.grns=[];
        billnew.other=[];
        //recalculate(billnew);    
        setbill(billnew);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setrono([]);
        billnew.rono='';
        billnew.items=[];
        billnew.grns=[];
        billnew.other=[];
        //recalculate(billnew);    
        setbill(billnew);
    });
  };
  const confirmpo = () => {
    if (history.location.state.mode === 'Edit') {
      let authOptions = {
        method: 'PUT',
        url: '/tvro/byrono/', 
        data: {'rono':bill.rono, 'storeid':props.storeid},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          setselectedRO(response.data.pro);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    }
    setpoDialog(true);
  }
  const hidepoDialog = () => setpoDialog(false);


  const onPoChange = (e) => {
    e.preventDefault();
    const billnew = {...bill};
    billnew.rono = e.value;
    let authOptions = {
      method: 'PUT',
      url: '/tvro/byrono/', 
      data: {'vendor':billnew.agency, 'storeid':props.storeid, 'rono':billnew.rono},
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setselectedRO(response.data.pro);
        setBilledAmt(response.data.billedamt);
        const newpro = response.data.pro;
        let final=[];
        const final1 = response.data.pro.tvmain.map(e => final.concat(e.tvsubs));
        final = [].concat(...final1);
        const newadvt = newpro.advt.map(ea => ({ ...ea, tvsub : final.find(e => e._id === ea.tvsub)}));
        const xx = (newadvt.filter(e=>((!e.bno || e.bno === 0))));
        setseleditions(xx?xx:[]);
        setSelectedItems(!xx?response.data.pro.advt:[])
        setbill(billnew);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setselectedRO(null);
        setseleditions([]);
    });
  }
const vstatusBodyTemplate = (rowData) => {
    return rowData.verified?<span style={{color:'green'}}>{'Verified'}</span>:<span style={{color:'orange'}}>{'Not Verified'}</span>;
}
const rightToolbarTemplate = () => {
  return (
      <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText disabled={props.disabled} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
  )
}


  let Items=(<>
    <Toolbar className="mt-0 p-1"  right={rightToolbarTemplate} ></Toolbar>

    <DataTable disabled={disable} editMode="row" resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm editable-cells-table" ref={dt} value={seleditions} selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
    dataKey="_id" paginator rows={5} rowsPerPageOptions={[5, 10, 25]} 
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Items"
    globalFilter={globalFilter} >
    <Column selectionMode="multiple" style={{width:'4%'}} ></Column>
    <Column field="tvsub.tvsub" header="FM Sub" sortable style={{width:'18%',padding:'0 2px 0 2px'}}></Column>
    <Column field="spot" header="Spot" sortable style={{width:'10%',padding:'0 2px 0 2px'}}></Column>
    <Column field="spotduration" header="Duration" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
    <Column field="frequency" header="Frequency"  style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
    <Column field="fct" header="FCT" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
    <Column field="paid" header="Paid" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
    <Column field="totalsec" header="Total" style={{width:'6%',padding:'0 2px 0 2px'}}></Column>
    <Column field="verified"  header="Verified"  body={vstatusBodyTemplate} style={{width:'8%',padding:'0 2px 0 2px'}}></Column>
</DataTable>
</>
);



  let footer = (<div style={{display:'flex', flexDirection: 'column', alignItems:'center', marginTop:'10px', marginRight:'0'}}>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="cgst" style={{width:'250px',paddingLeft:'50px', marginBottom:'0px'}}>Gross Amt (Before GST) : </label>
        <InputNumber name='cgst' id='cgst' inputStyle={{width:'150px', textAlign:'right'}}  value={bill.gross} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'gross')}/>
      </div>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="cgst" style={{width:'250px',paddingLeft:'50px', marginBottom:'0px'}}>C.G.S.T. : </label>
        <InputNumber disable={disablegst} name='cgst' id='cgst' inputStyle={{width:'150px', textAlign:'right'}}  value={bill.cgst} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'cgst')}/>
      </div>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="gst" style={{width:'250px',paddingLeft:'50px', marginBottom:'0px'}}>S.G.S.T. : </label>
        <InputNumber disable={disablegst} name='sgst' id='sgst' inputStyle={{width:'150px', textAlign:'right'}}  value={bill.sgst} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'sgst')}/>
      </div>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="roff" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Round Off : </label>
        <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  value={bill.roff} mode="decimal" locale="en-IN" minFractionDigits={2}/>
      </div>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="net" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Net Amount : </label>
        <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  value={bill.netamt} mode="decimal" locale="en-IN" minFractionDigits={2}/>
      </div>
      <div className=" field flex align-items-center mb-0">
        <label htmlFor="setoff" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Set off Amount : </label>
        <InputNumber inputStyle={{width:'150px', textAlign:'right'}} name='setoff' id='net'  value={bill.setoff} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'setoff')}/>
      </div>
    </div>
  );
  
  if (interstate) 
    footer = (<div style={{display:'flex', flexDirection: 'column', alignItems:'end', marginRight:'0'}}>
    <div className=" field flex align-items-center mb-0">
      <label htmlFor="cgst" style={{width:'250px',paddingLeft:'50px', marginBottom:'0px'}}>Gross Amt (Before GST) : </label>
      <InputNumber name='gross' id='gross' inputStyle={{width:'150px', textAlign:'right'}} placeholder='gross' value={bill.gross} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'gross')}/>
    </div>
    <div className=" field flex align-items-center mb-0">
      <label htmlFor="gst" style={{width:'250px',paddingLeft:'50px', marginBottom:'0px'}}>I.G.S.T.</label>
      <InputNumber disable={disablegst} name='igst' id='igst' inputStyle={{width:'150px', textAlign:'right'}} placeholder='IGST' value={bill.igst} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'igst')}/>
    </div>
    <div className=" field flex align-items-center mb-0">
        <label htmlFor="roff" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Round Off : </label>
        <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  value={bill.roff} mode="decimal" locale="en-IN" minFractionDigits={2}/>
      </div>
    <div className=" field flex align-items-center mb-0">
      <label htmlFor="net" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Net Amount</label>
      <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  placeholder='NetAmt' value={bill.netamt} mode="decimal" locale="en-IN" minFractionDigits={2}/>
    </div>
    <div className=" field flex align-items-center mb-0">
        <label htmlFor="setoff" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Set off Amount : </label>
        <InputNumber inputStyle={{width:'150px', textAlign:'right'}} name='setoff' id='net'  value={bill.setoff} mode="decimal" locale="en-IN" minFractionDigits={2} onChange={(e) => onInputChangeamt(e, 'setoff')}/>
      </div>
  </div>
  );

    const uploadBILL = async (itemfile) => {
      setbillimage(itemfile.files[0]);
      toast.current.show({severity: 'success',summary: 'Success', detail: 'Bill Document is set to upload on Save/Submit', life: 3000 }); 
    };
  
  
  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<><Button disabled={disable} label="Save as Draft"   className=" p-button-rounded p-button-raised p-button-info " onClick={billDraft} />
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={billSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if   ((bill.finalstatus === 'Draft' || bill.finalstatus === 'Review') && history.location.state.mode === 'Edit') {
    BtnOptions = (<><Button disabled={disable} label="Save as Draft"   className=" p-button-rounded p-button-raised p-button-info " onClick={billDraft} />
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={billSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (bill.finalstatus === 'Submitted') {
    BtnOptions = (<><Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  let vendordetail = null
  if (bill.vendor)  vendordetail = (
      <Fieldset className="p-0 w-12" legend="Vendor Contact Detail" toggleable collapsed='true'>
      <div className={'formgrid grid'}>
      <div className="field col mb-0 pb-0">
          <label htmlFor="pname">Party Name</label>
          <InputText disable='true' id="pname" className={'w-full'} value={bill.vendor.name} />
      </div>
      <div className="field col mb-0 pb-0">
          <label htmlFor="person">GSTIN</label>
          <InputText disable='true' id="gstin" className={'w-full'} value={bill.vendor.gstin}  />
      </div>
      </div>
      <div className={'formgrid grid'}>
      <div className="field col mb-0 pb-0">
          <label htmlFor="address">Address</label>
          <InputText disable='true' id="address" className={'w-full'} value={bill.vendor.address}  />
      </div>
      <div className="field col mb-0 pb-0">
          <label htmlFor="state">State</label>
          <InputText disable='true' id="state" className={'w-full'} value={bill.vendor.state}  />
      </div>  
      </div>
      <div className={'formgrid grid'}>
      <div className="field col mb-0 pb-0">
          <label htmlFor="phone">Phone</label>
          <InputText disable='true' id="phone" className={'w-full'} value={bill.vendor.mobileno}  />
      </div>
      <div className="field col mb-0 pb-0">
          <label htmlFor="emailid">Email ID</label>
          <InputText disable='true' id="email" className={'w-full'} value={bill.vendor.emailid}  />
      </div>
      </div>
  </Fieldset>
  );
  const ourContact = (
      <>
      <div className={'formgrid grid'}>
        <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Narration</label>
            <InputTextarea disabled={disable} autoComplete="off" className={'w-full'} id="remarks" value={bill.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
        </div>
      </div>
      <div className='flex row justify-content-evenly py-2 border-1 border-300'>
        <div className="  mb-0 pb-0">
        <label htmlFor="recdDate" className="mr-2 p-0">Physical Bill Send to Accounts Date</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" style={{width:'100px'}} disabled={disable} id="sendaccDate" name="sendaccDate" className=" w-auto" size='2' value={new Date(bill.sendaccdate)} onChange={(e) => onInputChange(e, 'sendaccdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
        </div>
        <div className="  mb-0 pb-0">
        <label htmlFor="recdDate" className="mr-2 p-0">Physical Bill Recd at Accounts Date</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" style={{width:'100px'}} disabled={true} id="recdaccDate" name="recdaccDate" className=" w-auto" size='2' value={new Date(bill.recdaccdate)} onChange={(e) => onInputChange(e, 'recdaccdate')} mask="99/99/9999" dateFormat="dd/mm/yy" />
        </div>
      </div>
      </>
  );
  
  return (
    <div >
      <BlockUI blocked={blockedDocument} fullScreen />
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
      <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Bill Entry Form</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>
      <div style={{width:'84%',margin:'auto'}} className="formgrid grid h-auto mt-2 surface-50">
        <div  className="field col  mb-0">
          <label htmlFor="party" className="">Allen Registered Vendor* </label>
          <Dropdown filter filterBy='name' placeholder="Select Allen Registered Vendor"className=" w-full" id="party" disabled={disable} autoComplete="off" optionLabel="name"  options={vendorlist} value={bill.vendor} onChange={onVendorChange} />
        </div>
        <div  className="field col mb-0">
          <label htmlFor="po" >Release Orders</label>
          <div  className="formgrid  mb-0">
            <Dropdown placeholder="Select RO" className="w-10" 
              disabled={disable}
              id="ro"  
              value={bill.rono} 
              options={rono}
              onChange={ onPoChange} />
            <Button  label="View"  className="  p-button-outline " onClick={confirmpo}  />
          </div>
        </div>
      </div>
      <div style={{width:'84%',margin:'auto'}} className="formgrid grid h-auto mt-2 surface-50">
        <div  className="field col mb-0">
          <label htmlFor="ubn" >UBN </label>
          <InputText disabled={disable} htmlFor="ubn" className="w-full" readOnly value={bill.ubn}/>
        </div>
        <div  className="field col  mb-0">
          <label htmlFor="recdDate" className="mr-2 p-0">Recd Date</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" style={{width:'100px'}} disabled={disable} id="recdDate" name="recdDate" className=" w-full" size='2' value={new Date(bill.recddate)} onChange={(e) => onInputChange(e, 'recddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
        </div>
        <div  className="field col mb-0">
          <label htmlFor="bno" >Bill No* </label>
          <InputText disabled={disable} htmlFor="bno" className="w-full"  value={bill.bno} onChange={(e) => onInputChange(e, 'bno')}/>
        </div>
        <div  className="field col  mb-0">
          <label htmlFor="billDate" className="mr-2 p-0">Bill Date*</label>
          <Calendar maxDate={new Date()} monthNavigator yearNavigator yearRange="2010:2030" style={{width:'100px'}} disabled={disable} id="billDate" name="billDate" className=" w-full" size='2' value={new Date(bill.billdate)} onChange={(e) => onInputChange(e, 'billdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
        </div>
        <div  className="field col mb-0">
          <label htmlFor="agencyrono" >Agency RO</label>
          <InputText disabled={disable} htmlFor="agencyrono" className="w-full"  value={bill.agencyrono} onChange={(e) => onInputChange(e, 'agencyrono')}/>
        </div>
      </div>
      <div className="w-10 m-auto formgrid grid h-auto mt-2 surface-50">
        {vendordetail}
      </div>
      <div className='w-10 m-auto'>
        {Items}
        <div className='flex'>
        <div className=" field flex align-items-center mb-0">
          <label htmlFor="net" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>RO Amount : </label>
          <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='net' id='net'  placeholder='NetAmt' value={selectedRO?selectedRO.cost.netamt:0} mode="decimal" locale="en-IN" minFractionDigits={2}/>
        </div>
        <div className=" field flex align-items-center mb-0">
          <label htmlFor="setoff" style={{width:'250px', paddingLeft:'50px', marginBottom:'0px'}}>Already Billed Amount : </label>
          <InputNumber readOnly inputStyle={{width:'150px', textAlign:'right'}} name='setoff' id='net'  value={billedAmt} mode="decimal" locale="en-IN" minFractionDigits={2}/>
        </div>
        {footer}
        </div>
        {ourContact}
      </div>   
      <div className='w-10 mt-2 m-auto flex flex-row justify-content-around py-2 border-1 border-300'>     
        <FileUpload name='img' url="uploads/bills/"  accept="image/*" mode='basic' 
          customUpload={true}
          disabled={disable}
          chooseLabel="Select Bill Image to Upload"
          uploadHandler={uploadBILL}
          maxFileSize={5000000}></FileUpload>
        <Image src={url}  alt="Image" width="50" height="50" preview />
      </div>
      <div className='Statuslog'>
          <Statuslog statuses={bill.status}/>
        </div>
      <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
        {BtnOptions}
      </div>

      <Dialog visible={deleteDialog} style={{ width: '490px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />

            {bill && <span>Are you sure you want to Cancel this Bill <b>{bill.ubn}</b>?</span>}

        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={approveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
      <Dialog visible={poDialog} style={{ width: '80%' }} modal onHide={hidepoDialog}>
        <div >
          <Proprint pro={selectedRO}/>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        unitgstin:state.auth.unitgstin,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(TvBillentry);