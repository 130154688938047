import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import logo from "../../../assets/allen.png";
import axios from '../../../axios-allen';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import styles from './mediatype.module.css';
import { useHistory } from "react-router-dom";


const Mediatype = (props) => {
    const history = useHistory();
    let emptyMediatype = {
        name: '',
    };

    const [Mediatype, setMediatype] = useState(emptyMediatype);
    const [Mediatypes, setMediatypes] = useState('');
    const [MediatypeDialog, setMediatypeDialog] = useState(false);
    const [deleteMediatypeDialog, setDeleteMediatypeDialog] = useState(false);
    const [mode,setMode] = useState('');


    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        let authOptions = {
            method: 'GET',
            url: '/mediatype/all',
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
        };
        axios(authOptions)
        .then(response => {
            setMediatypes(response.data.mediatypes)
        })
        .catch(error => {
            toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            setMediatypes([]);
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps


    const openNew = () => {
        setMediatype(emptyMediatype);
        setSubmitted(false);
        setMode('New');
        setMediatypeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setMediatypeDialog(false);
    }

    const hideDeleteMediatypeDialog = () => {
        setDeleteMediatypeDialog(false);
    }

    const saveMediatype = () => {
        setSubmitted(true);

        if (Mediatype.name.trim()) {
            let _Mediatypes = [...Mediatypes];
            let _Mediatype = {...Mediatype};
            if (mode === 'Edit') {
                const index = findIndexById(Mediatype._id);
                _Mediatypes[index] = _Mediatype;
                let authOptions = {
                  method: 'PUT',
                  url: '/mediatype/', 
                  data: _Mediatype,
                  headers : {
                      'x-auth-token' : localStorage.getItem('token')
                  }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mediatype Edited', life: 3000 });
                    setMediatypes(_Mediatypes);
                    setMediatypeDialog(false);
                    setMediatype(emptyMediatype);
                })
                  .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
            else {
                let authOptions = {
                    method: 'POST',
                    url: '/mediatype/', 
                    data: _Mediatype,
                    headers : {
                        'x-auth-token' : localStorage.getItem('token')
                    }
                };
                axios(authOptions)
                    .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mediatype Created', life: 3000 });
                     _Mediatypes.push(response.data);
                     setMediatypes(_Mediatypes);
                     setMediatypeDialog(false);
                     setMediatype(emptyMediatype);
                })
                    .catch(error => {
                    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
                });
            }
        }
    }

    const editMediatype = (Mediatype) => {
        setMediatype({...Mediatype});
        setMode('Edit');
        setMediatypeDialog(true);
    }

    const confirmDeleteMediatype = (Mediatype) => {
        setMediatype(Mediatype);
        setDeleteMediatypeDialog(true);
    }

    const deleteMediatype = () => {
        let authOptions = {
            method: 'delete',
            url: '/mediatype/', 
            data: Mediatype,
            headers : {
                'x-auth-token' : localStorage.getItem('token')
            }
          };
          axios(authOptions)
            .then(response => {
                setDeleteMediatypeDialog(false);
                let _Mediatypes = Mediatypes.filter(val => val.name !== Mediatype.name);
                setMediatypes(_Mediatypes);
                setMediatype(emptyMediatype);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Mediatype Deleted', life: 3000 });
            })
            .catch(error => {
              toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
            });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < Mediatypes.length; i++) {
            if (Mediatypes[i]._id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Mediatype = {...Mediatype};
        _Mediatype[`${name}`] = val;
        setMediatype(_Mediatype);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success p-mr-2" onClick={() => editMediatype(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-warning" onClick={() => confirmDeleteMediatype(rowData)} />
           </React.Fragment>
        );
    }

    const header = (
        <div className={styles.tableheader}>
            <h4 className="mx-0 my-1">Manage Mediatypes</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const MediatypeDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveMediatype} />
        </React.Fragment>
    );
    const deleteMediatypeDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMediatypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteMediatype} />
        </React.Fragment>
    );

    return (
        <div className={styles.PER}>
            <Toast ref={toast} />
            <div className={styles.Headerper}>
                <img src={logo} alt="Allen" height={60} width={180} style={{margin:'10px 0 0 20px'}} ></img>
                <h2 style={{marginTop:'5px', textAlign:'center'}}>Media & Marketing<br/>Mediatypes Management</h2>
                <div className={styles.Userper}>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}} >Hi, {props.username}</h4>
                    <h4 style={{margin:'0 30px 0 0',textAlign:'end'}}>Centre:{props.unitname}</h4>
                </div>
            </div>
            <div className={styles.datatablecruddemo} style={{width:'60%', margin:'auto',top:'-30vh',position: 'relative'}}>
                <div className={styles.card}>
                    <Toolbar className="mb-0" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable sortField="name" sortOrder={1} resizableColumns columnResizeMode="fit" showGridlines className="p-datatable-sm" ref={dt} value={Mediatypes} 
                        dataKey="name" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Mediatypes"
                        globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                        <Column field="name" header="Mediatype Name" sortable style={{ width:'80%',minWidth: '16rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>
                </div>
                <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
                    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />
                </div>
            </div>
            <Dialog visible={MediatypeDialog} style={{ width: '450px' }} header="Mediatype Details" modal className="p-fluid" footer={MediatypeDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">Mediatype Name*</label>
                    <InputText id="name" value={Mediatype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !Mediatype.name })} />
                    {submitted && !Mediatype.name && <small className="p-error">Name is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteMediatypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMediatypeDialogFooter} onHide={hideDeleteMediatypeDialog}>
                <div className={styles.confirmationcontent}>
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {Mediatype && <span>Are you sure you want to delete <b>{Mediatype.name}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        username:state.auth.username,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin,
        storeid:state.auth.storeid
    }
  }
  
  export default connect(mapStateToProps)(Mediatype);