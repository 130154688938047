import React, { useState, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-allen';
import { useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import states from '../../../Components/state';
import Oorodetail from "./oorodetail";

import Statuslog from "../../statuslog"
//import { MultiSelect } from 'primereact/multiselect';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'


const Ooro = (props) => {
  const [sessions,setsessions] = useState([]);
  let ooroinit = {
    rono: '',
    rodate: new Date().toString(),
    session: '',
    division: '',
    agency:'',
    bstate:'',
    centres:[],
    caption:'',
    focqty:0,
    costalloc:[],
    advt:[],
    cost: {
      displayamt:0,
      flexamt:0,
      grossamt:0,
      discper:0,
      disc:0,
      gstper:18,
      gst:0,
      roundoff:0,
      netamt:0
    },
    bunit:'',
    baddress:'',
    note: '',
    remarks:'',
    status:[],
    finalstatus:''
  };

  const history = useHistory();
  const toast = useRef(null);
  const [newDate, setNewDate]= useState(new Date())
  const [vendorlist,setv] = useState(null);
  const [ooro,setooro] = useState(ooroinit);
  const [saved,setSaved] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [ApproveDialog, setApproveDialog] = useState(false);
  const [units,setunits] = useState([]);
  const [cities,setCities] = useState([]);
  const [selUnits,setselUnits] = useState([]);
  const [captions,setcaptions] = useState([]);
  const [divisions,setdivisions] = useState([]);
  const [mediatypes,setMediatypes] = useState([]);
  const [littypes,setLittypes] = useState([]);
  const [flexmaterials,setFlexmaterials] = useState([]);
  const [oldRo,setOldRo] = useState('');

  let disable=true;
  if (ooro.finalstatus === 'Submitted' || ooro.finalstatus === 'Draft' || ooro.finalstatus === 'Review' || history.location.state.mode === 'New')
  {
   disable=false;
  }

  const recalculate = (newooroa) => {
    let tnet=0;
    const newooro = {...newooroa.cost};
    newooro.displayamt = newooroa.advt.map(item => item.amt).reduce((acc,curr)=>acc+curr, 0);
    newooro.flexamt = newooroa.advt.map(item => item.flexamt).reduce((acc,curr)=>acc+curr, 0);
    newooro.grossamt = newooroa.advt.map(item => item.totalamt).reduce((acc,curr)=>acc+curr, 0);
    
    tnet = newooro.grossamt;
    newooro.disc = Math.round((tnet * newooro.discper/100)*100)/100;
    tnet = tnet - newooro.disc;
    newooro.gst = Math.round((tnet * newooro.gstper/100)*100)/100;
    tnet = tnet + newooro.gst;
    let roff = tnet - parseInt(tnet);
    if (roff>0.50) {
      tnet = parseInt(tnet)+1;
      newooro.roundoff = Math.round((1-roff)*100)/100;
    }
    else {
      tnet = parseInt(tnet);
      newooro.roundoff = Math.round(-roff*100)/100;
    }
  newooro.netamt = tnet;
    if (newooroa.agency.dtype === 'Unregistered' ) {
      tnet = newooro.netamt-newooro.gst;
      newooro.gst=0;
      let roff = tnet - parseInt(tnet);
      if (roff>0.50) {
        tnet = parseInt(tnet)+1;
        newooro.roundoff = Math.round((1-roff)*100)/100;
      }
      else {
        tnet = parseInt(tnet);
        newooro.roundoff = Math.round(-roff*100)/100;
      }
      newooro.netamt=tnet;
    }
    newooroa.cost = newooro;
    setooro(newooroa);
  };


  useEffect(() => {
    let authOptions = {
      method: 'GET',
      url: '/vendor/media/all',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setv(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setv([]);
    });
    authOptions = {
      method: 'GET',
      url: '/unit/allmedia',
      headers : {
          'x-auth-token' : localStorage.getItem('token')
      }
    };
    axios(authOptions)
      .then(response => {
        setunits(response.data);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setunits([]);
    });
    axios.get('/caption/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setcaptions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setcaptions([]);
    });
    axios.get('/session/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setsessions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setsessions([]);
    });
    axios.get('/division/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setdivisions(response.data.map(e => (e.name)));
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });
    axios.get('/mediatype/all', {headers : {
      'x-auth-token' : localStorage.getItem('token')
    }})
    .then(response => {
      setMediatypes(response.data.mediatypes);
      setLittypes(response.data.littypes);
      setFlexmaterials(response.data.flexmaterials);
    })
    .catch(error => {
      toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      setdivisions([]);
    });

  },[props.storeid]);

  useEffect(() => {
        let ndt = new Date();
        ndt.setMonth(3);
        ndt.setDate(1);
        setNewDate(ndt);
  },[history.location.state.mode,history.location.state.oorodata,units]);

  useEffect(() => {
    if (history.location.state.mode === 'Edit' ) {
      const newooro = {...history.location.state.oorodata};
      const selcentres = units.filter((el) => {
        return newooro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      let authOptions = {
        method: 'PUT',
        url: '/cities/bystate',
        data: {'state':newooro.bstate},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setCities(response.data);
        setooro(newooro);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setCities([]);
        setooro(newooro);
      });
    }
  },[history.location.state.mode,history.location.state.oorodata,units]);

  const onInputChange = (e, name) => {
    let val = (e.target && e.target.value) || '';
    let newooro = {...ooro};
    newooro[`${name}`] = val;
    setooro(newooro);
  }
  // const onInputDateChange = (e, name) => {
  //   let val = (e.target && e.target.value) || '';
  //   let newooro = {...ooro};
  //   newooro[`${name}`] = val;
  //   newooro.days = Math.ceil((new Date(newooro.enddate).getTime() - new Date(newooro.startdate).getTime()) / (1000*60*60*24))+1;
  //   setooro(newooro);
  // }
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _Item = {...ooro};
    _Item[`${name}`] = val;
    //_Item.enddate = new Date(new Date(_Item.startdate).getTime()+(val*1000*60*60*24));
    setooro(_Item);
  };
//   <div  className="  mb-1 ml-2 m-auto">
//   <label  className=" mr-2 mb-0" >Start Date</label>
//   <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(ooro.startdate)} onChange={(e) => onInputDateChange(e, 'startdate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
// </div>
// <div  className="  mb-1 ml-2 m-auto">
//   <label className="mr-2 mb-0">End Date</label>
//   <Calendar  style={{width:'300px'}} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable}  value={new Date(ooro.enddate)} onChange={(e) => onInputDateChange(e, 'enddate')} mask="99/99/9999" dateFormat="dd/mm/yy" required />
// </div>
// <div  className="  mb-1 ml-2 m-auto">
//   <label className=" mr-2 mb-0" >Period</label>
//   <InputNumber inputStyle={{width:'100%'}} disabled={disable}  onChange={(e) => onInputDaysChange(e, 'period')} value={ooro.period}/>
// </div>

  const onInputChangeamt = (e, name) => {
    let val = (e.value || 0);
    let newooro = {...ooro}
    let cst = {...newooro.cost}
    cst[`${name}`] = (val);
    newooro.cost = cst
    recalculate(newooro);
    //setooro(newooro);
  }


  const ooroSave = (event) => {
    event.preventDefault();
    if (!ooro.agency) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Select Agency', life: 3000 });
      return;
    };
    let newooro = {...ooro};
    if (ooro.session === "") {
      const xx =sessions.splice(-1);
      newooro.session = xx[0];
    };
    if (ooro.division === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Specify the Division', life: 3000 });
      return;
    };
    if (!ooro.caption) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Caption', life: 3000 });
      return;
    };
    if (!ooro.centres.length===0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Centres', life: 3000 });
      return;
    };
    if (ooro.advt.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'No Advertisement detail', life: 3000 });
      return;
    };
    if (!ooro.bunit) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide Billing Centre', life: 3000 });
      return;
    };
    if (!ooro.centres) {
      toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Please Provide the Centre', life: 3000 });
      return;
    };

    if (history.location.state.mode === 'New') {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newooro.status.push(status);
      const advts = newooro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newooro.advt = advts;
      newooro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'POST',
        url: '/ooro/', 
        data: newooro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Submitted', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
    else
    {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:'Draft',
        sdate:new Date(),
      };
      newooro.status.push(status);
      const advts = newooro.advt.map(e => {
        if (e._id < 9000) {
          const {_id, ...ni} = e;
          return ni;
        } else {
          return e;
        }
      });
      newooro.advt = advts;
      newooro.finalstatus = 'Submitted';
      let authOptions = {
        method: 'PUT',
        url: '/ooro/', 
        data: newooro,
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Edited', life: 3000 });
          setSaved(true);
        })
        .catch(error => {
          toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        });
    }
  }

  const hideDeleteDialog = () => (setDeleteDialog(false));
  const confirmDelete = () => setDeleteDialog(true);
  const ooroCancel = (event) => {
    event.preventDefault();
    ooroApproveReject('Cancel');
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={ooroCancel} />
    </>
  );

  //Approve
  const ooroApproveReject = (type) => {  
    if (ooro.finalstatus === "Submitted") {
      const status = {
        empid:props.empid,
        username:props.username,
        desig:props.desig,
        status:type,
        sdate:new Date(),
      };
      let authOptions = {
        method: 'PUT',
        url: '/ooro/approve/', 
        data: {'rono':ooro.rono, 'action':type, 'status':status},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'RO Approved', life: 3000 });
        setSaved(true);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
      });
    } else 
    setSaved(true);
  }
  const ooroApprove = (event) => {
    event.preventDefault();
    ooroApproveReject('Approved');
  }
  const ooroReject = (event) => {
    event.preventDefault();
    ooroApproveReject('Rejected');
  }

  const hideApproveDialog = () => setApproveDialog(false);
  const confirmApprove = () => {
    const i = props.permissions.find(x => (x.name === 'OO-APPROVE'))
    if (props.isAdmin || i) {
      setApproveDialog(true);
    } else 
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Sorry !! You are NOT authorised to Approve', life: 3000 }); 
  };
  const apooroveDialogFooter = (
    <>
      <Button label="Cancel" className="p-button-text" onClick={hideApproveDialog} />
      <Button label="Approved" id="Approved" className="p-button-text p-button-success" onClick={ooroApprove} />
      <Button label="Reject"  id="Reject" className="p-button-text p-button-danger" onClick={ooroReject} />
    </>
  );

  const onVendorChange = (e) => {
    const ooronew = {...ooro};
    ooronew.agency = e.value;
    ooronew.bunit = units.find(x => x.name === 'KOTA-HO');
    ooronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+ooronew.bunit.address+"\n"+ooronew.bunit.city+"-"+ooronew.bunit.pincode+" "+ooronew.bunit.state+"\nGSTIN:"+ooronew.bunit.gstin;
    setooro(ooronew);
  };
 const onCaptionChange = (e) => {
  const ooronew = {...ooro};
  ooronew.caption = e.value;
  //recalculate(ooronew); 
  setooro(ooronew);
};
const onSessionChange = (e) => {
  const ooronew = {...ooro};
  ooronew.session = e.value;
  //recalculate(ooronew);    
  setooro(ooronew);
};
const onDivisionChange = (e) => {
  const ooronew = {...ooro};
  ooronew.division = e.value;
  //recalculate(ooronew);    
  setooro(ooronew);
};
const onCentreChange = (e) => {
  e.preventDefault();
  const ooronew = {...ooro};
  ooronew.centres = e.value;
  ooronew.costalloc=[];
  if (e.value.length>0) {
    const aper = 100 / e.value.length;
    const final2 = e.value.map(e => {
      return({'centre':e, 'alloc':aper})
    });
    ooronew.costalloc = final2;
  }
  setooro(ooronew);
// e.value.map(e1 => { 
  //   const f = ooronew.datealloc.find(e2 => e2.cname === e1.name);
  //   if (!f) ooronew.datealloc.push({'unit':e1._id, 'cname':e1.name, 'dop':ooronew.datealloc.length===0?new Date().toString():ooronew.datealloc[0].dop, 'alloc':0})
  //   return true;
  // })
  // let data = ooronew.datealloc.filter(item => e.value.findIndex(f => f.name === item.cname)+1);
  // //recalculate(ooronew); 
  // ooronew.datealloc = data;   
};


const onStateChange  = (e) => {
  e.preventDefault();
  const ooronew = {...ooro};
  ooronew.bstate = e.value;
  const selcentres = units.filter((el) => {
    return e.value.some((f) => {
      return f === el.state;
    });
  });

  setselUnits(selcentres!==null?selcentres:'');
  let authOptions = {
    method: 'PUT',
    url: '/cities/bystate',
    data: {'state':ooronew.bstate},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
    setCities(response.data);
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setCities([]);
  });

  //setselUnits(units.filter(es => es.state === e.value));
  setooro(ooronew);
};
const onBunitChange = (e) => {
  const ooronew = {...ooro};
  ooronew.bunit = e.target.value;
  ooronew.baddress = 'ALLEN CAREER INSTITUTE PVT. LTD.\n'+e.value.address+"\n"+e.value.city+"-"+e.value.pincode+" "+e.value.state+"\nGSTIN:"+e.value.gstin;
  //setselunit(e.value);
  setooro(ooronew);
}
const onOldRoChange = (e) => {
  setOldRo(e.target.value);
}
const onOldRoBlur = (e) => {
  setOldRo(e.target.value);
  let authOptions = {
    method: 'PUT',
    url: '/ooro/byoldrono',
    data: {'oldrono':e.target.value},
    headers : {
        'x-auth-token' : localStorage.getItem('token')
    }
  };
  axios(authOptions)
    .then(response => {
      const newooro = response.data;
      newooro.rono = ''
      newooro.rodate = new Date();
      newooro.status = []
      const selcentres = units.filter((el) => {
        return newooro.bstate.some((f) => {
          return f === el.state;
        });
      });
      //setselfmmain(ed);
      setselUnits(selcentres!==null?selcentres:'');
      let authOptions = {
        method: 'PUT',
        url: '/cities/bystate',
        data: {'state':newooro.bstate},
        headers : {
            'x-auth-token' : localStorage.getItem('token')
        }
      };
      axios(authOptions)
        .then(response => {
        setCities(response.data);
        setooro(newooro);
      })
      .catch(error => {
        toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
        setCities([]);
        setooro(newooro);
      });
  })
  .catch(error => {
    toast.current.show({severity: 'error', summary: 'Error Message', detail: error.response.data});
    setooro(ooroinit);
  });
}

// const dateEditor = (ooroductKey, props) => {
//   return  <Calendar  className="w-auto"  monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} value={props.rowData['dop']}
//   onChange={(e) => onEditorValueChange(ooroductKey, props, e.value)} mask="99/99/9999" dateFormat="dd/mm/yy"/>
// }
const saveItems = (i) => {
  let newooro = {...ooro};
  newooro.advt = i;
  recalculate(newooro);
  //setooro(newooro);
}

  let BtnOptions = <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} />;
  if (history.location.state.mode === 'New') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={ooroSave} />
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if (history.location.state.mode === 'Edit' && ooro.finalstatus !== 'Approved') {
    BtnOptions = (<>
    <Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={ooroSave} />
    <Button  label="Cancel"  className="p-button-rounded p-button-raised p-button-danger " onClick={confirmDelete} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}
  if  (ooro.finalstatus === 'Approved') {
    BtnOptions = (<><Button  label="Submit"  className="p-button-rounded p-button-raised p-button-success " onClick={ooroSave} />
    <Button  label="Approve/Reject" className="p-button-rounded p-button-raised p-button-warning " onClick={confirmApprove}/>
    <Button label="Back"  className="p-button-rounded p-button-raised" onClick={()=>history.goBack()} /></>
  );}

  if (saved)  history.goBack();

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div style={{height: 'auto', width:'100%',backgroundColor:'#B1DCED'}}> 
        <div className="flex flex-row m-0 justify-content-center">
          <h2 className="text-primary text-center mt-0 mb-4">Outdoor - ROs</h2>
        </div>
        <div className="flex m-0 justify-content-between align-items-center">
          <div style={{display:'flex',alignItems:'center'}}>
            <Button  icon="pi pi-home" className=' p-button-text p-0' onClick={() => history.push('/MEDIA')} />
            <h4 className=" m-0 ml-2 mb-1">Current User:{props.username}</h4>
          </div>
          <h4 className=" m-0 mr-2 mb-1">Centre:{props.unitname}</h4>
        </div>
      </div>


      <div style={{width:'90%',margin:'auto',padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex flex-wrap mt-1 surface-100">
        <div  className="w-4 m-0">
          <div  className="formgrid grid justify-content-justify ml-1">
            <div  className="field grid mb-1 ">
              <label className="mb-0 mr-1 ml-1">RO_No</label>
              <InputText style={{width:'130px'}}className="" disabled={disable}  htmlFor="oorono" readOnly value={ooro.rono}/>
            </div>
            <div  className="field  mb-1 ">
              <label className="ml-4 mb-0 mr-1">RO_Date</label>
              <Calendar  style={{width:'140px'}}  minDate={newDate} monthNavigator yearNavigator yearRange="2010:2030" disabled={disable} id="ooroDate" name="roDate" value={new Date(ooro.rodate)} onChange={(e) => onInputChange(e, 'rodate')} mask="99/99/9999" dateFormat="dd/mm/yy" required autoFocus/>
            </div>
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="session" className="w-2 mr-2 mb-0">Session</label>
            <Dropdown disabled={disable} placeholder={sessions.slice(-1)} style={{width:'300px'}} id="session"  options={sessions} value={ooro.session} onChange={onSessionChange} />
          </div>
          <div  className="field grid mb-2 m-auto">
            <label htmlFor="division" className="w-2 mr-2 mb-0">Division</label>
            <Dropdown disabled={disable}  placeholder="Select Division" style={{width:'300px'}} id="division"  options={divisions}  value={ooro.division} onChange={onDivisionChange} />
          </div>
        </div>
        <div className="w-4 mx-0" >
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Renew Old RO</label>
            <InputText disabled={disable} style={{width:'250px'}} id="rro"  value={oldRo} onChange={onOldRoChange} onBlur={onOldRoBlur} />
          </div>
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="fmroject" className="w-2 mr-2 mb-0">Agency</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Agency"style={{width:'300px'}} id="agency"  optionLabel="name"  options={vendorlist} value={ooro.agency} onChange={onVendorChange} />
          </div>
        </div>
        <div  className="w-4 mx-0">
          <div  className="field grid mb-1 m-auto">
            <label htmlFor="ooroDate" className="w-2 mr-2 mb-0">Caption</label>
            <Dropdown disabled={disable} filter filterBy='name' placeholder="Select Caption"style={{width:'300px'}} id="caption"  options={captions} value={ooro.caption} onChange={onCaptionChange} />
          </div>
          <div className="field grid mb-1 m-auto">
            <label htmlFor="state" className="w-2 mr-2 mb-0">State* </label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={ooro.bstate} 
              options={states} 
              onChange={onStateChange} 
              placeholder="Select States" 
            />
          </div>
          <div className="field grid mb-1 m-auto">
            <label className="w-2 mr-2 mb-0" htmlFor="oorono" >Centres</label>
            <MultiSelect 
              style={{width:'300px'}}
              disabled={disable} 
              value={ooro.centres} 
              options={selUnits} 
              onChange={(e) => onCentreChange(e)} 
              optionLabel="name" placeholder="Select Centres" 
              filter 
            />
          </div>
        </div>
      </div>
      <div style={{width:'90%',margin:'auto', padding:'10px 0px 0 10px', fontSize: 'small'}} className="flex justify-content-center mt-1 surface-100">
        <div  className="  mb-1 ml-2 ">
          <label className=" mr-2 mb-0" >FOC - Qty</label>
          <InputNumber inputStyle={{width:'100%'}} disabled={disable}  min='0'  onChange={(e) => onInputNumberChange(e, 'focqty')} value={ooro.focqty}/>
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="">
          <Oorodetail disabled={disable} dc={cities}  mediatypes={mediatypes} littypes={littypes} flexmaterials={flexmaterials} pdate={ooro.rodate} Items={ooro.advt} mode={history.location.state.mode}  SaveItems={saveItems} />
        </div>
      </div>

      <div style={{width:'90%',margin:'auto',padding:'10px 0 5px 5px', fontSize: 'small'}} className="flex justify-content-end mt-1 surface-100">
        <div  className="w-8 mx-6">
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Display Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.displayamt}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Flex Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.flexamt}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Total Amount</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto " disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.displayamt+ooro.cost.flexamt}/>
          </div>
          <div className="field  mb-1 m-auto">
            <label htmlFor="ooroject" style={{width:'20%'}}className=" mr-2 mb-0">Less: Discount</label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="oorono"  value={ooro.cost.discper} onValueChange={(e) => onInputChangeamt(e, 'discper')}/>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.disc}/>
          </div>
          <div  className="field  mb-1 ">
            <label htmlFor="session" style={{width:'20%'}} className="mr-2 mb-0">Sub Total</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}} className="w-auto" disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.displayamt+ooro.cost.flexamt-ooro.cost.disc}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="oorono" >Add: GST </label>
            <InputNumber  minFractionDigits={2} min="0" max="100" inputStyle={{textAlign:'right',width:'70px', padding:'4px'}}  suffix="%" disabled={disable}  htmlFor="oorono"  value={ooro.cost.gstper} onValueChange={(e) => onInputChangeamt(e, 'gstper')}/>
            <InputNumber  locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'150px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.gst}/>
          </div>
          <div   className="field  mb-1 m-auto">
            <label style={{width:'20%'}} className=" mr-2 mb-0" htmlFor="oorono" >Round Off </label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  className="w-auto" disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.roundoff}/>
          </div>
          <div  className="field  mb-1 m-auto">
            <label htmlFor="ooroDate" style={{width:'20%'}} className="mr-2 mb-0">Net Amt</label>
            <InputNumber locale="en-IN" minFractionDigits={2} inputStyle={{textAlign:'right',width:'220px', padding:'4px'}}  cldassName="w-auto" disabled={true}  htmlFor="oorono" readOnly value={ooro.cost.netamt}/>
          </div>
        </div>
      </div>
      <div  style={{width: '90%'}}className='m-auto mt-2'>
        <div className={'formgrid grid'}>
          <div className="field col mb-0 pb-0 ">
            <label className="mb-0" htmlFor="baddress">Billing Address*</label>
            <Dropdown placeholder="Select Centre" className="w-full" id="units" disabled={disable} optionLabel="name"  options={units} value={ooro.bunit} onChange={onBunitChange} />
            <InputTextarea disabled={disable} rows='5' autoComplete="off" className={'w-full'} id="baddress" value={ooro.baddress} onChange={(e) => onInputChange(e, 'baddress')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Remarks</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={ooro.remarks} onChange={(e) => onInputChange(e, 'remarks')} />
          </div>
          <div className="field col mb-0 pb-0">
            <label className="mb-0" htmlFor="oterms">Note / Add On</label>
            <InputTextarea disabled={disable} rows={7} autoComplete="off" className={'w-full'} id="remarks" value={ooro.note} onChange={(e) => onInputChange(e, 'note')} />
          </div>
        </div>
        <div className='Statuslog'>
          <Statuslog statuses={ooro.status}/>
        </div>
        <div className="flex flex-row justify-content-around w-6 py-2 m-auto">
          {BtnOptions}
        </div>
      </div>

      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
          {ooro && <span>Are you sure you want to Cancel this RO <b>{ooro.rono}</b>?</span>}
        </div>
      </Dialog>
      <Dialog visible={ApproveDialog} style={{ width: '490px' }} header="Approve" modal footer={apooroveDialogFooter} onHide={hideApproveDialog}>
        <div className="confirmation-content">
        </div>
      </Dialog>
    </div>
  );
}

//<Button  label="Set Print Date/s" className=" p-button-info p-button-text " onClick={dopShow} />

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}

export default connect(mapStateToProps)(Ooro);