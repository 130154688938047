import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import logo from "../../assets/allen.png";
import { PDFViewer } from "@react-pdf/renderer";
import {Image, Page, Text, View, Document, StyleSheet} from "@react-pdf/renderer";
import {ToWords} from 'to-words';


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row'
    },
    section: {
      margin: 5
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 9,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      }
});
const Brroprint = (props) => {
    const history = useHistory();
    const [po,setpo] = useState(null);
    useEffect(() => {
        setpo(history.location.state.pro);
        if (props.pro) setpo(props.pro);
      },[props.pro, history.location.state.pro]);

    const formatDate = (dt) => {
        let cdt = new Date(dt);
        let month = cdt.getMonth() + 1;
        let day = cdt.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return day+"-"+month+"-"+cdt.getFullYear();
      };
    let pdfDoc = null;
    if (po) { 
        const newadvt = po.advt;
        //new Date(po.rodate).getFullYear().toString()+'01'+String(po.rono).padStart(4, '0')
        const toWords = new ToWords();
        const prep = po.status.find(p => p.status === 'Submitted' || p.status === 'Draft');
        const appr = po.status.find(p => p.status === 'Approved');
        pdfDoc = (<Document>
        <Page size="A4" style={{padding:'0 0 20 0'}}>
            <View fixed style={{margin:'40 10 0 20', display:'flex', flexDirection:'row', justifyContent:'space-between',  borderStyle:'solid', borderWidth:'1px'}}>
                <View style={styles.section}>
                    <Image src={logo} alt="Allen" style={{height:'30px', width:'100px'}}></Image>
                    <View  style={{ display:'flex', flexDirection:'row'}}>
                    <Text style={{fontSize:'10', margin:'10 0 0 0'}}>RELEASE ORDER - </Text>
                    <Text style={{fontSize:'12', margin:'9 0 0 0', fontWeight:'extrabold'}}>BRANDING</Text>
                    </View>
                </View>
                <View style={{margin:'5', display:'flex',flexDirection:'col', alignItems: 'flex-end'}}>
                    <Text style={{fontSize:'12',  margin:'0'}}>ALLEN CAREER INSTITUTE PVT. LTD.,</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>"SANKALP", CP-6, INDRA VIHAR</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>KOTA (RAJ.) 324005 Ph: (0744)-2757575</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>CIN : U80100RJ2021PTC077131</Text>
                    <Text style={{fontSize:'8', margin:'0'}}>Website: www.allen.ac.in Email:info@allen.ac.in</Text>
                </View>
            </View>
            <View fixed style={{margin:'0 10 0 20', display:'flex', flexDirection:'row', justifyContent:'space-between', borderStyle:'none',borderRightWidth:'1px', borderLeftWidth:'1px',borderBottomWidth:'1px'}}>
                <Text style={{fontSize:'9', margin:'5'}}>RO No: {po.rono}</Text>
                <Text style={{fontSize:'9', margin:'5'}}>RO Date: {formatDate(po.rodate)}</Text>
            </View>
            <View style={{margin:'0 10 0 20', display:'flex', flexDirection:'row', justifyContent:'space-between',  borderRightWidth:'1px', borderLeftWidth:'1px',borderBottomWidth:'1px'}}>
                <View style={{margin:'3 0 3 5', width:'70%',display:'flex', flexDirection:'col', alignItems: 'flex-Start'}}>
                    <Text style={{fontSize:'10', fontWeight:700, width:'110px', borderBottomWidth:'1px'}}>Agency Name &amp; Address</Text>
                    <Text style={{wordWrap:'normal', fontSize:'9', margin:'0'}}>{po.agency.name}</Text>
                    <Text style={{wordWrap:'normal', fontSize:'9', margin:'0'}}>{po.agency.address}</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>{po.agency.city + " " + po.agency.state}</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>{"GSTIN : " + po.agency.gstin}</Text>
                </View>
                <Text style={{fontSize:'9', margin:'0',borderRightWidth:'1px',}}></Text>
                <View style={{margin:'3 5 3 0', display:'flex', flexDirection:'col', alignItems: 'flex-end'}}>
                    <Text style={{fontSize:'10', fontWeight:700, borderBottomWidth:'1px'}}>Agency Contact No. &amp; Email</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>{po.agency.contact}</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>Mobile: {po.agency.officeno}</Text>
                    <Text style={{fontSize:'9', margin:'0'}}>Email: {po.agency.emailid}</Text>
                </View>
            </View>
            <View style={{margin:'0 10 0 20', display:'flex', flexDirection:'col', alignItems: 'flex-Start'}}>
                <Text style={{fontSize:'9', margin:'5 5 5 5'}}>Dear Sir/Ma'am,</Text>
                <Text style={{fontSize:'9', margin:'5'}}>As per your proposal, we hereby release our advertisement as per following:-</Text>
            </View>
            <View style={{margin:'0 10 0 20', display:'flex', flexDirection:'row', justifyContent:'space-between',  borderStyle:'solid', borderWidth:'1px'}}>
                <Text style={{width:'10%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Media Type</Text>
                <Text style={{width:'17%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Publication</Text>
                <Text style={{width:'13%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Edition</Text>
                <Text style={{width:'12%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Size </Text>
                <Text style={{width:'14%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Position</Text>
                <Text style={{width:'10%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Colour</Text>
                <Text style={{width:'12%',textAlign:'center',marginLeft:'5', fontSize:'8',borderRightWidth:'1px'}}>Date Publish</Text>
                <Text style={{width:'12%',textAlign:'right',marginRight:'5', fontSize:'8'}}>Total Amt</Text>
            </View>
            <View style={{margin:'0 10 0 20', display:'flex', flexDirection:'row', justifyContent:'space-between',borderRightWidth:'1px', borderLeftWidth:'1px',borderBottomWidth:'1px'}}>
                    <Text style={{width:'10%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.media === 'Other'?newadvt.mediaother:newadvt.media}</Text>
                    <Text style={{width:'17%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.publication}</Text>
                    <Text style={{width:'13%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.edition}</Text>
                    <Text style={{width:'12%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.size}</Text>
                    <Text style={{width:'14%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.position === 'Other'?newadvt.positionother:newadvt.position}</Text>
                    <Text style={{width:'10%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{newadvt.colorb}</Text>
                    <Text style={{width:'12%',textAlign:'center',marginLeft:'5', fontSize:'8', borderRightWidth:'1px'}}>{formatDate(newadvt.dtpublish)}</Text>
                    <Text style={{width:'12%',textAlign:'right',marginRight:'5', fontSize:'8'}}>{newadvt.amt.toFixed(2)}</Text>
            </View>
            <View wrap={false} style={{margin:'0 10 0 20',  display:'flex', flexDirection:'row', justifyContent:'space-between',  borderRightWidth:'1px', borderLeftWidth:'1px',borderBottomWidth:'1px'}}>
                <View style={{width:'50%',margin:'0 0 0 0', display:'flex', flexDirection:'col'}}>
                    {po.caption?(<Text style={{margin:'3 0 0 5', width:'37px', fontSize:'9', fontWeight:700, borderBottomWidth:'1px'}}>Caption</Text>):null}
                    {po.caption?(<Text style={{margin:'5', fontSize:'9'}}>{po.caption}</Text>):null}
                    {po.remarks?(<Text style={{margin:'3 0 0 5', width:'41px', fontSize:'9', fontWeight:700, borderBottomWidth:'1px'}}>Remarks</Text>):null}
                    {po.remarks?(<Text style={{margin:'5', fontSize:'9'}}>{po.remarks}</Text>):null}
                    {po.note?(<Text style={{margin:'3 0 0 5', width:'55px', fontSize:'9', fontWeight:700, borderBottomWidth:'1px'}}>Note/Add on</Text>):null}
                    {po.note?(<Text style={{margin:'5', fontSize:'9'}}>{po.note}</Text>):null}
                </View>
                <View style={{margin:'0 0 0 0', width:'50%', display:'flex', flexDirection:'col', alignItems:'flex-end',  borderLeftWidth:'1px', borderStyle:'solid'}}>
                    <View style={{margin:'3 0 0 0', display:'flex', flexDirection:'row'}}>
                        <Text style={{width:'50%', textAlign:'left', paddingLeft:'22', fontSize:'9'}}>Amount : </Text>
                        <Text style={{width:'50%', textAlign:'right', marginRight:'5', fontSize:'9'}}>{po.cost.grossamt?po.cost.grossamt.toFixed(2):0}</Text>
                    </View>
                    <View style={{margin:'3 0 0 0', display:'flex',flexDirection:'row'}}>
                        <Text style={{width:'70%', textAlign:'left', paddingLeft:'22',fontSize:'9'}}>Add:G.S.T. @ {po.cost.gstper.toFixed(2)}% : </Text>
                        <Text style={{width:'30%', textAlign:'right',marginRight:'5', fontSize:'9'}}>{po.cost.gst.toFixed(2)}</Text>
                    </View>
                    <View style={{margin:'3 0 0 0', display:'flex', flexDirection:'row'}}>
                        <Text style={{width:'50%', textAlign:'left', paddingLeft:'22',  fontSize:'9'}}>Grand Total : </Text>
                        <Text style={{width:'50%', textAlign:'right', marginRight:'5', fontSize:'9'}}>{(po.cost.grossamt+po.cost.gst).toFixed(2)}</Text>
                    </View>
                    <View style={{margin:'3 0 0 0', display:'flex', flexDirection:'row'}}>
                        <Text style={{width:'50%', textAlign:'left', paddingLeft:'22', fontSize:'9'}}>Round off : </Text>
                        <Text style={{width:'50%', textAlign:'right', marginRight:'5', fontSize:'9'}}>{po.cost.roundoff.toFixed(2)}</Text>
                   </View>
                    <View style={{margin:'3 0 3 0', display:'flex', flexDirection:'row'}}>
                        <Text style={{width:'50%', textAlign:'left', paddingLeft:'22', fontSize:'9'}}>Net Payable Amount : </Text>
                        <Text style={{width:'50%', textAlign:'right', marginRight:'5', fontSize:'10'}}>{po.cost.netamt.toFixed(2)}</Text>
                    </View>
                </View>
            </View>
            <View wrap={false} style={{margin:'0 10 0 20', padding:'3 0 3 0', display:'flex', flexDirection:'col', alignItems:'flex-end',  borderStyle:'solid', borderLeftWidth:'1px', borderRightWidth:'1px', borderBottomWidth:'1px'}}>
                <Text style={{marginRight:'5', fontSize:'10', textTransform:'capitalize'}}>Amount In words: {po.cost.netamt>0?toWords.convert(po.cost.netamt, { currency: true , ignoreZeroCurrency: true}):'Nil'}.</Text>
            </View>
            <View wrap={false} style={{margin:'0 10 0 20', display:'flex',flexDirection:'row',  borderLeftWidth:'1px', borderRightWidth:'1px', borderBottomWidth:'1px'}}>
                {po.baddress?(<View style={{margin:'3 0 0 5', display:'flex', width:'50%',  flexDirection:'col'}}>
                    <Text style={{margin:'0', width:'67px', fontSize:'10', borderBottomWidth:'1px'}}>Billing Address</Text>
                    <Text style={{margin:'3 0 0 0', fontSize:'9'}}>{po.baddress}</Text>
                </View>):null}
                <Text style={{fontSize:'9', margin:'0',borderRightWidth:'1px',}}></Text>
                {po.centres?(<View style={{margin:'3 0 0 5', display:'flex', width:'50%', flexWrap:'wrap', flexDirection:'col'}}>
                    <Text style={{margin:'0', width:'46px', fontSize:'10', fontWeight:700, borderBottomWidth:'1px'}}>Center(s):</Text>
                    <Text style={{margin:'3 0 0 0', fontSize:'9'}}>{po.centres.length > 1?'Combined':po.centres[0].name}</Text>
                </View>):null}
            </View>
            {po.tandc?(<View wrap={false} style={{margin:'0 10 0 20', display:'flex', flexDirection:'col', justifyContent:'space-between',  borderLeftWidth:'1px', borderRightWidth:'1px', borderBottomWidth:'1px'}}>
                <Text style={{margin:'3 0 0 5', width:'90px', fontSize:'10', fontWeight:700, borderBottomWidth:'1px'}}>Terms &amp; Conditions</Text>
                <Text style={{margin:'5', fontSize:'9'}}>{po.tandc}</Text>
            </View>):null}
            <View wrap={false} style={{margin:'0 10 0 20', height:'60px', display:'flex', flexDirection:'row', justifyContent:'space-around',  borderLeftWidth:'1px', borderRightWidth:'1px', borderBottomWidth:'1px'}}>
                <View style={{width:'50%'}}>
                    <Text style={{margin:'3 0 0 5', fontSize:'10', fontWeight:700}}>RO Prepared By : {prep?prep.username:''}</Text>
                </View>
                <Text style={{fontSize:'9', margin:'0',borderRightWidth:'1px'}}></Text>
                <View style={{width:'50%'}}>
                    <Text style={{margin:'3 0 0 5', textAlign:'left', fontSize:'10', fontWeight:700}}>Authorized & Approved By:{appr?appr.username:''}</Text>
                    <Text style={{marginLeft:'75', fontSize:'10', fontWeight:700}}>{appr?appr.desig:''}</Text>
                </View>
            </View>
            <View wrap={false} style={{margin:'0 10 0 20', height:'40px', display:'flex', flexDirection:'row', alignItems:'flex-end', justifyContent:'space-between',  borderLeftWidth:'1px', borderRightWidth:'1px', borderBottomWidth:'1px'}}>
                <Text style={{margin:'5', fontSize:'10', fontWeight:700}}>Received and Accepted</Text>
                <Text style={{margin:'5', fontSize:'10', fontWeight:700}}>Signature of Agency</Text>
            </View>
            <Text style={{textAlign:'center', fontSize:'9', margin:'0'}}>This is computer generated Release Order, therefore no signature is required.</Text>
            
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
        </Page>
    </Document>);
    }
    return (
        <PDFViewer style={{height:'100vh'}} width="100%" showToolbar={true}>
            {pdfDoc}
        </PDFViewer>
    );
};

const mapStateToProps = state => {
    return {
        username:state.auth.username,
        desig:state.auth.desig,
        unitname:state.auth.unitname,
        unitid:state.auth.unitid,
        userid:state.auth.userid,
        empid:state.auth.empid,
        premises:state.auth.premises,
        role:state.auth.role,
        storeid:state.auth.storeid,
        permissions:state.auth.permissions,
        isAuth:state.auth.token!==null,
        isAdmin:state.auth.isAdmin
    }
}


export default connect(mapStateToProps)(Brroprint);